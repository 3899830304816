import React from "react";
import Modal from "../../../_common/Modal/Modal";
import Button from "../../../_common/Button/Button";
import useAccount from "../../../../hooks/useAccount";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../../configs/routes/routes";
import useAuth from "../../../../hooks/useAuth";

interface Props {
  clickHandlerButton?: () => void;
  clickHandlerClose?: () => void;
}
function ModalSettings({ clickHandlerButton, clickHandlerClose }: Props) {
  const history = useHistory();
  const { removeAccount } = useAccount();
  const { logOut } = useAuth();
  const removeAccountHandler = async () => {
      await removeAccount();
      await logOut();
    history.push(Routes.HOME);
  };
  return (
    <Modal clickHandlerClose={clickHandlerClose}>
      <h3>Are you sure you want to delete your Bianu account?</h3>
      <p>
        Please bear in mind all your data will be removed from the Bianu app
      </p>
      <div className={"settings_modal_button_wrapper"}>
        <Button
          content={"NO, LET’S KEEP IT "}
          clickHandler={clickHandlerClose}
          className={"secondary_button_green"}
        />
        <Button
          content={"DELETE ACCOUNT"}
          clickHandler={removeAccountHandler}
          className={"secondary_button_gray"}
        />
      </div>
    </Modal>
  );
}

export default ModalSettings;
