import { PRODUCT_ACTION_TYPES } from "../action-types";

export function actionGetProducts(data: any) {
  return {
    type: PRODUCT_ACTION_TYPES.GET_PRODUCT,
    data,
  };
}
export function actionResetProductsReducer() {
  return {
    type: PRODUCT_ACTION_TYPES.USER_LOGOUT,
  };
}
