import React, { useCallback } from "react";
import "./QuizModal.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  actionCloseModal,
  actionSetQuizNavCurrent,
} from "../../store/actions/quiz-actions";
import Button from "../_common/Button/Button";
import { RootState } from "../../store";
import cross from "./../../assets/static/icon/close.svg";
import { Routes } from "../../configs/routes/routes";
import PrivacyPolicyText from "../PrivacyPolicy/PrivacyPolicyText";
import TermsText from "../Terms/TermsText";

function QuizModal() {
  const { header, content, button, subHeader, canBeClosed } = useSelector(
    (state: any) => state.quizModal
  );
  const { nav } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const closeModal = useCallback(() => {
    dispatch(actionCloseModal());
  }, [dispatch]);
  return (
    <div className="darkbg">
      <div
        className="quizmodal"
        style={{
          height:
            header === "Privacy Policy" || header === "TermsModal & Conditions"
              ? "100%"
              : "min-content",
        }}
      >
        <div className="header-quiz-modal">
          {canBeClosed && (
            <button className="closeBtn" onClick={() => closeModal()}>
              <img src={cross} alt="close" />
            </button>
          )}
          <h1>{header}</h1>
        </div>

        <div className="text">
          {subHeader && <h2>{subHeader}</h2>}
          {header === "TermsModal & Conditions" ? (
            <TermsText />
          ) : header === "Privacy Policy" ? (
            <PrivacyPolicyText />
          ) : (
            content.map((p: string) => <p>{p}</p>)
          )}
        </div>
        {button && (
          <div className="bottom-button-wrapper">
            <Button
              content={button.title}
              className="primary_button_green"
              clickHandler={
                button.type === "close"
                  ? () => {
                      dispatch(actionSetQuizNavCurrent(nav.nextStepId));
                      dispatch(actionCloseModal());
                    }
                  : () => console.log(1)
              }
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default QuizModal;
