import { PROGRAM } from "../action-types";

export function showDetailAction(data: boolean) {
  return {
    type: PROGRAM.SHOW_DETAIL,
    data: data,
  };
}
export function showDetailResetAction() {
  return {
    type: PROGRAM.USER_LOGOUT,
  };
}
