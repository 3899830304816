const dayInMs = 86400000;
const pregnancyPeriod = 266;
const now = new Date();

export class UtilsPregnancy {
  public static getDueDate(cycle: string, lastPeriod: number) {
    return new Date(
      lastPeriod + pregnancyPeriod * dayInMs + (Number(cycle) * dayInMs) / 2
    );
  }

  public static getDaysToDueDate(dueDate: Date) {
    return (dueDate.getTime() - now.getTime()) / dayInMs;
  }

  public static getCurrentWeek(dueDate: Date) {
    return Math.floor(40 - (dueDate.getTime() - now.getTime()) / (dayInMs * 7));
  }

  public static getTrimester(daysLeft: number) {
    if (daysLeft >= 180) {
      return 1;
    } else if (daysLeft >= 105) {
      return 2;
    } else return 3;
  }
}
