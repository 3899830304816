import React from "react";
import img from '../../assets/static/account_img/Image.svg'
import Button from "../../components/_common/Button/Button";
import LogInWrapper from "../../components/logIn/LogInWrapper";
import {useHistory} from "react-router-dom";
import {Routes} from "../../configs/routes/routes";

function CheckInBox() {
  const history = useHistory()
  return (
    <LogInWrapper>
      <h1>Check your inbox</h1>
      <p>We've emailed you instructions with password reset link.</p>
      <img src={img}/>

      <a href="https://mail.google.com"> <Button
        clickHandler={()=>{history.push(Routes.LOGIN)}}
        content={'Send reset link'}
        className={'primary_button_green'}/></a>
    </LogInWrapper>
  );
}
export default CheckInBox;



