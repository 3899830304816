import React, { useState } from "react";
import "./WhyTrustUs.scss";
import { secondBlockItems, WhyTrustUsImg } from "../../constants/WhyTrustUS";
import SecondBlockItem from "../../components/SecondBlockItem/SecondBlockItem";
import HeaderMain from "../../components/_common/HeaderMain/HeaderMain";
import FooterMain from "../../components/_common/FooterMain/FooterMain";
import Button from "../../components/_common/Button/Button";
import { useHistory } from "react-router-dom";
import { Routes } from "../../configs/routes/routes";

function WhyTrustUs() {
  const [menuVisible, setMenuVisible] = useState(false);
  const menuVisibleHandler = () => {
    setMenuVisible(!menuVisible);
  };
  const history = useHistory();
  return (
    <div>
      <HeaderMain menuBtnHandler={menuVisibleHandler} />
      <div
        className="first_block"
        style={{ overflow: menuVisible ? "hidden" : "auto" }}
      >
        <div className="container">
          <h1>
            Created with mums.
            <br />
            Powered by experts.
            <br />
          </h1>
          <p className="text1">
            We noticed that the inclusion of supplements in pregnancy is not
            currently done very well at all! Every premade supplement seemed
            like a copy of the next and those that claim “personalised service”
            didn’t offer any real support after you ordered their products!
            <br />
            <br />
            This was even harder to ignore when you consider:
          </p>
          <div className="first_block_content">
            <div className="first_block_content_item1">
              <p>
                Certain diets increase the risk of not getting enough nutrients,
                for example a plant-based diet, which is more popular in women
                of childbearing age.
              </p>
            </div>
            <div className="first_block_content_item2">
              <p>
                Many studies show that intakes of nutrients including folate,
                iron, vitamin D and iodine were lower than UK recommendations in
                pregnant women.
              </p>
            </div>
            <div className="first_block_content_item3">
              <p>
                Specific nutrients offer benefits in higher doses at specific
                points in your pregnancy so a ‘one size fits all’ approach
                simply will not work. Supplements need to adapt to the natural
                progression of a pregnancy.
              </p>
            </div>
            <div className="first_block_content_item4">
              <p>
                Dietary intakes of specific nutrients can be significantly
                reduced by the symptoms of pregnancy, such as sickness and
                nausea which effects 80% pregnant women.
              </p>
            </div>
            <div className="first_block_content_item5">
              <p>
                Mums-to-be face many challenges of including nutrition into a
                wider wellness plan as their lives and bodies change. It’s best
                to prepare both body and mind for birth!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="second_block">
        <div className="container">
          <div className="second_block_text">
            <h1>Meet our experts</h1>
            <p className="text2">
              Our experts put their collective expertise into Bianu, both from a
              nutrition and holistic wellness perspective. They will coach you
              to a healthier body and mind so that you truly benefit from your
              personalised supplements with confidence that they are perfect for
              both you and your baby.
              <br />
            </p>
            <div className="second_block_main">
              {secondBlockItems.map((item) => (
                <SecondBlockItem
                  name={item.name}
                  photo={item.photo}
                  about={item.about}
                  position={item.position}
                />
              ))}
            </div>
            <Button
              content={"Explore what our experts recommend for you"}
              className={"primary_button_green"}
              clickHandler={() => history.push(Routes.QUIZ)}
            />
          </div>
        </div>
      </div>
      <div className="third_block">
        <div className="container">
          <div className="third_block_content">
            <article className="third_block_content_article">
              <img
                src={WhyTrustUsImg.LogoDSM}
                alt="logo DSM"
                className="third_block_content_article_logo"
              />
              <h1>
                Born from science,
                <br />
                powered by DSM.
              </h1>
              <p className="third_block_content_p1">
                To offer you the highest quality, most sophisticated and
                responsibly sourced supplements, we partner with DSM, the
                world’s largest ingredients science company.
              </p>
              <p className="third_block_content_p2">
                With over 150 years of experience producing early life nutrition
                that supports mothers and children at every stage of their
                development, you can be reassured that your personalised
                supplements are of the highest quality.
              </p>
              <p className="third_block_content_p3">
                DSM ingredients are safety checked, non-GMO and backed by
                science. Your supplements have a particularly high
                bioavailability of micronutrients in the form of compact micro
                bubbles which makes them particularly effective and more
                pleasant for you to swallow.
              </p>
              <a
                href="https://www.dsm.com/corporate/home.html"
                target="_blank"
                rel="noopener"
              >
                Read more on DSM
              </a>
            </article>
            <img
              src={WhyTrustUsImg.OfficePhoto}
              alt="office building"
              className="third_block_content_article_img"
            />
          </div>
          <FooterMain linksShown={true} />
        </div>
      </div>
    </div>
  );
}

export default WhyTrustUs;
