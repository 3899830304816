export const accountMenu = {
  PERSONAL: "Personal details",
  RECOMMENDATION: "My recommendations",
  ORDERS: "Orders & subscriptions",
  PAYMENT: "Payment details",
  SETTINGS: "Settings",
};
export const steps = {
  STEP15: "stp0015",
  STEP18: "stp0018",
  STEP19: "stp0019",
  STEP07: "stp0007",
  STEP10: "stp0010",
};
export const className = {
  QUIZ_FORM_MULTI: "quiz-form_multiButton",
  QUIZ_FORM: "quiz-form",
  SINGLE_FORM_MULTI: "single_form_wrapper_multiButton",
  SINGLE_FORM: "single_form_wrapper",
};
export const cardStyle = {
  placeholder: "Card Number",
  style: {
    base: {
      fontSize: "16px",
    },
    invalid: {},
  },
  hidePostalCode: true,
};
export const cardStyleYears = {
  placeholder: "MM/YY",
  style: {
    base: {
      fontSize: "16px",
    },
    invalid: {},
  },
  hidePostalCode: true,
};
export const cardStyleCvc = {
  placeholder: "CVC",
  style: {
    base: {
      fontSize: "16px",
    },
    invalid: {},
  },
  hidePostalCode: true,
};
