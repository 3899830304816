import React from "react";
import "./SecondBlockItem.scss";
import { WhyTrustUsCard } from "../../types/whyTrustUsTypes";

function SecondBlockItem(props: WhyTrustUsCard) {
  const { photo, name, position, about } = props;
  return (
    <div className="second_block_main_item">
      <img className="second_block_main_item_avatar" src={photo} />
      <p className="second_block_main_item_name">{name}</p>
      <p className="second_block_main_item_position">{position}</p>
      <p className="second_block_main_item_about">{about}</p>
    </div>
  );
}

export default SecondBlockItem;
