import { actionInterface } from "../../types/common";
import { SUBSCRIPTION } from "../action-types";
import { defaultSubscriptionState } from "../../constants/defaultData";
import { Subscription } from "../../types/subscription";

export interface subscriptionStateInterface {
  subscription: Subscription;
}

export const currentSubscriptionReducer = (
  state: Subscription[] = defaultSubscriptionState,
  action: actionInterface
) => {
  switch (action.type) {
    case SUBSCRIPTION.SET_CURRENT_SUBSCRIPTION:
      return action.data;
    case SUBSCRIPTION.USER_LOGOUT:
      return defaultSubscriptionState;
  }
  return state;
};
