import { SUBSCRIPTION } from "../action-types";
import { Subscription } from "../../types/subscription";

export function currentSubscriptionAction(data: Subscription[]) {
  return {
    type: SUBSCRIPTION.SET_CURRENT_SUBSCRIPTION,
    data: data,
  };
}

export function resetSubscriptionReducerAction() {
  return {
    type: SUBSCRIPTION.USER_LOGOUT,
  };
}
