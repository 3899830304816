import React, {useState} from 'react';
import Input from "../../_common/Input/Input";
import Button from "../../_common/Button/Button";
import useAccount from "../../../hooks/useAccount";
import {useSelector} from "react-redux";
import {store} from "../../../types/store";
interface Props {
  editEmailHandler: ()=>void;
  emailData: any
}
function UpdateEmail({editEmailHandler, emailData}: Props) {
  const {accountData} = useSelector((state: store) => state.account)
  const {uid} = accountData
  const [email, setEmail] = useState(emailData)
  const {updateField, loadAccount} = useAccount()
  const emailHandler = (value: string) => {
    setEmail(value)
  }
  const emailUpdateHandler = async () => {
    await updateField(uid || '', 'email', email)
    editEmailHandler()
    await loadAccount(uid || '')
  }
  return (
    <>
      <div className={'name_input_wrapper'}>
        <Input
          type={'text'}
          placeholder={emailData}
          name={'email'}
          value={email}
          onChange={(e)=>{emailHandler(e.currentTarget.value)}} />
      </div>
      <div className={'name_button_wrapper'}>
        <Button disabled={email === '' && true} content={'Done'} clickHandler={emailUpdateHandler} className={'primary_button_green'}/>
        <Button content={'Cancel'} clickHandler={editEmailHandler} className={'secondary_button_gray'}/>
      </div>
    </>
  );
}

export default UpdateEmail;
