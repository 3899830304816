import { initializeApp } from "firebase/app";
import { initializeFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// export const firebaseConfig = {
//   apiKey: "AIzaSyDm_q24VOB0QmgtoiqKT8_cImG3v7A_akM",
//   authDomain: "l3m-development-database.firebaseapp.com",
//   databaseURL: "https://l3m-development-database.firebaseio.com",
//   projectId: "l3m-development-database",
//   storageBucket: "l3m-development-database.appspot.com",
//   messagingSenderId: "813407595366",
//   appId: "1:813407595366:web:6447677683696851ddb6a7",
//   measurementId: "G-9R4WL7S8W3",
// };
export const firebaseConfig = {
  apiKey: "AIzaSyB-GzWWpJp72dIcr5a3p5D1GD5geEfJzYo",
  authDomain: "l3m-data-collator.firebaseapp.com",
  databaseURL: "https://l3m-data-collator.firebaseio.com",
  projectId: "l3m-data-collator",
  storageBucket: "l3m-data-collator.appspot.com",
  messagingSenderId: "1045232021479",
  appId: "1:1045232021479:web:56b23ee34323cb3b1a5742",
  measurementId: "G-JX38CF3MT3",
};


const firebaseApp = initializeApp(firebaseConfig);

export const db = initializeFirestore(firebaseApp, {
  ignoreUndefinedProperties: true,
});

export const auth = getAuth();
export default firebaseApp;


