import { actionInterface } from "../../types/common";
import { SUBSCRIPTION } from "../action-types";
import { Subscription } from "../../types/subscription";
import { defaultSubscriptionState } from "../../constants/defaultData";

export interface subscriptionStateInterface {
  subscription: Subscription;
}

export const subscriptionReducer = (
  state: Subscription[] = defaultSubscriptionState,
  action: actionInterface
) => {
  switch (action.type) {
    case SUBSCRIPTION.GET_SUBSCRIPTION: {
      return action.data;
    }
    case SUBSCRIPTION.USER_LOGOUT: {
      return defaultSubscriptionState;
    }
    default:
      return state;
  }
};
