import React, { useEffect, useState } from "react";
import "./Recommendation.scss";
import {
  fallbackValues,
  initialStateRecommendation,
} from "../../../constants/defaultData";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import recItem2 from "../../../assets/static/recommendation_img/rec-item2.svg";
import recItem3 from "../../../assets/static/recommendation_img/rec-item3.svg";
import { productState, Supplement } from "../../../types/supplements";
import { useRecommendations } from "../../../hooks/useRecommendations";
import { useSelector } from "react-redux";
import Program from "../../Recommendation/Programme/Programme";
import ProgramDetail from "../../Recommendation/ProgramDetail/ProgramDetail";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../configs/routes/routes";
import { LocalStorage } from "../../../services/storage";
import {firebaseConfig} from "../../../configs/firebase";

const storage = getStorage();

function Recommendation() {
  const [items, setItems] = useState<productState>(fallbackValues);
  const { loadProduct, loadSupplements } = useRecommendations();
  const history = useHistory();
  const [load, setLoad] = useState(false);
  const recProduct = useSelector((state: any) => state.rec.recommended);
  const productId = useSelector((state: any) => state.rec.favorite);
  const productData = useSelector((state: any) => state.rec.product);
  const user = useSelector((state: any) => state.account.accountData);
  const supplementsData = useSelector((state: any) => state.rec.supplements);
  const [showDetail, setShowDetail] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(productId);
  const [ProductInStorage, setProductInStorage] = useState("");
  const RecProductFinder = supplementsData.find((item: Supplement) => {
    return item.id === recProduct;
  });
  const optionSelect = (newProduct: string, currentProductId: string) => {
    setLoad(true);
    setProductInStorage(currentProductId);
    LocalStorage.set("recProduct", currentProductId);
    if (currentProduct !== newProduct) {
      setCurrentProduct(newProduct);
      setItems({
        ...items,
        elements: {
          program: {
            id: RecProductFinder.id,
            img: RecProductFinder.imagePath,
            title: RecProductFinder.name,
            text: RecProductFinder.description,
          },
          appInfo: {
            img: recItem2,
            title: "Personalised programme companion app",
            text: `Welcome to Trimester 1${
              user.name ? `, ${user.name}` : ""
            }! We know theres a lot on your mind in these exciting early weeks of pregnancy. We hope to make things easier by sharing nutrition information when you need it.`,
          },
          oooCoach: {
            img: recItem3,
            title: "1:1 coach",
            text: "Our prenatal experts use our technology to understand you as a person and offer guidance that reflects your needs... you can even 1:1 text chat with your coach!",
          },
        },
      });
      setLoad(false);
    } else {
      setLoad(false);
      return;
    }
  };
  const setProductForSubscription = () => {
    if (ProductInStorage !== "") {
      LocalStorage.set("recProduct", ProductInStorage);
      history.push(Routes.SUBSCRIPTION);
    } else {
      LocalStorage.set("recProduct", productId);
      history.push(Routes.SUBSCRIPTION);
    }
  };
  const makeListItems = async (productData: any) => {
    if (!productData) {
      setItems(fallbackValues);
    } else {
      const getSrc = async () => {
        try {
          return await getDownloadURL(
            ref(
              storage,
              `gs://${firebaseConfig.storageBucket}/${productData.webImagePath}`
            )
          );
        } catch (e) {
          console.log(e);
          return fallbackValues.elements.program.img;
        }
      };
      const productImg = await getSrc();
      setItems({
        ...items,
        elements: {
          program: {
            id: productData.id,
            img: productImg || "",
            title: productData.name || fallbackValues.elements.program.title,
            text:
              productData.description || fallbackValues.elements.program.text,
          },
          appInfo: {
            img: recItem2,
            title: "Personalised programme companion app",
            text: `Welcome to Trimester 1${
              user.name ? `, ${user.name}` : ""
            }! We know theres a lot on your mind in these exciting early weeks of pregnancy. We hope to make things easier by sharing nutrition information when you need it.`,
          },
          oooCoach: {
            img: recItem3,
            title: "1:1 coach",
            text: "Our prenatal experts use our technology to understand you as a person and offer guidance that reflects your needs... you can even 1:1 text chat with your coach!",
          },
        },
      });
    }
  };
  useEffect(() => {
    loadProduct(currentProduct);
    loadSupplements().then(() => setTimeout(() => setLoad(true), 2000));
  }, [currentProduct]);

  useEffect(() => {
    makeListItems(productData);
  }, [productData]);
  const [showInfo, setShowInfo] = useState(initialStateRecommendation);
  const showInfoItem = (id: string) => {
    const currentDetailComponent = supplementsData.find(
      (item: Supplement) => item.id === id
    );
    setShowInfo(currentDetailComponent);
    setShowDetail(true);
  };
  return (
    <>
      <div
        style={{ width: showDetail ? "100%" : "0" }}
        className={"blackout"}
      />
      <div className={"recommendation"}>
        {productId && recProduct && productId !== recProduct && (
          <>
            <p>Here's what we recommend for you:</p>
            <div className={"recommendation-options"}>
              <div
                id={productId}
                onClick={(e: React.MouseEvent) =>
                  optionSelect(productId, e.currentTarget.id)
                }
                className={
                  currentProduct === productId
                    ? "recommendation-selected"
                    : "recommendation-deselected"
                }
              >
                Option 1
              </div>
              <div
                id={recProduct}
                onClick={(e: React.MouseEvent) =>
                  optionSelect(recProduct, e.currentTarget.id)
                }
                className={
                  currentProduct === recProduct
                    ? "recommendation-selected"
                    : "recommendation-deselected"
                }
              >
                Option 2
              </div>
            </div>
          </>
        )}
        <Program
          loading={load}
          className
          learnMore={() => showInfoItem(currentProduct !==''? currentProduct: productId)}
          items={items.elements}
        />
        <ProgramDetail
          productImage={items.elements.program.img}
          showModal={showDetail}
          closeOnClick={() => setShowDetail(false)}
          sup={showInfo}
        />
      </div>
    </>
  );
}

export default Recommendation;
