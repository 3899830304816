import { SUBSCRIPTION } from "../action-types";

export function actionSetSubscription(data: any) {
  return {
    type: SUBSCRIPTION.GET_SUBSCRIPTION,
    data,
  };
}
export function actionResetSubscription() {
  return {
    type: SUBSCRIPTION.USER_LOGOUT,
  };
}
