import { actionInterface } from "../../types/common";
import { QUIZ_MODAL_ACTION_TYPES } from "../action-types";

export interface quizModalReducerState {
  header: string;
  open: boolean;
  content: string[];
  subHeader?: string;
  canBeClosed: boolean;
  button?: {
    title: string;
    type: string;
  };
}

const defaultState: quizModalReducerState = {
  header: "String",
  open: false,
  content: [""],
  canBeClosed: true,
  subHeader: "",
  button: {
    title: "",
    type: "",
  },
};

export const quizModalReducer = (
  state: quizModalReducerState = defaultState,
  action: actionInterface
) => {
  switch (action.type) {
    case QUIZ_MODAL_ACTION_TYPES.OPEN_MODAL: {
      return action.data;
    }
    case QUIZ_MODAL_ACTION_TYPES.CLOSE_MODAL: {
      return defaultState;
    }
    default: {
      return state;
    }
  }
};
