import profilePhoto1 from "../../src/assets/static/WhyTrustUs_img/Ellipse 1.png";
import profilePhoto2 from "../../src/assets/static/WhyTrustUs_img/Ellipse 2.png";
import profilePhoto3 from "../../src/assets/static/WhyTrustUs_img/Ellipse 3.png";
import profilePhoto4 from "../../src/assets/static/WhyTrustUs_img/Ellipse 4.png";
import profilePhoto5 from "../../src/assets/static/WhyTrustUs_img/Ellipse 5.png";
import profilePhoto6 from "../../src/assets/static/WhyTrustUs_img/Ellipse 6.png";
import profilePhoto7 from "../../src/assets/static/WhyTrustUs_img/Ellipse 7.png";
import profilePhoto8 from "../../src/assets/static/WhyTrustUs_img/Ellipse 8.png";
import profilePhoto9 from "../../src/assets/static/WhyTrustUs_img/Ellipse 9.png";
import profilePhoto10 from "../../src/assets/static/WhyTrustUs_img/Ellipse 10.png";
import profilePhoto11 from "../../src/assets/static/WhyTrustUs_img/Ellipse 11.png";
import profilePhoto12 from "../../src/assets/static/WhyTrustUs_img/Ellipse 12.png";
import profilePhoto13 from "../../src/assets/static/WhyTrustUs_img/Ellipse 13.png";
import profilePhoto14 from "../../src/assets/static/WhyTrustUs_img/Ellipse 14.png";
import profilePhoto15 from "../../src/assets/static/WhyTrustUs_img/Ellipse 15.png";
import profilePhoto16 from "../../src/assets/static/WhyTrustUs_img/Ellipse 16.png";
import profilePhoto17 from "../../src/assets/static/WhyTrustUs_img/Ellipse 17.png";
import logo from "../../src/assets/static/WhyTrustUs_img/Group 168.png";
import officePhoto from "../../src/assets/static/WhyTrustUs_img/locate-our-nearest-office.thumb.800 1.png";
import logoDSM from "../../src/assets/static/WhyTrustUs_img/logo-dsm 1.png";
import { WhyTrustUsCard } from "../types/whyTrustUsTypes";

export const WhyTrustUsImg = {
  Logo: logo,
  OfficePhoto: officePhoto,
  LogoDSM: logoDSM,
};

export const secondBlockItems: WhyTrustUsCard[] = [
  {
    photo: profilePhoto1,
    name: "Cordelia Uys",
    position: "Breastfeeding Counsellor",
    about:
      "Cordelia began her career as a journalist, but found breastfeeding her three sons so rewarding and empowering that she decided to re-train as an NCT Breastfeeding Counsellor. Since 2009, Cordelia has been facilitating breastfeeding, introducing solids and parenting classes, and with a team of colleagues, runs a weekly breastfeeding drop-in at her home.",
  },
  {
    photo: profilePhoto2,
    name: "Elisa Lee",
    position: "Yoga Teacher",
    about:
      "Elisa Lee Lavery teaches vinyasa flow, prenatal and postnatal yoga. She has been practicing for over ten years, and completed her teacher training at Laughing Lotus NYC. Advanced teacher trainings include Uma Dinsmore-Tuli, an expert in women’s yoga, and Judith Lasater, among others. Accredited by Yoga Alliance UK. ElisaLeeYoga.com",
  },
  {
    photo: profilePhoto3,
    name: "Eliza Flynn",
    position: "Prenatal Fitness Expert",
    about:
      "Eliza has worked with hundreds of women, helping them reconnect and rediscover respect for their bodies. She can often be seen running with other mums at local Park Runs, training mums for their first obstacle course race or getting mums together to try out new fitness classes.",
  },
  {
    photo: profilePhoto4,
    name: "Juliet Newson",
    position: "Sleep Consultant",
    about:
      "Juliet is a registered nurse and health visitor. Supporting families with babies through to teens exclusively as a sleep consultant for over 12 years, Juliet takes a holistic and gentle approach. She delivers sleep workshops within the NHS and private sectors, as well as facilitating sleep study days for students within the school setting.",
  },
  {
    photo: profilePhoto5,
    name: "Karen Appleson",
    position: "Postnatal Fitness",
    about:
      "For the past 25 years, Karen has helped women feel stronger, more aware and in control through programs centred around Pilates. Her teaching provides practical guidance and understanding of what each individual person needs, helping them to deepen the relationship with their body.",
  },
  {
    photo: profilePhoto6,
    name: "Kat Berry",
    position: "Hypnobirthing and Doula",
    about:
      "Kat is the author of “Why Hypnobirthing Matters” published by Pinter & Martin as well as a cognitive behavioural hypnotherapist, doula and mindfulness and meditation teacher. Her multi-disciplined approach has helped many parents welcome their baby in a calm, confident and joyous way.",
  },
  {
    photo: profilePhoto7,
    name: "Dr Kirsty Elliott-Sale",
    position: "Professor of Female Physiology",
    about:
      "Dr Elliott-Sale is the Head of the Musculoskeletal Physiology Research Group. She is also responsible for teaching on the undergraduate and postgraduate Sport Science degree programmes. Dr Elliott-Sale teaches mainly in the areas of Exercise Physiology and particularly in Female Physiology, Performance, and Health.",
  },
  {
    photo: profilePhoto8,
    name: "Laura Tilt",
    position: "Dietician",
    about:
      "With over 10 years of experience as a dietician, Laura has worked within the NHS and private healthcare sectors, contributed to various publications and has been featured on numerous podcasts and panels. Laura helps people to understand the relationships between what’s on their plate and what goes on inside the body.",
  },
  {
    photo: profilePhoto9,
    name: "Mandy Gurney",
    position: "Founder Millpond Children’s Sleep Clinic",
    about:
      "Mandy is a registered nurse, midwife and health visitor. As Director of Millpond Sleep Clinic she has specialised in children’s sleep for over 20 years. She delivers sleep workshops for the NHS, private sectors and within schools. Mandy is the author of Teach Your Child to Sleep, with a new edition out summer 2020.",
  },
  {
    photo: profilePhoto10,
    name: "Maria Elliott",
    position: "Founder Mummy MOT",
    about:
      "Maria helps mums overcome some of the biggest frustrations during pregnancy like incontinence, tummy gaps and pelvic pain by providing them with a full body assessment and bespoke exercises. Maria is also the UK’s first practitioner to offer treatments in the PelviPower™ Therapy Chair.",
  },
  {
    photo: profilePhoto11,
    name: "Marie Louise",
    position: "Midwifery and Hypnobirthing",
    about:
      "Marie Louise is an experienced Midwife, Author of The Modern Midwife’s Guide, qualified adult educator and hypnobirthing teacher. She has travelled extensively to learn about midwifery in different cultures and has also practised in Australia. Marie has been an adviser on BBC Radio 4 Woman’s Hour, Channel 5, and was invited to provide expert commentary on ITV This Morning when Meghan Markle gave birth.",
  },
  {
    photo: profilePhoto12,
    name: "Maya King",
    position: "Prenatal Transformational Movement",
    about:
      "Maya developed the Inspirational Movement, a multi-disciplinary method that brings together toning, stretching and breathing to help women find balance on their journey into motherhood. Maya helps women prepare for pregnancy, labour and giving birth with the aim of helping them feel strong, empowered and in control.",
  },
  {
    photo: profilePhoto13,
    name: "Naomi Middleton",
    position: "Clinical Psychologist",
    about:
      "Naomi is a clinical psychologist and yoga teacher who is passionate about integrating psychological and physical health. Her approach is based on helping clients to nurture their mind and body and find comfort within the discomfort.",
  },
  {
    photo: profilePhoto14,
    name: "Niamh Burn",
    position: "Pelvic Health Physiotherapist",
    about:
      "Niamh qualified as a physiotherapist in 2003 at the Royal College of Surgeons in Dublin. She’s worked extensively with the NHS and private sectors in the UK specialising in women’s health physiotherapy. Niamh helps women with pregnancy and postnatal related conditions such as pelvic pain, diastasis, pelvic organ prolapse and pelvic floor dysfunction.",
  },
  {
    photo: profilePhoto15,
    name: "Sophie Kay",
    position: "Prenatal Fitness Expert",
    about:
      "Sophie has been working in the fitness industry for 5 years and has developed a passion for helping women get stronger, fitter and gain confidence. As a new mum, she’s seen firsthand the mental and physical benefits of strength training as a post-natal woman.",
  },
  {
    photo: profilePhoto16,
    name: "Toral Shah",
    position: "Nutrition Expert",
    about:
      "Niamh qualified as a physiotherapist in 2003 at the Royal College of Surgeons in Dublin. She’s worked extensively with the NHS and private sectors in the UK specialising in women’s health physiotherapy. Niamh helps women with pregnancy and postnatal related conditions such as pelvic pain, diastasis, pelvic organ prolapse and pelvic floor dysfunction.",
  },
  {
    photo: profilePhoto17,
    name: "Yolande Diver",
    position: "Pre and Postnatal Yoga ",
    about:
      "Yolande is a qualified yoga teacher specialising in pre and postnatal yoga. She is also a birth doula and active birth teacher. Through teaching yoga, Yolande’s intention cultivates a feeling of balance and freedom in the body and mind, helping mums to tune into what their needs are and connect to their inner knowing.",
  },
  {
    photo: "",
    name: "",
    position: "",
    about: "",
  },
];
