import React, {useEffect, useState} from "react";
import './Banner.scss'
import close_banner from '../../assets/static/png/close_banner.png'

function Banner() {
  const bannerStatus = localStorage.getItem('banner')
  const [isOpen, setIsOpen] = useState<string | null>(bannerStatus)
  const clickHandler = () => {
    setIsOpen('false')
    localStorage.setItem('banner', "true")
  }

  useEffect(() => {
    const onStorage = () => setIsOpen(bannerStatus);
    window.addEventListener('storage', onStorage);
    return () => {
      window.removeEventListener('storage', onStorage);
    };
  }, []);
  return (
    <div style={isOpen === null ? {display: "block"} : {display: 'none'}} className={'banner_item'}>
      From just £16.10/ month with promo code. Incl. 30-day money back guarantee.
      <img onClick={() => clickHandler()} className={'banner_close'} src={close_banner} alt="close"/>
    </div>
  )
}

export default Banner;