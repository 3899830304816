import { actionInterface } from "../../types/common";
import { REC_ACTION_TYPES } from "../action-types";

export function actionSetRec(data: any): actionInterface {
  return {
    type: REC_ACTION_TYPES.SET_REC,
    data,
  };
}

export function actionSetProduct(data: any): actionInterface {
  return {
    type: REC_ACTION_TYPES.SET_PRODUCT,
    data,
  };
}

export function actionSetSupplements(data: any): actionInterface {
  return {
    type: REC_ACTION_TYPES.SET_SUPPLEMENTS,
    data,
  };
}
export function actionUpdateFavorite(data: string): actionInterface {
  return {
    type: REC_ACTION_TYPES.UPDATE_RECOMMENDED,
    data,
  };
}
export function actionResetRecReducer(): actionInterface {
  return {
    type: REC_ACTION_TYPES.USER_LOGOUT,
  };
}
