import {webQuiz} from "../constants/webQuiz";
import {LocalStorage} from "../services/storage";
import {CHECKPOINT} from "../constants/namespaces";
import {className, steps} from "../constants/constants";
import {Step} from "../types/quizTypes";
import {getDownloadURL, getStorage, ref} from "firebase/storage";
import {fallbackValues} from "../constants/defaultData";
import * as Yup from "yup";
import {firebaseConfig} from "../configs/firebase";

export class UtilsCommon {
  public static makeSteps() {
    const inputs = new Map();
    webQuiz.steps.forEach((step) =>{
      inputs.set(step.id, {
        answer: "",
        mm: "",
        dd: "",
        yyyy: "",
        cycle: "",
        email: "",
        password: "",
        selected: [],
      })
        }

    );
    return Object.fromEntries(inputs);
  }

  public static getCurrentStepData(step: string) {
    return webQuiz.steps.find((item: Step) => item.id === step);
  }

  public static setCheckpoint(id: string) {
    LocalStorage.set(CHECKPOINT, id);
  }

  public static getCheckpoint() {
    return LocalStorage.get(CHECKPOINT);
  }

  public static validAge(dob: Date) {
    const now = new Date();
    now.setFullYear(now.getFullYear() - 100);
    const six10ya = new Date();
    six10ya.setFullYear(six10ya.getFullYear() - 16);
    return (
      Date.parse(dob.toDateString()) >= Date.parse(now.toDateString()) &&
      Date.parse(dob.toDateString()) <= Date.parse(six10ya.toDateString())
    );
  }

  public static validDueDate(due: Date) {
    const _1day = 86400000;
    const dueDate = Date.parse(due.toDateString());
    const now = new Date();
    const _280daysfwd = Date.parse(now.toDateString()) + _1day * 280;
    return dueDate < _280daysfwd && Date.parse(now.toDateString()) < dueDate;
  }

  public static validLastPeriod(last: Date) {
    const _1day = 86400000;
    const lastPer = Date.parse(last.toDateString());
    const now = new Date();
    const _280daysago = Date.parse(now.toDateString()) - _1day * 280;
    return lastPer >= _280daysago && lastPer <= Date.parse(now.toDateString());
  }
}

export const pickClassQuizForm = (step: string) => {
  switch (step) {
    case steps.STEP15:
      return className.QUIZ_FORM_MULTI;
    case steps.STEP18:
      return className.QUIZ_FORM_MULTI;
    case steps.STEP19:
      return className.QUIZ_FORM_MULTI;
    default:
      return className.QUIZ_FORM;
  }
};
export const pickClassSingleForm = (stepData: any) => {
  switch (stepData.id) {
    case steps.STEP07:
      return className.SINGLE_FORM_MULTI;
    case steps.STEP10:
      return className.SINGLE_FORM_MULTI;
    default:
      return className.SINGLE_FORM;
  }
};

export const makeQuizObject = (
  answers: any,
  dueDate: Date,
  step: string,
  uid: string,
  trimester: number,
  email?: string
) => {
  const birthday = new Date(
    Date.parse(
      `${answers.stp0002.yyyy}-${answers.stp0002.mm}-${answers.stp0002.dd}`
    )
  );

  const currentDiet = answers.stp0007.answer.text;
  const currentDietEnum = answers.stp0007.answer.enumItem;
  const foodIntake = answers.stp0010.answer.text;
  const foodIntakeEnum = answers.stp0010.answer.enumItem;
  const hasSickness =
    answers.stp0008.answer.text === "Yes" ||
    answers.stp0009.answer.text === "Yes";
  const id = "supplementQuiz";
  const quizName = "supplementQuiz";
  const path = `users/${uid}/userData/supplementQuiz`;
  const userId = uid;
  const firstName = answers.stp0001.answer;
  const lastName = answers.stp0012.answer;
  let tagList: string[];
  if (step === "stp0020") {
    tagList = [
      `trimester_${trimester}`,
      ...answers.stp0016.selected,
      ...answers.stp0019.selected,
      ...answers.stp0020.selected,
    ];
  } else tagList = [`trimester_${trimester}`];
  const takingSupplements = answers.stp0007.answer.text === "Yes";
  const cycleLength = answers.stp0004.cycle || "";
  const firstDayOfLastPeriod =
    answers.stp0004.yyyy || answers.stp0004.mm || answers.stp0004.dd
      ? new Date(
        Date.parse(
          `${answers.stp0004.yyyy}-${answers.stp0004.mm}-${answers.stp0004.dd}`
        )
      )
      : "";
  let healthyDiet;
  if (
    answers.stp0011.answer.text ===
    "I'm pretty much there, or working on getting there"
  ) {
    healthyDiet = true;
  } else
    healthyDiet = answers.stp0011.answer.text !== "I'm a long way from this";
  const singleBaby = answers.stp0015.answer.text === "A single baby";
  const caffeine = answers.stp0018.answer.text === "Yes";
  const fluidLess2l = answers.stp0019.answer.text === "8 or more";
  const createdAt = new Date();
  const updatedAt = new Date();

  if (step === "stp0013") {
    return {
      supplementQuiz: {
        birthday,
        currentDiet,
        currentDietEnum,
        dueDate,
        foodIntake,
        foodIntakeEnum,
        hasSickness,
        id,
        email,
        name: quizName,
        path,
        takingSupplements,
        userId,
        firstName,
        lastName,
        cycleLength,
        firstDayOfLastPeriod,
        healthyDiet,
        createdAt,
        updatedAt,
      },
      userTags: {
        createdAt,
        id: "userTags",
        name: "userTags",
        path,
        updatedAt,
        userId,
        tagList,
      },
    };
  } else
    return {
      userTags: {
        tagList,
        createdAt,
        id: "userTags",
        name: "userTags",
        path,
        updatedAt,
        userId,
      },
      supplementQuiz: {
        birthday,
        currentDiet,
        currentDietEnum,
        dueDate,
        foodIntake,
        foodIntakeEnum,
        hasSickness,
        id,
        name: quizName,
        path,
        takingSupplements,
        userId,
        firstName,
        lastName,
        cycleLength,
        firstDayOfLastPeriod,
        healthyDiet,
        singleBaby,
        caffeine,
        fluidLess2l,
        createdAt,
        updatedAt,
      },
    };
};

export const getRecommendations = (
  trimester: number | string, //2
  hasSickness: boolean, //true
  foodIntakeEnum: string, //omnivore
  currentDietEnum: string,
  healthyDiet: boolean
) => {
  const output = {favorite: "", recommended: ""};
  let lastNum;
  if (typeof trimester === "string") {
    lastNum = Number(trimester.charAt(trimester.length - 1)) === 1 ? 1 : 2;
  } else {
    lastNum = trimester === 1 ? 1 : 2;
  }
  // console.log(
  //   trimester,
  //   hasSickness,
  //   foodIntakeEnum,
  //   currentDietEnum,
  //   healthyDiet
  // );
  const isCompromised = foodIntakeEnum === "compromised";
  const isReduced = foodIntakeEnum === "reduced";
  if (isReduced || !healthyDiet || isCompromised) {
    output.recommended = `pregnancy_complete_t${lastNum}`;
  }
  switch (currentDietEnum) {
    case "omnivore": {
      output.favorite = `pregnancy_essentials_t${lastNum}`;
      break;
    }
    case "flexitarian": {
      output.favorite = `flexitarian_t${lastNum}`;
      break;
    }
    case "plant_based": {
      output.favorite = `plant_based_t${lastNum}`;
      break;
    }
    case "dairy_free": {
      output.favorite = `dairy_free_t${lastNum}`;
      break;
    }
  }
  if (isCompromised && hasSickness) {
    output.favorite = `pregnancy_complete_t${lastNum}`;
  }
  return output;
};
const storage = getStorage();

export const getSrc = async (imagePath: string) => {
  try {
    const url = await getDownloadURL(
      ref(storage, `gs://${firebaseConfig.storageBucket}/${imagePath}`)
    );

    return url;
  } catch (e) {
    console.log(e);
    return fallbackValues.elements.program.img;
  }
};
export const subscriptionDate = (time: number) => {
  const dateStatus = new Date(time * 1000);
  return dateStatus.toLocaleString().split(",")[0];
};
export const nanosecondDate = (time: number) => {
  const dateStatus = new Date(time);
  return dateStatus.toLocaleString().split(",")[0];
};
export const validatePhone = (phone: string) => {
  const expression =
    /^\+?(\d{1,3})?[- .]?\(?(?:\d{2,3})\)?[- .]?\d\d\d[- .]?\d\d\d\d$/;
  return expression.test(String(phone).toLowerCase());
};
export const checkTrimester = (favorite: string) => {
  switch (favorite) {
    case "pregnancy_complete_t2":
      return "Trimester 2/3";
    case "pregnancy_complete_t1":
      return "Trimester 1";
    case "pregnancy_complete_t3":
      return "Trimester 3";
    case "The Dairy Free T1":
      return "Trimester 1";
    case "dairy_free_t2":
      return "Trimester 2";
    case "flexitarian_t1":
      return "Trimester 1";
    case "flexitarian_t2":
      return "Trimester 2/3";
    case "plant_based_t1":
      return "Trimester 1";
    case "plant_based_t2":
      return "Trimester 2/3";
    case "pregnancy_essentials_t1":
      return "Trimester 1";
    case "pregnancy_essentials_t2":
      return "Trimester 2";
  }
};
export const renderConfirmationText = () => {
  const monthAmountSub = LocalStorage.get("monthCount");
  const selectedPlan = LocalStorage.get("selectedPlan");
  if (monthAmountSub && selectedPlan === "pregnancy") {
    return `${confirmationMonthCalc(monthAmountSub)} month subscription`;
  } else if (monthAmountSub && selectedPlan === "one_off") {
    return `${confirmationMonthCalc(monthAmountSub)} month subscription`;
  } else {
    return "Monthly Subscription";
  }
};

export const generateSubPrices = (
  dueDate: Date,
  pricePregnancy: number,
  priceMonthly: number
): {
  savedAmount: number;
  monthCount: number;
  totalChargeMonthly: number;
  totalChargePregnancy: number;
  lastMonth: boolean;
} => {
  const _1day = 86400000;
  // const monthCount = Math.ceil(
  //   (dueDate.getTime() - new Date().getTime()) / (_1day * 30)
  // );
  const dateFrom = dueDate.getTime() - _1day * 280;
  let monthCount: number;
  const daysOfPregnancy = (new Date().getTime() - dateFrom) / _1day;
  switch (true) {
    case daysOfPregnancy < 30: {
      monthCount = 10;
      break;
    }
    case daysOfPregnancy < 60: {
      monthCount = 9;
      break;
    }
    case daysOfPregnancy < 90: {
      monthCount = 8;
      break;
    }
    case daysOfPregnancy < 120: {
      monthCount = 7;
      break;
    }
    case daysOfPregnancy < 150: {
      monthCount = 6;
      break;
    }
    case daysOfPregnancy < 180: {
      monthCount = 5;
      break;
    }
    case daysOfPregnancy < 210: {
      monthCount = 4;
      break;
    }
    case daysOfPregnancy < 240: {
      monthCount = 3;
      break;
    }
    case daysOfPregnancy < 270: {
      monthCount = 2;
      break;
    }
    default: {
      monthCount = 1;
    }
  }
  const totalChargePregnancy = Number((pricePregnancy / monthCount).toFixed(2));
  const totalChargeMonthly = Number((priceMonthly * monthCount).toFixed(2));
  const savedAmount = Number(
    (totalChargeMonthly - totalChargePregnancy * monthCount).toFixed(2)
  );
  const lastMonth = monthCount === 1;
  return {
    savedAmount,
    monthCount,
    totalChargeMonthly,
    totalChargePregnancy,
    lastMonth,
  };
};

export const registerForm = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[a-z ,.'-]+$/i)
    .required("Please enter a first name"),
  lastName: Yup.string()
    .matches(/^[a-z ,.'-]+$/i)
    .required("Please enter a last name"),
  email: Yup.string().email().required("Please enter an email"),
  password: Yup.string().min(8).required("Please enter a password"),
});

export const checkOutSchemaValidation = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(30, "Too Long!")
    .required("Required")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  firstName: Yup.string()
    .matches(/^[a-z ,.'-]+$/i)
    .required("Please enter a first name"),
  lastName: Yup.string()
    .matches(/^[a-z ,.'-]+$/i)
    .required("Please enter a last name"),
  email: Yup.string().email().required("Please enter an email"),
  password: Yup.string().min(8).required("Please enter a password"),
});
export const checkOutSchemaValidation4ExistedUser = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(30, "Too Long!")
    .required("Required")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
});

export const confirmationMonthCalc = (month: string) => {
  switch (month) {
    case "1":
      return "Single";
    case "2":
      return "Two";
    case "3":
      return "Three";
    case "4":
      return "Four";
    case "5":
      return "Five";
    case "6":
      return "Six";
    case "7":
      return "Seven";
    case "8":
      return "Eight";
    case "9":
      return "Nine";
    case "10":
      return "Ten";
  }
};
export default checkOutSchemaValidation;

// export const calculateCurrentWeek = (dueDate: Date) => {
//   const _1day = 86400000;
//   return dueDate.getTime() ;
// };
