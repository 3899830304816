import React, {useEffect, useState} from "react";
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import {useHistory} from "react-router-dom";
import {Routes} from "../../../../configs/routes/routes";
import Input from "../../../_common/Input/Input";
import {useSelector} from "react-redux";
import {
    cardStyle,
    cardStyleCvc,
    cardStyleYears,
} from "../../../../constants/constants";
import {store} from "../../../../types/store";
import Button from "../../../_common/Button/Button";

function ErrorMessage({children}: any) {
    return (
        <div className="ErrorMessage" role="alert">
            {children}
        </div>
    );
}

function SubmitButton({processing, error, disabled, title}: any) {
    return (
        <button
            className={`primary_button_green ${error ? "SubmitButton--error" : ""}`}
            type="submit"
            disabled={processing || disabled}
        >
            {processing ? "Processing..." : title}
        </button>
    );
}

function PaymentForm({
                         defaultPaymentMethod,
                         closeFormHAndler,
                         getPaymentMethod,
                         setLoadingHandler,
                     }: any) {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [cardComplete, setCardComplete] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [payerName, setPayerName] = useState("");
    const sKey = useSelector((state: store) => state.account.keySTR);
    const history = useHistory();
    const payerNameHandler = (value: string) => {
        setPayerName(value);
    };
    useEffect(() => {
        if (processing) {
            history.push(Routes.CONFIRMATION);
        }
    }, []);
    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if (!stripe || !elements) return;
        if (error) {
            elements.getElement("card")?.focus();
            return;
        }
        setProcessing(true);
        const confirmMethod = await stripe.confirmCardSetup(sKey, {
            payment_method: {
                // @ts-ignore
                card: elements.getElement(CardNumberElement),
                billing_details: {
                    name: payerName,
                },
            },
        });
        if (confirmMethod.error) {
            // @ts-ignore
            setError(confirmMethod.setupIntent.error);
        } else {
            defaultPaymentMethod(confirmMethod.setupIntent.payment_method);
            closeFormHAndler();
            getPaymentMethod();
            setProcessing(false);
        }
    };
    return (
        <form className="Form" onSubmit={handleSubmit}>
            <div className={"checkout-cardInfo"}>
                <Input
                    id="name"
                    className="custom-input"
                    type="text"
                    placeholder="Jane Doe"
                    required
                    value={payerName}
                    onChange={(e) => {
                        payerNameHandler(e.currentTarget.value);
                    }}
                />
                <div className={"input-container"}>
                    <CardNumberElement options={cardStyle} id="cardNumber"/>
                    <CardCvcElement options={cardStyleCvc} id={"card-cvc"}/>
                    <CardExpiryElement options={cardStyleYears} id={"card-exp"}/>
                </div>
                <div className={'payment_account_buttons'}>
                    <SubmitButton
                        processing={processing}
                        error={error}
                        disabled={processing}
                        title={"Add this card"}
                    />
                    <Button clickHandler={() => closeFormHAndler()} type={'button'} className={'secondary_button_gray'}
                            content={"CANCEL"}
                    />
                </div>
            </div>
            {error && (
                <ErrorMessage>
                    {/*// @ts-ignore*/}
                    {error.message}
                </ErrorMessage>
            )}
        </form>
    );
}

export default PaymentForm;
