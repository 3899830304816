import React, { useState } from "react";
import Button from "../../_common/Button/Button";
import "./MultipleForm.scss";
import { multipleStep } from "../../../types/quizTypes";
interface Props {
  stepData: multipleStep;
  submitHandler: (selected: string[]) => void;
}

function MultipleForm(props: Props) {
  const { stepData, submitHandler } = props;
  const [selected, setSelected] = useState<string[]>([]);
  const switchButtons = (tag: string) => {
    if (selected.includes(tag)) {
      setSelected(selected.filter((item: string) => item !== tag));
    } else {
      setSelected([...selected, tag]);
    }
  };
  return (
    <div className={"form-wrapper multiple-form"}>
      <h1>{stepData.header}</h1>
      <div className="button-container">
        {stepData.listOptions?.map(
          (option: { displayText: string; tag: string }) => {
            return (
              <Button
                className={
                  selected.includes(option.tag)
                    ? "secondary_button_green"
                    : "secondary_button_gray"
                }
                content={option.displayText}
                clickHandler={() => {
                  switchButtons(option.tag);
                }}
              />
            );
          }
        )}
      </div>
      <Button
        className={"primary_button_green"}
        content={stepData.btnText}
        clickHandler={() => {
          submitHandler(selected);
        }}
      />
    </div>
  );
}

export default MultipleForm;
