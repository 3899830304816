import React from "react";
import Modal from "../../../_common/Modal/Modal";
import Button from "../../../_common/Button/Button";

interface Props {
  clickHandlerButton?: () => void;
  clickHandlerClose?: () => void;
}
function ModalEndSubscriptionSecond({
  clickHandlerButton,
  clickHandlerClose,
}: Props) {
  return (
    <Modal title={"End subscription"} clickHandlerClose={clickHandlerClose}>
      <div className={"order_subscription_sub_title"}>
        <div className={"choose_subscription_second"}>
          <div className="round">
            <input name={"monthly"} type="checkbox" checked id="checkbox" />
            <label htmlFor="checkbox" />
          </div>
        </div>
        <h4 className={"end_subscription_title"}>
          Subscription changed successfully
        </h4>
        <p>No further payments will be taken</p>
      </div>
      <Button
        content={"Go it"}
        clickHandler={clickHandlerClose}
        className={"primary_button_green end_sub_btn"}
      />
    </Modal>
  );
}

export default ModalEndSubscriptionSecond;
