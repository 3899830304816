import React from "react";
import logo from "../../../assets/static/svg/Logo.svg";
import ChevronLeft from "../../../assets/static/svg/Chevron-left.svg";
import main_content_image from "../../../assets/static/svg/FancyPlantsPlantandPot.svg";
import "./ConnectFeedBack.scss";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../configs/routes/routes";

function ConnectFeedBack() {
  const history = useHistory();
  return (
    <div className={"connect_feed_back"}>
      <img className={"connect_logo"} src={logo} alt="main logo" />
      <div onClick={() => history.push(Routes.HOME)} className={"back_button"}>
        <img src={ChevronLeft} alt="back button" />
        <p className={"back_button_text"}>Back</p>
      </div>
      <div className={"feed_back_container"}>
        <h2 className={"feed_back_container_title"}>Thank you!</h2>
        <p className={"feed_back_container_text"}>
          We will get back to you very soon.
        </p>
        <img src={main_content_image} alt="content image" />
      </div>
    </div>
  );
}

export default ConnectFeedBack;
