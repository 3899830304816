import React, { useState } from "react";
import visa from "../../../../assets/static/icon/visa.svg";
import master from "../../../../assets/static/icon/master.svg";
import Switch from "react-switch";
import useStripeHook from "../../../../hooks/useStripeHook";
import Loader from "../../../_common/Loader/Loader";
import { useSelector } from "react-redux";
import { store } from "../../../../types/store";
import { PaymentMethod } from "@stripe/stripe-js";

function PaymentItem({ item, checkedStatus }: any) {
  const { deletePaymentMethod, defaultPaymentMethod, getUserCards } =
    useStripeHook();
  const [load, setLoad] = useState(false);
  const account = useSelector((state: store) => state.account);
  const checkedHandler = async () => {
    if (!checkedStatus) {
      setLoad(true);
      await defaultPaymentMethod(item.id);
      setLoad(false);
    } else return;
  };
  const removePaymentMethodHandler = async (id: string) => {
    setLoad(true);
    await deletePaymentMethod(id);
    if (account.paymentMethod.length !== 1) {
      await defaultPaymentMethod(
        account.paymentMethod.filter((item: any) => item.id !== id)[0].id
      );
    }
    await getUserCards();
    setLoad(false);
  };
  return (
    <div className={"payment_account_item"}>
      <div className={"payment_account_item_data"}>
        <div className={"payment_account_item_method"}>
          <img src={item.card?.brand === "VISA" ? visa : master} />
        </div>
        <div className={"payment_account_item_data_wrapper"}>
          <div className={"payment_account_item_name"}>
            {item.billing_details.name}
          </div>
          <div className={"payment_account_item_card"}>
            <div className={"payment_account_item_card_number"}>
              ···· ···· ···· {item.card?.last4}
            </div>
            <div className={"payment_account_item_card_exp"}>
              {" "}
              · EXP: {item.card?.exp_month}/{item.card?.exp_year}
            </div>
          </div>
        </div>
      </div>
      <div className={"payment_account_item_action"}>
        {load ? (
          <div className={"payment_account_loader_wrapper"}>
            <Loader />
          </div>
        ) : (
          <>
            <div className={"payment_account_item_action_primary"}>
              <span>Primary</span>
              <Switch
                uncheckedIcon={false}
                checkedIcon={false}
                offColor={"#787880"}
                onChange={checkedHandler}
                checked={checkedStatus}
              />
            </div>
            <div
              onClick={() => removePaymentMethodHandler(item.id)}
              className={"payment_account_item_action_remove"}
            >
              Remove
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default PaymentItem;
