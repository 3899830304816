import { Quiz } from "../../types/quizTypes";
import { actionInterface } from "../../types/common";
import {
  NAV_ACTION_TYPES,
  QUIZ_ACTION_TYPES,
  QUIZ_MODAL_ACTION_TYPES,
} from "../action-types";
import { quizModalReducerState } from "../reducers/quizModalReducer";

export function actionSetQuizzes(data: Quiz[]): actionInterface {
  return {
    type: QUIZ_ACTION_TYPES.SET_QUIZZES,
    data,
  };
}
export function actionResetQuizzes(): actionInterface {
  return {
    type: QUIZ_ACTION_TYPES.USER_LOGOUT,
  };
}
export function actionSetQuizNav(data: any): actionInterface {
  return {
    type: NAV_ACTION_TYPES.SET_NAV,
    data,
  };
}

export function actionSetQuizNavNext(data: string): actionInterface {
  return {
    type: NAV_ACTION_TYPES.SET_NEXT_STEP,
    data,
  };
}

export function actionSetQuizNavPrevious(data: string): actionInterface {
  return {
    type: NAV_ACTION_TYPES.SET_PREVIOUS_STEP,
    data,
  };
}

export function actionSetQuizNavCurrent(data: string): actionInterface {
  return {
    type: NAV_ACTION_TYPES.SET_CURRENT_STEP,
    data,
  };
}
export function actionResetQuizReducer(): actionInterface {
  return {
    type: NAV_ACTION_TYPES.USER_LOGOUT,
  };
}

export function actionSetArticles(data: any[]): actionInterface {
  return {
    type: QUIZ_ACTION_TYPES.SET_ARTICLES,
    data,
  };
}

export function actionSetTagging(data: any[]): actionInterface {
  return {
    type: QUIZ_ACTION_TYPES.SET_TAGGING,
    data,
  };
}

export function actionOpenModal(data: quizModalReducerState): actionInterface {
  return {
    type: QUIZ_MODAL_ACTION_TYPES.OPEN_MODAL,
    data,
  };
}

export function actionCloseModal(): actionInterface {
  return {
    type: QUIZ_MODAL_ACTION_TYPES.CLOSE_MODAL,
  };
}
