import React, {useEffect, useState} from "react";
import "./Recommendation.scss";
import {
  doc,
  getDoc,
  updateDoc,
  setDoc,
  getDocs,
  collection,
} from "firebase/firestore";
import Button from "../../components/_common/Button/Button";
import {useDispatch, useSelector} from "react-redux";
import Header from "../../components/_common/Header/Header";
import Program from "../../components/Recommendation/Programme/Programme";
import recItem2 from "../../assets/static/recommendation_img/rec-item2.svg";
import recItem3 from "../../assets/static/recommendation_img/rec-item3.svg";
import {useRecommendations} from "../../hooks/useRecommendations";
import {getDownloadURL, getStorage, ref} from "firebase/storage";
import {
  fallbackValues,
  suggestionProducts,
  suggestionProductsT2,
} from "../../constants/defaultData";
import {productState, Supplement} from "../../types/supplements";
import ProgramDetail from "../../components/Recommendation/ProgramDetail/ProgramDetail";
import useAccount from "../../hooks/useAccount";
import {auth, db, firebaseConfig} from "../../configs/firebase";
import {onAuthStateChanged} from "firebase/auth";
import {useHistory} from "react-router-dom";
import {Routes} from "../../configs/routes/routes";
import FooterMain from "../../components/_common/FooterMain/FooterMain";
import {LocalStorage} from "../../services/storage";
import {initialStateRecommendation} from "../../constants/defaultData";
import {RootState} from "../../store";
import {COMPLETE_PAY} from "../../constants/namespaces";
import Banner from "../../components/Banner/Banner";
import useAuth from "../../hooks/useAuth";

const storage = getStorage();

function Recommendation() {
  // Selectors

  const productId = useSelector((state: any) => state.rec.favorite);
  const altProductId = useSelector((state: any) => state.rec.recommended);
  const productData = useSelector((state: any) => state.rec.product);
  const user = useSelector((state: any) => state.account.accountData);
  const supplementsData = useSelector((state: any) => state.rec.supplements);
  // State
  const [supplements, setSupplements] = useState(supplementsData);
  const [suggestions, setSuggestion] = useState(suggestionProducts);
  const [ProductInStorage, setProductInStorage] =
    useState<string>(productId);
  const [items, setItems] = useState<productState>(fallbackValues);
  const [showDetail, setShowDetail] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(productId);
  const [load, setLoad] = useState(false);
  const [defaultProduct, setDefaultProduct] = useState<string>("");
  const userName = localStorage.getItem('name')
  // Hooks
  const dispatch = useDispatch();
  const {loadProduct, loadSupplements} = useRecommendations();
  const {setRecommendationsAgain, loadAccount} = useAccount();
  const {anonymousAuth}= useAuth();
  const history = useHistory();
  // Functions
  const optionSelect = (newProduct: string, currentProductId: string) => {
    setLoad(true);
    setProductInStorage(currentProductId);
    LocalStorage.set("productId", currentProductId);
    if (currentProduct !== newProduct) {
      setCurrentProduct(newProduct);
      setLoad(false);
    } else {
      setLoad(false);
      return;
    }
  };

  const currentSuggestionProducts = () => {
    if (suggestionProducts.includes(defaultProduct)) {
      const currentSuggestion = suggestionProducts.filter(
        (item: any) => item !== defaultProduct
      );
      setSuggestion(currentSuggestion);
      return suggestions;
    }
    if (suggestionProductsT2.includes(defaultProduct)) {
      const currentSuggestion = suggestionProductsT2.filter(
        (item: any) => item !== defaultProduct
      );
      setSuggestion(currentSuggestion);
      return suggestions;
    }
  };

  const makeListItems = async (productData: any) => {
    if (!productData) {
      setItems(fallbackValues);
    } else {
      const getSrc = async () => {
        try {
          return await getDownloadURL(
            ref(
              storage,
              `gs://${firebaseConfig.storageBucket}/${productData.webImagePath}`
            )
          );
        } catch (e) {
          console.log(e);
          return fallbackValues.elements.program.img;
        }
      };
      const productImg = await getSrc();
      setItems({
        ...items,
        elements: {
          program: {
            id: productData.id,
            img: productImg,
            title: productData.name || fallbackValues.elements.program.title,
            text:
              productData.description || fallbackValues.elements.program.text,
          },
          appInfo: {
            img: recItem2,
            title:
              "Holistic wellbeing and nutrition tailored for your pregnancy",
            text: `Welcome to Trimester ${productData.id.charAt(
              productData.id.length - 1
            )}. Get the confidence and reassurance you need with our holistic wellness and nutrition guidance that’s tailored to your pregnancy.`,
          },
          oooCoach: {
            img: recItem3,
            title: "Prenatal experts on tap to prepare you for your birth",
            text: "Get unlimited access to prenatal expert coaches who will provide you with one-to-one chat support for all your physical pregnancy needs, as and when you need it.",
          },
        },
      });
    }
  };
  useEffect(() => {
    setProductInStorage(productId);
  }, [productId]);
  const {accountData, orderData} = useSelector((state: RootState) => state.account);
  const {anonymousUid} = useSelector((state: RootState) => state.account.accountData);
  const userId = auth?.currentUser?.uid
  const setProductForSubscription = async () => {
    // accountData.uid;

    if (/*accountData.uid*//*anonymousUid*/ userId && ProductInStorage !== "") {
      LocalStorage.set("productId", ProductInStorage);
      await setDoc(
        doc(db, "users", userId),
        {currentProductId: currentProduct},
        {merge: true}
      );
      history.push(Routes.SUBSCRIPTION);
    }
    if (userId && ProductInStorage === "") {
      await setDoc(
        doc(db, "users", userId),
        {currentProductId: currentProduct},
        {merge: true}
      );
      LocalStorage.set("productId", productId);
      history.push(Routes.SUBSCRIPTION);
    }
  };

  // Side effects
  useEffect(() => {
    anonymousAuth()

  }, [])
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        loadAccount(user.uid).then(() => {
          if (
            accountData.hasStartedQuiz &&
            !accountData.hasCompletedQuizPart1
          ) {
            history.push(Routes.QUIZ);
          } else if (orderData.length > 0) {
            history.push(Routes.ACCOUNT);
          }
        });
        setRecommendationsAgain(user.uid).then((data) => {
          setDefaultProduct(data.favorite);
          setProductInStorage(productId);
          loadProduct(data.favorite)
          setCurrentProduct(data.favorite)
        });
      } else {
        history.push(Routes.QUIZ);
      }
    });
    return function cleanup() {
      unsubscribe();
    };
  }, [productId]);

  useEffect(() => {
    currentSuggestionProducts();
    LocalStorage.set(COMPLETE_PAY, "false");
  }, [defaultProduct]);

  useEffect(() => {
    setLoad(false);
    setCurrentProduct(productId);
  }, [productId]);

  useEffect(() => {
      loadProduct(currentProduct);
      loadSupplements().then(() => {
        setTimeout(() => setLoad(true), 2000);
      });
  }, [currentProduct]);

  useEffect(() => {
    makeListItems(productData);
  }, [productData]);

  useEffect(() => {
    setSupplements(supplementsData);
  }, [supplementsData]);

  const [showInfo, setShowInfo] = useState(initialStateRecommendation);
  const showInfoItem = (id: string) => {
    const currentDetailComponent = supplementsData.find(
      (item: Supplement) => item.id === id
    );
    setShowInfo(currentDetailComponent);
    setShowDetail(true);
  };
  useEffect(() => {
    localStorage.setItem("productId", altProductId);
  }, [altProductId]);
  return (
    <div className={"recommendation"}>
      <Banner/>
      <Header noButton/>
      <div className={"recommendation-header_title"}>
        <p>Thanks {userName || user.firstName}!</p>
        <p>Here's your personalised pregnancy recommendation</p>
        {productId && altProductId && productId !== altProductId && (
          <div className={"recommendation-options"}>
            <div
              id={productId}
              onClick={(e: React.MouseEvent) =>
                optionSelect(productId, e.currentTarget.id)
              }
              className={
                currentProduct === productId
                  ? "recommendation-selected"
                  : "recommendation-deselected"
              }
            >
              Option 1
            </div>
            <div
              id={altProductId}
              onClick={(e: React.MouseEvent) =>
                optionSelect(altProductId, e.currentTarget.id)
              }
              className={
                currentProduct === altProductId
                  ? "recommendation-selected"
                  : "recommendation-deselected"
              }
            >
              Option 2
            </div>
          </div>
        )}
      </div>
      <Program
        defaultProduct={defaultProduct}
        learnMore={() =>
          showInfoItem(currentProduct || "pregnancy_essentials_t1")
        }
        loading={load}
        items={items.elements}
      />
      <div className={"recommendation-buttons"}>
        <div className={"recommendation-buttons_container"}>
          <Button
            className={"primary_button_green"}
            content={"Add & Continue"}
            clickHandler={setProductForSubscription}
          />
        </div>
      </div>
      <div
        style={{width: showDetail ? "100%" : "0"}}
        className={"blackout"}
      />

      <ProgramDetail
        showModal={showDetail}
        closeOnClick={() => setShowDetail(false)}
        sup={showInfo}
        productImage={items.elements.program.img}
        suggestion={suggestions}
        setProductInStorage={setProductInStorage}
        setSuggestion={setSuggestion}
        setShowDetail={setShowDetail}
      />

      <FooterMain  linksShown={false}/>
    </div>
  );
}

export default Recommendation;
