import React, { useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "../paymentForms/PaymentForm";
import useStripeHook from "../../../../hooks/useStripeHook";

interface Props {
  addPayment: () => void;
  payment : boolean;
  setLoading: (value: boolean) => void;
}
function AddPayment({ addPayment, setLoading,payment }: Props) {
  const { startSetupIntents, defaultPaymentMethod, getUserCards } =
    useStripeHook();
  useEffect(() => {
    startSetupIntents();
  }, []);
  let key =
    "pk_test_51JUrJEDEcgDmZVsQjJHlzwRRC3cCzTefVbBqUlgITqWljAYtH0G3Bz9hnmEwLGygMLTwOqf6MkYyZ0ns8ZWhLOcK00tZQDE0Ac";
  return (
    <Elements stripe={loadStripe(key)}>
      <PaymentForm
        setLoadingHandler={setLoading}
        getPaymentMethod={getUserCards}
        closeFormHAndler={addPayment}
        defaultPaymentMethod={defaultPaymentMethod}
      />
    </Elements>
  );
}

export default AddPayment;
