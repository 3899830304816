import React, {useEffect, useState} from 'react';
import Button from "../../../_common/Button/Button";
import {useHistory} from "react-router-dom";
import {Routes} from "../../../../configs/routes/routes";
import {useSelector} from "react-redux";
import {LocalStorage} from "../../../../services/storage";

function OrderNoSubscription() {
  const history = useHistory()
  const {recommended} = useSelector((state: any) => state.rec)
  const [recProduct, setRecProduct] = useState(recommended)
  useEffect(() => {
    setRecProduct(recommended)
    LocalStorage.set('recProduct', recProduct)
  }, [recommended])
  return (
    <div className={'no_subscription_wrapper'}>
      <h2>No active subscriptions</h2>
      <Button content={'SUBSCRIBE NOW'} className={'primary_button_green'} clickHandler={() => {
        history.push(Routes.RECOMMENDATION)
      }}/>
    </div>
  );
}

export default OrderNoSubscription;
