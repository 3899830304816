import React, {ChangeEvent, useEffect, useState} from "react";
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import Input from "../../_common/Input/Input";
import "../../../pages/Checkout/Checkout.scss";
import BianuPromise from "../../../assets/static/png/newBianuPromise.png";
import {EmailAuthProvider, getAuth, linkWithCredential} from "firebase/auth";

import {
  cardIcon,
  initialBillingData,
  initialFormikData, initialFormikData4ExistedUser,
  initialShippingData, termData,
} from "../../../constants/defaultData";
import {useHistory} from "react-router-dom";
import ShippingInputs from "../../ShippingInputs/ShippingInputs";
import BillingInputs from "../../BillingInputs/BillingInputs";
import {store} from "../../../types/store";
import {auth, db} from "../../../configs/firebase";
import useAccount from "../../../hooks/useAccount";
import useUpdateSubscription from "../../../hooks/useUpdateSubscription";
import {useSelector} from "react-redux";
import {LocalStorage} from "../../../services/storage";
import useStripeHook, {subObj} from "../../../hooks/useStripeHook";
import Button from "../../_common/Button/Button";
import PaymentSummary from "../../PaymentSummary/PaymentSummary";
import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import useSubscription from "../../../hooks/useSubscription";
import {Subscription} from "../../../types/subscription";
import {Form, Formik, Field, FormikValues} from "formik";
import checkOutSchemaValidation, {checkOutSchemaValidation4ExistedUser} from "../../../utils/UtilsCommon";
import {COMPLETE_PAY} from "../../../constants/namespaces";
import {Routes} from "../../../configs/routes/routes";
import RegisterForm from "../../RegisterForm/RegisterForm";
import {TermsData} from "../../../types/checkout";

const renderCards = () =>
  cardIcon.map((item: string, index: number) => {
    return <img key={index} src={item} alt="card-icon"/>;
  });


function CheckoutForm({
                        currentPlan,
                        setErrorMessage,
                        isError
                      }: any) {
  const cardStyle = {
    placeholder: "Card Number",
    style: {
      base: {
        fontSize: "16px",
      },
      invalid: {},
    },
    hidePostalCode: true,
  };
  const stripe = useStripe();
  const auth = getAuth();
  const {updateSubscription} = useUpdateSubscription();
  const {loadAccount, setRecommendationsAgain, sendAddress, updateField, setAccountDocument} =
    useAccount();
  const {
    getUserCards, startSetupIntents, createCustomerAddress, firstCreateStripeCustomer
  } = useStripeHook();
  const {loadSubscription} = useSubscription();
  const elements = useElements();
  const {uid, promoCodeId, address_id, firstName} = useSelector(
    (state: store) => state.account.accountData
  );
  const first_name = localStorage.getItem('name')
  const subscriptions = useSelector((state: any) => state.subscription);
  const [anonUid, setAnonUid] = useState<string>('')
  const [error, setError] = useState<any>(null);
  const [cardError, setCardError] = useState<boolean>(true)
  const [processing, setProcessing] = useState(false);
  const [terms, setTerms] = useState<TermsData>(termData)
  const [shippingDetails, setShippingDetails] = useState(initialShippingData);
  let [billingDetails, setBillingDetails] = useState(initialBillingData);
  const [billingCountryList, setBillingCountryList] = useState(false);
  const [checked, setChecked] = useState(false);
  const [coupon, setCoupon] = useState({
    promo: "",
    validatedPromo: "",
    validateError: "",
    percentage: 0,
    totalPrice: "0",
    totalSaved: "0",
  });

  const localStorageSubId = LocalStorage.get("chosenPrice");
  const localStorageRecProduct = LocalStorage.get("productId");
  const history = useHistory();
  const chosenSubscription = subscriptions.find(
    (item: Subscription) => item.id === localStorageSubId
  );
  const totalPrice = () => {
    if (coupon.percentage) {
      let decreasePercent = (100 - coupon.percentage) / 100;
      let total = (chosenSubscription?.amount / 100) * decreasePercent;
      let totalSaved = chosenSubscription?.amount / 100 - total;
      setCoupon({
        ...coupon,
        totalPrice: total.toFixed(2),
        totalSaved: totalSaved.toFixed(2),
      });
    } else {
      setCoupon({
        ...coupon,
        totalPrice: `${chosenSubscription?.amount / 100}`,
      });
    }
  };
  const promoCodeValidation = async () => {
    const promoCodesRef = collection(db, "bianu_1.00", "en_uk", "promoCodes");
    const q = query(
      promoCodesRef,
      where("name", "==", coupon.promo.toLowerCase())
    );

    try {
      await getDocs(q).then((data) => {
        if (data.docs.length > 0) {
          data.docs.forEach((doc) => {
            const promoCodeObject = doc.data();
            setCoupon({
              ...coupon,
              validatedPromo: promoCodeObject.stripeCouponId,
              percentage: promoCodeObject.percent,
            });
            if (anonUid || uid) {
              updateField(anonUid, "promoCodeId", promoCodeObject.id);
            }
          });
        } else {
          setCoupon({...coupon, validateError: "Error"});
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (!auth?.currentUser?.isAnonymous) {
      setTerms({...terms, accepted: true})
    }
    const listener = auth.onAuthStateChanged((user) => {
      if (user !== null) {
        setAnonUid(user.uid)
        loadAccount(user.uid);
        setRecommendationsAgain(user.uid);
        getUserCards();
        loadSubscription();
      }
    });
    return () => listener();

  }, []);
  useEffect(() => {
    totalPrice();
  }, [coupon.percentage, localStorageSubId]);

  useEffect(() => {
    if (checked) {
      setBillingDetails({
        ...billingDetails,
        name: shippingDetails.name,
        address: {...shippingDetails.address},
      });
    }
  }, [shippingDetails]);
  useEffect(() => {
    async function isExistPromo() {
      const promoCodesRef = collection(db, "bianu_1.00", "en_uk", "promoCodes");
      const q = query(promoCodesRef, where("id", "==", promoCodeId));
      await getDocs(q).then((data) => {
        data.forEach((doc) => {
          const isExist = doc.data();
          setCoupon({
            ...coupon,
            promo: isExist.name,
            validatedPromo: isExist.stripeCouponId,
            percentage: isExist.percent,
          });
        });
      });
    }

    if (promoCodeId) {
      isExistPromo();
    }
  }, [promoCodeId]);

  const paymentShippingDataHandler = (value: string, name: string) => {
    setShippingDetails({
      ...shippingDetails,
      address: {...shippingDetails.address, [name]: value},
    });
  };
  const paymentBillingDataHandler = (value: string, name: string) => {
    setBillingDetails({
      ...billingDetails,
      address: {...billingDetails.address, [name]: value},
    });
  };
  const changeInputStatus = () => {
    setChecked(!checked);
    if (!checked) {
      setBillingDetails({
        ...billingDetails,
        address: {...shippingDetails.address},
      });
    } else {
      setBillingDetails(initialBillingData);
    }
  };

  const SubmitButton = ({processing, error, children, disabled}: any) => {
    return (
      <button
        type={"submit"}
        className={`primary_button_green ${error ? "SubmitButton--error" : ""}`}
        disabled={processing || disabled}
        onClick={() => LocalStorage.set(COMPLETE_PAY, "false")}
      >
        {processing ? "Processing..." : children}
      </button>
    );
  };

  const handleSubmit = async (values: FormikValues) => {
    if (!stripe || !elements) {
      return;
    }

    if (auth.currentUser && auth.currentUser.isAnonymous) {
      const credential = EmailAuthProvider.credential(values.email, values.password);

      await linkWithCredential(auth.currentUser, credential)
        .then((usercred) => {
          const userId = usercred.user.uid;
          setAccountDocument({uid: userId, email: values.email}, {
            firstName: values.firstName,
            lastName: values.lastName
          })
          setAnonUid(userId)
        }).catch((error) => {
          console.log(error);
        });
    }
    setProcessing(true);
    await firstCreateStripeCustomer()
    await startSetupIntents();

    const noLine2Address = () => {
      if (billingDetails.address.line2 === "") {
        return {
          name: billingDetails.name,
          address: {
            line1: billingDetails.address.line1,
            city: billingDetails.address.city,
            postal_code: billingDetails.address.postal_code,
            country: billingDetails.address.country,
          },
        };
      }
    };
    setProcessing(true);
    if (
      !stripe
    ) {
      setProcessing(false);
    } else {
      setProcessing(true);
      await createCustomerAddress({
        line1: shippingDetails.address.line1,
        line2: shippingDetails.address.line2,
        city: shippingDetails.address.city,
        country: shippingDetails.address.country,
        postcode: shippingDetails.address.postal_code,
      })
      await updateSubscription(anonUid || uid, {
        priceId: localStorageSubId,
        productId: localStorageRecProduct,
        rootPath: "bianu_1.00/en_uk",
        couponId: coupon.validatedPromo,
      } as subObj).then(async ({data}) => {
        const cardElements = elements.getElement(CardElement)
        if (cardElements) {
          //@ts-ignore
          await stripe.confirmCardPayment(data.clientSecret, {
            payment_method: {
              card: cardElements,
              billing_details:
                billingDetails.address.line2 === ""
                  ? noLine2Address()
                  : billingDetails,
            }
          }).then(async (data) => {
            if (data.paymentIntent?.status === 'succeeded') {
              const addressObj = {
                fullName: billingDetails.name,
                line1: shippingDetails.address.line1,
                line2: shippingDetails.address.line2,
                town: shippingDetails.address.city,
                postcode: shippingDetails.address.postal_code,
                isPrimary: true,
              };
              await sendAddress(`${anonUid || uid}`, addressObj, address_id);
              if (anonUid || uid)
                await setDoc(
                  doc(db, "users", uid || anonUid),
                  {hasCompletedSupplementQuiz: true},
                  {merge: true}
                );
              //@ts-ignore
              cqet("send", "Purchase", {
                revenue: `${coupon.totalPrice}`,
                currency: "GBP",
                transactionId: "id",
                userId: `${billingDetails.name}`,
              });

              history.push(Routes.CONFIRMATION);
            } else {
              setProcessing(false)
            }

          })
        }
      });

    }
  };
  return (
    <>
      <Formik
        initialValues={auth.currentUser?.isAnonymous ? {
          ...initialFormikData,
          firstName: first_name
        } : initialFormikData4ExistedUser}
        onSubmit={async (values) => {
          await handleSubmit(values);
        }}
        validationSchema={auth.currentUser?.isAnonymous ? checkOutSchemaValidation : checkOutSchemaValidation4ExistedUser}
        validateOnChange={true}
        validateOnBlur={true}
        validateOnMount={false}
      >
        {({errors, values, touched, handleChange}) => {
          // @ts-ignore
          const formikTouched = Object.keys(touched).filter((touched) => touched);
          // @ts-ignore
          const formikErrors = Object.keys(errors).filter((error) => errors[error]);
          const intersection = formikErrors.filter(element => formikTouched.includes(element));
          intersection.length >0 ||
          coupon.validateError ||
          // @ts-ignore
          error?.message
            ? setErrorMessage(true)
            : setErrorMessage(false);

          const sameAddress = () => {
            if (checked) {
              setShippingDetails((current) => {
                return {
                  ...shippingDetails,
                  name: values.name,
                  address: {
                    city: values.shipping_city,
                    line1: values.shipping_line1,
                    line2: values.shipping_line2,
                    country: "GB",
                    postal_code: values.shipping_postal_code,
                  },
                };
              });
            } else {
              setShippingDetails((current) => {
                return {
                  ...shippingDetails,
                  name: values.name,
                  address: {
                    city: values.shipping_city,
                    line1: values.shipping_line1,
                    line2: values.shipping_line2,
                    country: "GB",
                    postal_code: values.shipping_postal_code,
                  },
                };
              });
              setBillingDetails(() => {
                return {
                  ...billingDetails,
                  name: values.name,
                  address: {
                    city: values.city,
                    line1: values.line1,
                    line2: values.line2,
                    country: "GB",
                    postal_code: values.postal_code,
                  },
                };
              });
            }
          };
          return (
            <Form className={"checkout_main_form"} onChange={sameAddress}>
              {

                auth?.currentUser?.isAnonymous &&
                <RegisterForm touched={touched} terms={terms} setTerms={setTerms} values={values} errors={errors}
                              handleChange={handleChange}/>
              }              <h1 className="payment-details-header">Payment details</h1>
              <div className={"checkout-cardInfo"}>
                <div className={"checkout-cardInfo_general_inputs"}>
                  <div className="payment-method-container">
                    <p className={"checkout-cardInfo-paymentTitle"}>
                      Payment method
                    </p>
                    <div className={"input_wrapper"}>
                      <p className={"checkout_error"}>{touched.name && errors.name}</p>
                      <Field
                        id="name"
                        className={
                          touched.name && errors.name
                            ? "custom-input custom-input_error"
                            : "custom-input"
                        }
                        type="text"
                        placeholder="Name on card"
                        required
                        name={"name"}
                        min={6}
                        max={30}
                      />
                    </div>
                    <div className={"input-container"}>
                      {error?.message && (
                        <p className={"card-error"}>{error.message}</p>
                      )}
                      <CardElement
                        onChange={(e) => {
                          if (e.complete) {
                            setCardError(false)
                          } else {
                            setCardError(true)
                          }
                          if (e.error?.message) {
                            //@ts-ignore
                            setError(e.error);
                          }else{
                            setError(null)
                          }
                        }}
                        options={cardStyle}
                        id="cardNumber"
                      />
                    </div>
                    <div className={"payment_method_wrapper"}>
                      {renderCards()}
                    </div>
                  </div>
                  <div className="promocode-input-container">
                    <p className={"promocode_title"}>Promo code</p>
                    <div className={"promocode_form"}>
                      <p className={"promocode_error"}>
                        {coupon.validateError}
                      </p>

                      <Input
                        type="text"
                        id="promocode"
                        value={coupon.promo}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setCoupon({
                            ...coupon,
                            promo: e.currentTarget.value,
                            validateError: "",
                          })
                        }
                        placeholder="Promo code"
                        className={
                          coupon.validateError
                            ? "custom-input_error"
                            : "custom-input"
                        }
                        disabled={coupon.validatedPromo !== ""}
                      />
                      <Button
                        type="button"
                        className="primary_button_green"
                        content={"Apply"}
                        clickHandler={() => promoCodeValidation()}
                        disabled={coupon.validatedPromo !== ""}
                      />
                    </div>
                  </div>
                </div>
                <div className={"checkout-cardInfo_address_inputs"}>
                  <div className="shipping-wrapper">
                    <p className={"checkout-cardInfo-addressTitle"}>
                      Shipping address
                    </p>
                    <ShippingInputs
                      paymentShippingDataHandler={paymentShippingDataHandler}
                    />
                  </div>
                  <div className="billing-wrapper">
                    <div className={"checkout-cardInfo_billingAddress"}>
                      <p className={"checkout-cardInfo-addressTitle"}>
                        Billing address
                      </p>
                      {/*<div>*/}
                      {/*  <Input*/}
                      {/*    onChange={(e) => changeInputStatus()}*/}
                      {/*    className={"checkout-checkBox_input"}*/}
                      {/*    type={"checkbox"}*/}
                      {/*    label={"Same as shipping address"}*/}
                      {/*    checked={checked}*/}
                      {/*  />*/}
                      {/*</div>*/}
                      <div className={"sameAddress_container"}>
                        <div className="round_monthly">
                          <input
                            id={"same_address"}
                            onChange={(e) => changeInputStatus()}
                            type="checkbox"
                            checked={checked}
                          />
                          <label htmlFor={"same_address"}/>
                        </div>
                        <p className={"same_address_text"}>
                          Same as shipping address
                        </p>
                      </div>
                    </div>
                    <BillingInputs
                      showList={billingCountryList}
                      setShowList={setBillingCountryList}
                      setBillingAddressCountry={setBillingDetails}
                      BillingAddressCountry={billingDetails}
                      paymentBillingDataHandler={paymentBillingDataHandler}
                      checked={checked}
                    />
                  </div>
                </div>
                <div className={"checkout-cardInfo_footer"}>
                  <div className="submitWrapper">
                    <PaymentSummary
                      plan={currentPlan}
                      promoCode={coupon.validatedPromo && coupon.promo}
                      saved={coupon.totalSaved && `£${coupon.totalSaved}`}
                      shipping={"free"}
                      total={coupon.totalPrice && `£${coupon.totalPrice}`}
                    />
                    <SubmitButton
                      type={"submit"}
                      processing={processing}
                      error={error}
                      disabled={cardError || !terms.accepted}
                    >
                      Start subscription
                    </SubmitButton>
                    <p className={"payment-secure_text"}>
                      Payments are processed securely{" "}
                    </p>
                  </div>
                  <div className="bianu-promise">
                    <img src={BianuPromise} alt="Bianu Promise"/>
                    <h1 className={"bianu_promise_title"}>
                      Our promise to you
                    </h1>
                    <p>
                      Ensuring you have the best pregnancy possible is our #1
                      priority. Which means we’re obsessed with providing you
                      and your baby with a brilliant product and service. And in
                      the first 30-days if you don’t love Bianu for any reason
                      at all, we’ll refund your first month. Just reach out to
                      the Bianu team. We’re here for you and your baby.
                    </p>
                  </div>
                </div>
              </div>
              {/*{error && (*/}
              {/*  <ErrorMessage>*/}
              {/*    <div>*/}
              {/*      Please check the entered information or reload the page.*/}
              {/*    </div>*/}
              {/*    {*/}
              {/*      // @ts-ignore*/}
              {/*      error.message*/}
              {/*    }*/}
              {/*  </ErrorMessage>*/}
              {/*)}*/}
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default CheckoutForm;
