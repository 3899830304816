import React, {useEffect, useState} from "react";
import Header from "../../components/_common/Header/Header";
import "./Checkout.scss";
import Footer from "../../components/_common/Footer/Footer";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import CheckoutForm from "../../components/Forms/CheckoutForm/CheckoutForm";
import useStripeHook from "../../hooks/useStripeHook";
import {auth, firebaseConfig} from "../../configs/firebase";
import useAccount from "../../hooks/useAccount";
import {Link} from "react-router-dom";
import Program from "../../components/Recommendation/Programme/Programme";
import {fallbackValues} from "../../constants/defaultData";
import CheckoutModal from "../../components/_common/CheckOutModal/CheckoutModal";
import {LocalStorage} from "../../services/storage";
import {getDownloadURL, getStorage, ref} from "firebase/storage";
import recItem2 from "../../assets/static/recommendation_img/rec-item2.svg";
import recItem3 from "../../assets/static/recommendation_img/rec-item3.svg";
import {productState} from "../../types/supplements";
import {useSelector} from "react-redux";
import {useRecommendations} from "../../hooks/useRecommendations";
import FooterMain from "../../components/_common/FooterMain/FooterMain";
import Banner from "../../components/Banner/Banner";
import RegisterForm from "../../components/RegisterForm/RegisterForm";

const storage = getStorage();

function Checkout() {
  const {startSetupIntents, defaultPaymentMethod,     firstCreateStripeCustomer} = useStripeHook();
  const {setRecommendationsAgain, loadAccount, getUserAddress} = useAccount();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const localStorageMonthCount = LocalStorage.get("monthCount");
  const localStoragePlanType = LocalStorage.get("selectedPlan");
  const localStorageProductId = LocalStorage.get("productId");
  const liveKey = 'pk_live_51IU8KAA3CZnBjx6Yym1fSDTukuhxyyLM6uAopBmUV1rZuOFwGfQVYymVH0UoxUbhfyoiKEekXVEtRK5GB4a0w1ZQ00afgqczWy'
  // let key =
  //   "pk_test_51JUrJEDEcgDmZVsQjJHlzwRRC3cCzTefVbBqUlgITqWljAYtH0G3Bz9hnmEwLGygMLTwOqf6MkYyZ0ns8ZWhLOcK00tZQDE0Ac";
  useEffect(() => {
      const listener = auth.onAuthStateChanged((user) => {
        if (user !== null) {

          loadAccount(user.uid);
          setRecommendationsAgain(user.uid);
          getUserAddress(user.uid);
          if (localStorageProductId) {
            loadProduct(localStorageProductId);
          }
        }
      });
      return () => listener();


  }, []);
  const [items, setItems] = useState<productState>(fallbackValues);
  const productData = useSelector((state: any) => state.rec.product);
  const {loadProduct} = useRecommendations();
  const productId = useSelector((state: any) => state.rec.favorite);

  useEffect(() => {
    if (localStorageProductId) {
      makeListItems(productData);
    }
  }, [productData]);
  const makeListItems = async (productData: any) => {
    if (!productData) {
      // setItems(fallbackValues);
    } else {
      const getSrc = async () => {
        try {
          return await getDownloadURL(
            ref(
              storage,
              `gs://${firebaseConfig.storageBucket}/${productData.webImagePath}`
            )
          );
        } catch (e) {
          console.log(e);
          return fallbackValues.elements.program.img;
        }
      };
      const productImg = await getSrc();
      setItems({
        ...items,
        elements: {
          program: {
            id: productData.id,
            img: productImg,
            title:
            productData.name /* || fallbackValues.elements.program.title*/,
            text: productData.description /* || fallbackValues.elements.program.text*/,
          },
          appInfo: {
            img: recItem2,
            title:
              "Holistic wellbeing and nutrition tailored for your pregnancy",
            text: `Welcome to Trimester 1. Get the confidence and reassurance you need with our holistic wellness and nutrition guidance that’s tailored to your pregnancy.`,
          },
          oooCoach: {
            img: recItem3,
            title: "Prenatal experts on tap to prepare you for your birth",
            text: "Get unlimited access to prenatal expert coaches who will provide you with one-to-one chat support for all your physical pregnancy needs, as and when you need it.",
          },
        },
      });
      setLoading(true);
    }
  };
  return (
    <>
      <Banner/>
      <div className={"checkout"}>
        {showModal && (
          <CheckoutModal closeModal={() => setShowModal(!showModal)}/>
        )}
        <Header noButton/>
        <h1>
          {localStoragePlanType === "pregnancy"
            ? `${localStoragePlanType} Plan - ${localStorageMonthCount} month${
              localStorageMonthCount === "1" ? "" : "s"
            }`
            : localStoragePlanType === "one_off"
              ? "Single Month Plan"
              : "Monthly Plan"}
        </h1>
        <div className="change-plan-link">
          {localStorageMonthCount !== "1" && (
            <Link to={"#"} onClick={(e) => setShowModal(!showModal)}>
              Change subscription plan
            </Link>
          )}
        </div>

        <div className={"checkout-container"}>
          <div className="recommendation">
            <Program loading={loading} items={items.elements} short={true}/>
          </div>
          <div
            style={!error ? {height: "0"} : {height: "86px"}}
            className="payment_error"
          >
            <h3 style={!error ? {display: "none"} : {display: "block"}}>
              Please check your details before continuing.
            </h3>
          </div>
          <Elements stripe={loadStripe(liveKey)}>
            <CheckoutForm
              currentPlan={items.elements.program.title}
              defaultPaymentMethod={defaultPaymentMethod}
              setErrorMessage={setError}
              isError={error}
            />
          </Elements>
          {/*<TotalPriceInfo />*/}
        </div>
        <FooterMain/>
      </div>
    </>
  );
}

export default Checkout;
