export interface appState {
  appData: any;
}
export type appAction = fetchAppAction;

export enum appActionTypes {
  GET_DATA = "GET_DATA",
}
interface fetchAppAction {
  type: appActionTypes.GET_DATA;
  data: any[];
}
export const QUIZ_ACTION_TYPES = {
  SET_QUIZZES: "SET_QUIZZES",
  USER_LOGOUT: "USER_LOGOUT",
  SET_ARTICLES: "SET_ARTICLES",
  SET_TAGGING: "SET_TAGGING",
};

export const SUBSCRIPTION = {
  GET_SUBSCRIPTION: "GET_SUBSCRIPTION",
  USER_LOGOUT: "USER_LOGOUT",
  SET_CURRENT_SUBSCRIPTION: "SET_CURRENT_SUBSCRIPTION",
};

export const PROGRAM = {
  SHOW_DETAIL: "SHOW_DETAIL",
  USER_LOGOUT: "USER_LOGOUT",
};
export const REC_ACTION_TYPES = {
  SET_REC: "SET_REC",
  SET_PRODUCT: "SET_PRODUCT",
  SET_SUPPLEMENTS: "SET_SUPPLEMENTS",
  UPDATE_RECOMMENDED: "UPDATE_RECOMMENDED",
  USER_LOGOUT: "USER_LOGOUT",
  UPDATE_FAVORITE: "UPDATE_FAVORITE",
};
export const PRODUCT_ACTION_TYPES = {
  GET_PRODUCT: "GET_PRODUCT",
  USER_LOGOUT: "USER_LOGOUT",
};
export const NAV_ACTION_TYPES = {
  SET_CURRENT_STEP: "SET_CURRENT_STEP",
  SET_NEXT_STEP: "SET_NEXT_STEP",
  SET_PREVIOUS_STEP: "SET_PREVIOUS_STEP",
  SET_ALT: "SET_ALT",
  SET_NAV: "SET_NAV",
  USER_LOGOUT: "USER_LOGOUT",
};

export const QUIZ_MODAL_ACTION_TYPES = {
  OPEN_MODAL: "OPEN_MODAL",
  CLOSE_MODAL: "CLOSE_MODAL",
};
