import React, { useEffect, useState } from "react";
import Modal from "../../../_common/Modal/Modal";
import Button from "../../../_common/Button/Button";

import { useSelector } from "react-redux";
import {
  checkTrimester,
  subscriptionDate,
} from "../../../../utils/UtilsCommon";
import useSubscription from "../../../../hooks/useSubscription";
import { Subscription } from "../../../../types/subscription";
import Loader from "../../../_common/Loader/Loader";
import { LocalStorage } from "../../../../services/storage";
import { auth } from "../../../../configs/firebase";
import useAccount from "../../../../hooks/useAccount";

interface Props {
  clickHandlerButton?: () => void;
  clickHandlerClose?: () => void;
  currentSubscription: any;
  orderData: any;
}
function ModalModifySecond({
  clickHandlerButton,
  orderData,
  clickHandlerClose,
}: Props) {
  const dataSubscription = useSelector((state: any) => state.subscription);
  const currentSubscription = dataSubscription.find(
    (item: Subscription) => item.id === orderData.priceId
  );
  const { supplements } = useSelector((state: any) => state.rec);
  const [loading, setLoading] = useState(true);
  const subscriptionId = localStorage.getItem("SubscriptionId");
  const { title, currencySymbol, amount } = currentSubscription;
  const { loadSubscription } = useSubscription();
  const { loadAccount, loadOrderData } = useAccount();

  const currentTrimester = useSelector((state: any) => state.rec.favorite);
  useEffect(() => {
    const listener = auth.onAuthStateChanged((firebaseUser) => {
      if (firebaseUser !== null) {
        loadSubscription().then(() => {
          loadAccount(firebaseUser.uid);
          loadOrderData(firebaseUser.uid);
          setLoading(false);
        });
      }
    });
    return () => listener();
  }, []);
  return (
    <Modal title={"Modify subscription"} clickHandlerClose={clickHandlerClose}>
      <div className={"order_subscription_sub_title"}>
        <div className={"choose_subscription_second"}>
          <div className="round">
            <input name={"monthly"} type="checkbox" checked id="checkbox" />
            <label htmlFor="checkbox" />
          </div>
        </div>
        <h4>Subscription changed successfully</h4>
      </div>
      <div className={"order_subscription"}>
        <ul className={"order_account_top_wrapper_right_order_list"}>
          <li className={"first_item"}>
            ✔
            {supplements &&
              supplements.map((item: any) => {
                if (item.id === orderData.productId) {
                  return item.name;
                }
              })}
          </li>
          <li>✔ 30 sachets per pack</li>
          <li>✔ Access to 1:1 coaching</li>
          <li>✔ Nutrition & wellness programme</li>
          <li>✔ Cancel subscription any time</li>
        </ul>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div className={"current_subscription"}>
          <div className={"choose_subscription_left"}>
            <span>
              Next payment -{" "}
              {subscriptionDate(orderData.currentPeriodEnd.seconds)}
            </span>
            <p>{title}</p>
          </div>
          <div className={"choose_subscription_right"}>
            <div>Subscribed</div>
            <p>
              {currencySymbol}
              {amount / 100}
            </p>
          </div>
        </div>
      )}
      <Button
        content={"Go it"}
        clickHandler={clickHandlerClose}
        className={"secondary_button_gray"}
      />
    </Modal>
  );
}

export default ModalModifySecond;
