import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {store} from "../../../../types/store";

function OrderCartAddress() {
    const {accountData, paymentMethod, defaultPaymentMethod} = useSelector(
        (state: store) => state.account
    );
    const payMethod =
        paymentMethod &&
        paymentMethod.find((i: any) => i.id === defaultPaymentMethod);
    // const state = useSelector((state: store) => state);
    const {line1, line2, country, town, post_code} = accountData;
    return (
        <div className={"order_account_global_info"}>
            {payMethod !== undefined &&
            payMethod.billing_details.address.city !== null && null/*(
          <div className={"order_account_global_info_item"}>
            <p>Billing Address</p>
            <span>
              {payMethod.billing_details.address.city},
              {payMethod.billing_details.address.line2},
              {payMethod.billing_details.address.line1},
              {/!*{payMethod.billing_details.address.country},*!/}
              {payMethod.billing_details.address.postal_code}
            </span>
          </div>
        )*/}
            {line1 !== undefined ? (
                <div className={"order_account_global_info_item"}>
                    <p>Delivery Address</p>
                    <span>
            {line1 && `${line1},`} {line2 && `${line2},`} {town && `${town},`} {post_code && `${post_code}`}
          </span>
                </div>
            ) : null}
            {payMethod && (
                <div className={"order_account_global_info_item"}>
                    <p>Payment Method</p>
                    <span>Visa ending in *{payMethod.card.last4}</span>
                </div>
            )}
            {/*<div className={"order_account_global_info_item"}>*/}
            {/*  <p>Shipping Method</p>*/}
            {/*  <span>Express - Track order</span>*/}
            {/*</div>*/}
        </div>
    );
}

export default OrderCartAddress;
