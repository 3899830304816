import { defaultStateShowDetails } from "../../constants/defaultData";
import { actionInterface } from "../../types/common";
import { PROGRAM } from "../action-types";

export interface showDetailStateInterface {
  showDetail: boolean;
}

export const showDetailsReducer = (
  state: showDetailStateInterface = defaultStateShowDetails,
  action: actionInterface
) => {
  switch (action.type) {
    case PROGRAM.SHOW_DETAIL:
      return {
        ...state,
        showDetail: action.data,
      };
    case PROGRAM.USER_LOGOUT:
      return defaultStateShowDetails;
  }
  return state;
};
