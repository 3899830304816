export enum Routes {
  ACCOUNT = "/account",
  CART = '/cart',
  CONNECT = '/connect',
  HOME = '/',
  LOGIN = '/login',
  QUIZ = '/quiz',
  RECOMMENDATION = '/recommendation',
  TRUST = '/trust',
  REGISTRATION_EMAIL = '/email',
  REGISTRATION_INBOX = '/inbox',
  REGISTRATION_PASSWORD = '/password',
  CONFIRMATION = '/confirmation',
  SUBSCRIPTION = '/subscription',
  APP = '/app',
  CHECKOUT = '/checkout',
  FAQ = '/faq',
  TERMS = '/terms',
  PRIVACY_POLICY = '/privacy',
  CONNECT_FEED_BACK ='/feed_back',

}
