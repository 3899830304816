import React, { useEffect, useState } from "react";
import Modal from "../../../_common/Modal/Modal";
import { SVG } from "../../../../constants/svg";
import { useSelector } from "react-redux";
import { store } from "../../../../types/store";
import useStripeHook from "../../../../hooks/useStripeHook";
import useAccount from "../../../../hooks/useAccount";
import PaymentItem from "../../payment/paymentItem/PaymentItem";

interface Props {
  clickHandlerClose?: () => void;
}
function ModalEndSubscription({ clickHandlerClose }: Props) {
  const [load, setLoad] = useState(false);
  const { paymentMethod, defaultPaymentMethod, accountData } = useSelector(
    (state: store) => state.account
  );
  const [checked, setChecked] = useState([{ id: "", isDefault: false }]);
  useEffect(() => {
    setChecked(
      paymentMethod.map((item: any) => {
        return {
          id: item.id,
          isDefault: item.id === defaultPaymentMethod,
        };
      })
    );
  }, [paymentMethod, defaultPaymentMethod]);
  const { getUserCards } = useStripeHook();
  const { loadOrderData } = useAccount();
  useEffect(() => {
    loadOrderData(accountData.uid || "");
    getUserCards().then(() => {
      setLoad(true);
    });
  }, []);
  const renderPaymentItem = () => {
    return paymentMethod.map((item: any, index: number) => {
      return (
        <PaymentItem
          checkedStatus={item.id === checked.find((item) => item.isDefault)?.id}
          key={index}
          item={item}
        />
      );
    });
  };
  return (
    <Modal
      title={"Change Payment Method"}
      clickHandlerClose={clickHandlerClose}
    >
      <div className="payment_account">
        {load ? (
          renderPaymentItem()
        ) : (
          <div className={"loader_wrapper"}>
            <img className={"loader_icon"} src={SVG.LOADER_ICON} alt="loader" />
          </div>
        )}
      </div>
    </Modal>
  );
}

export default ModalEndSubscription;
