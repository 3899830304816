import React from "react";
import "./Button.scss";

interface Props {
  content: string | undefined;
  clickHandler?: (e?: any) => void;
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
  className?: string;
  name?: string;
}

function Button({
  content,
  clickHandler,
  disabled,
  type,
  className,
  name,
}: Props) {
  return (
    <button
      disabled={disabled}
      type={type}
      onClick={clickHandler}
      className={className ? className : "add-btn"}
      name={name}
    >
      {content}
    </button>
  );
}

export default Button;
