import React from 'react';
import Input from "../../_common/Input/Input";
import Button from "../../_common/Button/Button";
import useAccount from "../../../hooks/useAccount";
import {useSelector} from "react-redux";
import {Store} from "redux";
import {store} from "../../../types/store";

interface Props {
    editAddressHandler: () => void;
    data: any;
    handlerInput: any;
    uid: string;
}

function UpdateSecondAddress({data, uid, handlerInput, editAddressHandler}: Props) {
    const {sendAddress, getUserAddress} = useAccount()
    const {second_address_id} = useSelector((state: store) => state.account.accountData)
    const updateSecondAddressHandler = async () => {
        await sendAddress(uid, data, second_address_id, false)
        await getUserAddress(uid)
        editAddressHandler()
    }
    const itemSecondAddress = [
        {name: 'line1', value: data.line1, placeholder: 'Address line 1'},
        {name: 'line2', value: data.line2, placeholder: 'Address line 2'},
        {name: 'town', value: data.town, placeholder: 'Town'},
        {name: 'postcode', value: data.postcode, placeholder: 'Postcode'}]
    const renderItemOne = () => {
        return (
            itemSecondAddress.map((item, index) => {
                if (index <= 1) {
                    return (
                        <div key={index}>
                            <Input
                                type={'text'}
                                placeholder={item.placeholder}
                                name={item.name}
                                value={item.value}
                                onChange={
                                    (e) => {
                                        handlerInput(e.currentTarget.value, e.currentTarget.name)
                                    }}/>
                        </div>
                    )
                }
            }))
    }
    const renderItemTwo = () => {
        return (
            itemSecondAddress.map((item, index) => {
                if (index > 1) {
                    return (
                        <div key={index}>
                            <Input
                                type={'text'}
                                placeholder={item.placeholder}
                                name={item.name}
                                value={item.value}
                                onChange={
                                    (e) => {
                                        handlerInput(e.currentTarget.value, e.currentTarget.name)
                                    }}/>
                        </div>
                    )
                }
            }))
    }
    return (
        <>
            <div className={'name_input_wrapper'}>
                {renderItemOne()}
            </div>
            <div className={'name_input_wrapper_two'}>
                {renderItemTwo()}
            </div>
            <div className={'name_button_wrapper'}>
                <Button content={'Done'} clickHandler={updateSecondAddressHandler}
                        className={'primary_button_green'}/>
                <Button content={'Cancel'} clickHandler={editAddressHandler} className={'secondary_button_gray'}/>
            </div>
        </>
    );
}

export default UpdateSecondAddress;
