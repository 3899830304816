export const FAQItems = [
  {
    h2: "What are microbubbles?",
    p: [
      "Our supplements are delivered as microbubbles - tiny dissolvable pellets which hold the nutrients (vitamins and  minerals). The microbubbles move through your digestive system releasing the nutrients slowly, which maximises  absorption.",
      "All our supplement packs also contain omega-3, which is housed in an uncoloured soft gel capsule.",
    ],
  },
  {
    h2: "Why do you use microbubbles?",
    p: [
      "Microbubbles offer several advantages over a traditional solid ‘pill style’ supplement.",
      "The first is absorption. Some nutrients are absorbed most effectively when delivered together – for example, vitamin D is required for the absorption of calcium. Equally some nutrients aren't well absorbed when delivered  together – for example high intakes of calcium can prevent zinc from being absorbed.",
      "By separating nutrients into different bubbles and delivering those that help each other together we can maximise absorption.",
      "The second advantage is reducing interaction – vitamins and minerals can react if they are in close contact with  each other, which can lead to them breaking down. By separating them into separate bubbles the nutrients don’t  react, even when stored in the same pack.",
    ],
  },
  {
    h2: "How should I take my supplement?",
    p: [
      "We recommend taking your supplement in the morning, as the slow-release formula of the microbubbles means  the nutrients are released over a number of hours.",
      "Tear open your pack and tip a few of the microbubbles onto your tongue at a time, then swallow them with a cold  drink, but don’t chew. Alternatively, you can tip the microbubbles into a food or thicker liquid – for example in  yoghurt or smoothies. They won’t dissolve and the contents won’t be affected.",
      "The omega-3 capsules can be swallowed with a drink.",
    ],
  },
  {
    h2: "What are the microbubbles made from?",
    p: [
      "The microbubbles are composed of the vitamins and minerals contained in the supplement, plus some ingredients  to help stabilise them.",
      "We use cellulose (a type of fibre) to control how quickly the bubbles release their nutrients. Starch (made from  corn) is used to hold the microbeads together and beeswax is used to make the microbubbles flavourless.",
    ],
  },
  {
    h2: "I can see the capsules in my stools (poo) does this mean they haven’t been absorbed?",
    p: [
      "No. The shells of the microbubbles are not always completely broken down in the gut, as the shells contain a type  of fibre which may not be digested. This means you might see the shells in your poo – but the nutrients will have  been released as they move through the gut.",
    ],
  },
  {
    h2: "Are your supplements vegan?",
    p: [
      "Unfortunately, no, not yet. Although our omega-3 is sourced from algae and therefore vegan, the microbubbles  contain some beeswax in their coating, which is there to stabilise the nutrients. In addition, the vitamin D  contained in the microbubbles is derived from lanolin, which is extracted from sheep’s wool.",
      "We are looking for alternative sources for these ingredients, and we will update our supplements when we do.",
    ],
  },
  {
    h2: "What are the microbubbles like? Do they have a taste?",
    p: [
      "They look like tiny pellets, which are neutral colours. They are completely flavourless.",
    ],
  },
  {
    h2: "Do the microbubbles dissolve? Can I stir them into a drink, or add them to something like yoghurt?",
    p: [
      "The pellets do not dissolve but can be consumed with a drink or added to a yoghurt or cereal for example. We  usually recommended consuming them with a cold or warm food or drink like cereal or yoghurt rather than a hot food or drink (for example tea or hot chocolate).",
    ],
  },
  {
    h2: "How does the omega-3 come?",
    p: [
      "The omega-3 we use is sourced from algae (seaweed) and housed in an uncoloured soft gel capsule, which means  you can see the light-yellow colour of the omega oil. The capsule can be swallowed with a cold drink.",
    ],
  },
  {
    h2: "How long does it take for the supplements to arrive once they've ordered and do I have to sign for them, or can they fit through a letterbox?",
    p: [
      "The supplements should be with you within four days of being ordered as they are manufactured and shipped  from mainland Europe. Unfortunately they won’t fit through a letterbox. Someone will need to sign for them on  delivery.",
    ],
  },
  {
    h2: "Is there are risk for someone with allergies? ",
    p: [
      "While our products are made on a production line that is gluten-free and lactose-free we cannot ever guarantee  that they are completely free from these allergens. If you have a food allergy or are allergic to any of the  ingredients, then please consult a doctor before taking these supplements.",
    ],
  },
  {
    h2: "Are there any side effects which could happen as a result of taking the supplements?",
    p: [
      "We do not know of any side effects as a result of taking the supplements as directed. It’s important not to combine  with other supplements unless directed by your doctor, or unless you are taking a supplement with nutrients  which are not contained in your Bianu supplement.",
    ],
  },
  {
    h2: "Are the packets the granules / capsules come in recyclable?",
    p: [
      "At the moment no, but we are looking at creating sustainable options and will aim to introduce these as soon as  possible.",
    ],
  },
  {
    h2: "Can I chew the microbubbles?",
    p: [
      "Because they are hard, it’s best for them to be swallowed rather than chewed.",
    ],
  },
  {
    h2: "Are there any interactions with medications?",
    p: [
      "If you are taking medications and concerned about interactions with your Bianu supplement, we recommend  speaking with your doctor.",
    ],
  },
  {
    h2: "Can I take the Bianu supplements if I’m already taking other vitamin supplements?",
    p: [
      "Our supplements are designed to help you get all the nutrients you need during pregnancy when consumed  alongside a healthy balanced diet. For this reason, we recommend stopping other vitamin and mineral  supplements to avoid the risk of consuming more than the safe level of any nutrient.",
      "If you're taking a supplement that contains a nutrient not supplied in your Bianu supplement (for example you're  taking an iron supplement and your recommended Bianu supplement doesn't contain iron) you might choose to  continue, but if you're unsure, always seek the advice of your doctor or healthcare professional.",
    ],
  },
  {
    h2: "What should I do if I miss a day?",
    p: [
      "Just take the normal dose the next day and don’t worry about skipping one day. Our companion app is designed  to help remind you to take your supplement each day whilst you get into the habit.",
    ],
  },
  {
    h2: "What happens if I run out of supplements but I’m still pregnant?",
    p: [
      "We’ve calculated the subscriptions to cover you for the rest of your pregnancy from the point that you sign up, plus some overlap as babies have a tendency not to arrive when we plan them to! If you do run out of supplements  and you’re at the end of your plan then just drop us an email at support@bianu.co. We can sort it out for you!",
    ],
  },
];
