import React, {useEffect, useState} from "react";
import Button from "../../_common/Button/Button";
import imgOne from "../../../assets/static/homePageImages/updateIMG/Header Image 1_Scan QR code.png";
import imgSecond from "../../../assets/static/homePageImages/updateIMG/Header Image 3_Bianu Sachet.png";
import imgThree from "../../../assets/static/png/home_page_mob.png";
import imgFour from "../../../assets/static/png/hello_vallery_img.png";
import CheckBoxItems from "../../CheckBox/CheckBoxItems";

interface Props {
  clickBtnHandler: any;
  menuVisible: boolean;
  menuBtnHandler: () => void;
}

function SectionHead({clickBtnHandler}: Props) {
  return (
    <>
      <div className="header_content">
        <div className={"header_content_main_wrapper"}>
          <div className={'mobile_view_images'}>
            <img src={imgThree} alt="benefits"/>
            <div className={'mobile_view_images_group'}>
              <img src={imgSecond} alt="benefits"/>
              <img src={imgFour} alt="benefits"/>

            </div>
          </div>
          <div className="section_one_content_left">
            <h1 className={"title_section_one"}>
              Your best pregnancy with personalised supplements and a team of prenatal experts in your pocket
            </h1>
          <div className={'desktop_container'}>
            <p className={"description_section_one"}>
              Take a short quiz to personalise your supplements and wellbeing programme for you and your baby.

            </p>
            <p className={"description_section_one"}> If you have a promotional code, you can add this at the payment step at the end of the quiz.
            </p>
          </div>
            {/*<CheckBoxItems />*/}
            <Button
              content={"Start pregnancy quiz"}
              className={"primary_button_orange"}
              clickHandler={clickBtnHandler}
            />
          </div>
          <div className="section_one_content_right">
            <div className="section_one_content_right_top">
              <img style={{maxWidth: '244px'}} src={imgSecond}/>
              <img
                className={"section_one_content_right_bottom_1st_img"}
                src={imgThree}
              />
            </div>
            <div className="section_one_content_right_bottom">
              <img style={{maxHeight: '466px'}} src={imgOne}/>

              <img className={"mobile_img"} src={imgSecond}/>
              {/*<img src={imgFour} />*/}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionHead;
