import React from "react";
import Button from "../../_common/Button/Button";
import imgOne from "../../../assets/static/homePageImages/updateIMG/Component 1.png";
interface Props {
  clickBtnHandler: any;
}
function SectionThree({ clickBtnHandler }: Props) {
  return (
    <section id={"supplements_anchor"} className="supplements">
      <div className="supplements_content">
        <div className="supplements_content-info">
          <h2 className="supplements_content-title">
            Supplements tailored to your dietary and pregnancy needs
          </h2>
          <div className="supplements_content-text">
            Our prenatal experts recommend the perfect supplement type and
            amount of micronutrients based on your dietary needs, your trimester
            and your pregnancy symptoms. Flavourless micro bubbles provide you
            with a convenient way to get your daily recommended dose in an easy
            to swallow format.
          </div>
          <div className={"section_three_button_wrapper"}>
            <Button
              content={"Start pregnancy quiz"}
              clickHandler={clickBtnHandler}
              className={"primary_button_orange"}
            />
          </div>
        </div>
        <img className="supplements_content-img" src={imgOne} alt="" />
      </div>
    </section>
  );
}

export default SectionThree;
