import React, {useCallback, useEffect, useState} from "react";
import "./Quiz.scss";
import {Link, useHistory} from "react-router-dom";
import QuizForm from "../../components/QuizForm/QuizForm";
import ProgressBar from "../../components/_common/ProgressBar/ProgressBar";
import {Svg} from "../../components/_common/Svg/Svg";
import {SVG} from "../../constants/svg";
import {Routes} from "../../configs/routes/routes";
import {useDispatch, useSelector} from "react-redux";
import {actionSetQuizNavCurrent} from "../../store/actions/quiz-actions";
import {UtilsCommon} from "../../utils/UtilsCommon";
import useAccount from "../../hooks/useAccount";
import {RootState} from "../../store";
import {onAuthStateChanged} from "firebase/auth";
import { getAuth, signInAnonymously } from "firebase/auth";
import ReactPixel from "react-facebook-pixel";
import Banner from "../../components/Banner/Banner";
import useAuth from "../../hooks/useAuth";
import {store} from "../../types/store";

function Quiz() {
  const history = useHistory();
  const [bar, setBar] = useState({
    currentWidth: 1,
    currentStep: 1,
  });
const {anonymousAuth} = useAuth()
  const nav = useSelector((state: any) => state.nav);

  const dispatch = useDispatch();
  useEffect(() => {
    setStepData(UtilsCommon.getCurrentStepData(nav.currentStepId));
    // changeBar();
  }, [nav.currentStepId]);
  const [stepData, setStepData] = useState(
    UtilsCommon.getCurrentStepData(nav.currentStepId)
  );

  useEffect(() => {
    anonymousAuth()
    if (stepData)
      setBar({
        currentStep: stepData.displayStepNumber,
        currentWidth: (stepData.displayStepNumber / 6) * 30,
      });
  }, [stepData]);
  return (
    <>
      <Banner/>
      <div className="quiz">
        <div className="header-quiz">
          {/*{nav.currentStepId !== "stp0003" && (*/}
            <div
              className={"login_header_button_back"}
              onClick={() => {
                if (nav.prevStepId) {
                  //@ts-ignore
                  window.cqet("send", "CustomEvent", {
                    eventName: `Quiz step ${bar.currentStep} abandoned`,
                  });
                  ReactPixel.trackCustom(`Quiz step ${bar.currentStep} abandoned`)
                  dispatch(actionSetQuizNavCurrent(nav.prevStepId));
                } else {
                  //@ts-ignore
                  window.cqet("send", "CustomEvent", {
                    eventName: `Quiz step ${bar.currentStep} abandoned`,
                  });
                  ReactPixel.trackCustom(`Quiz step ${bar.currentStep} abandoned`)
                  history.push(Routes.HOME);
                }
              }}
            >
              Back
            </div>
          {/*// }*/}
          <span className={"logo"} onClick={() => history.push(Routes.HOME)}>
          <Svg icon={SVG.LOGO}/>
        </span>
        </div>
        <div className="main">
          <QuizForm/>
        </div>
        <footer>
          <div className="square-bottom-left">
            <div className="full-circle-left"/>
            <div className="circle-left"/>
          </div>
          <ProgressBar
            currentStep={bar.currentStep}
            totalSteps={6}
            barWidth={bar.currentWidth}
          />
          <div className="square-bottom-right">
            <div className="full-circle-right"/>
            <div className="circle-right"/>
          </div>
        </footer>
      </div>
    </>
  );
}

export default Quiz;
