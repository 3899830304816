import React from "react";
import { Routes } from "../../configs/routes/routes";

const PrivacyPolicyText = () => {
  return (
    <div className={"terms_content_text"}>
      <p className={"terms_content_paragraph"}>Welcome to our Privacy Policy</p>
      <p className={"terms_content_paragraph"}>
        Your privacy is critically important to us. Bianu is brought to you by
        L3M Technologies (t/a Salus Optima)
      </p>
      <p className={"terms_content_paragraph"}>
        Registered office: L3M Technologies (t/a Salus Optima), C/O Pkf
        Littlejohn, 15 Westferry Circus, Canary Wharf, London, United Kingdom,
        E14 4HD (hereby referred to as <b>'Salus Optima'</b>). Registered in
        England No. 11157173.
      </p>
      <ol className={"terms_list"}>
        <li>
          <h2 className={"terms_list_header"}>OUR APPROACH</h2>
          <p className={"terms_content_paragraph_margin"}>
            This Privacy Policy sets out how we, <b>Salus Optima</b> (
            <b>“we”</b>, <b>“us”</b> or <b>“our”</b>), process personal
            information about you when you sign up to and use any part of the
            Bianu ecosystem (including our Biainsider programme) which is made
            up of, but not necessarily limited to, the digital application (the{" "}
            <b>“App”</b>), the website (the <b>‘Web’</b>), and the physical
            supplement product and associated questionnaire to personalise it
            (the <b>‘Supplement’</b>),whether as a guest or registered user.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            We are a data controller of the personal information we process and
            are therefore responsible for ensuring our systems, processes,
            suppliers and staff comply with data protection laws in relation to
            the information we handle. If you do not agree with this Privacy
            Policy, you should not submit information to us.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            You can find out more about our responsibilities and about how and
            why we collect and use your personal information by reading this
            Privacy Policy. However, if anything is unclear or if you have any
            questions about this Privacy Policy, please contact us at
            support@bianu.co.
          </p>
        </li>
        <li>
          <h2 className={"terms_list_header"}>
            THE INFORMATION WE COLLECT AND HOW WE COLLECT IT
          </h2>
          <p className={"terms_content_paragraph_margin"}>
            Personal data, or personal information, means any information about
            a person from which they can be identified. We may collect, store,
            and use some or all of the following categories of personal
            information about you:
          </p>
          <p className={"terms_content_paragraph_margin"}>
            <b>
              <em>Identity Information</em>
            </b>
            <ul className={"terms_list_inner"}>
              <li className={"terms_content_paragraph"}>
                personal contact details such as name, title, gender, addresses,
                telephone numbers, and personal email addresses;
              </li>
              <li className={"terms_content_paragraph"}>
                date of birth and place of birth;
              </li>
              <li className={"terms_content_paragraph"}>
                next of kin and emergency contact information;
              </li>
              <li className={"terms_content_paragraph"}>start date;</li>
              <li className={"terms_content_paragraph"}>
                physical measurements, training statistics and dietary
                information;
              </li>
              <li className={"terms_content_paragraph"}>photographs; and/or</li>
              <li className={"terms_content_paragraph"}>
                information about your use of our information and communications
                systems (such as messages to personal trainers, goals and
                notes).
              </li>
            </ul>
          </p>
          <p className={"terms_content_paragraph_margin"}>
            Subject to your explicit consent, we may also collect, store and use
            your health data. This is considered a special category of more
            sensitive data. Where we process health data, it will be treated
            confidentially and will never be used for direct marketing purposes.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            <b>
              <em>System Information</em>
            </b>
          </p>
          <p className={"terms_content_paragraph_margin"}>
            When you log on to our <b>App</b> or <b>Web</b>, we automatically
            collect information about your use of the <b>App</b> or <b>Web</b>{" "}
            including details of your visits such as pages viewed and the
            resources that you access. This information will include traffic
            data, location data, IP address, browser, operating system, referral
            source, length of visit, clickstream data and other communication
            data.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            <b>
              <em>Identity Information</em>
            </b>
          </p>
          <p className={"terms_content_paragraph_margin"}>
            We collect Identity Information provided voluntarily by you. For
            example, when you register with or use our <b>App</b> or <b>Web</b>{" "}
            (by entering training plan details, goals, ordering a{" "}
            <b>Supplement</b> or making a booking).
          </p>
          <p className={"terms_content_paragraph_margin"}>
            We also collect Identity Information when you fill out a web form or
            questionnaire or contact us (by email or telephone) to ask a
            question or request information.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            We also collect Identity Information through the Apple Health app (+
            Google Fit app?) where you give us explicit consent to do so
            (detailed further below).
          </p>
          <p className={"terms_content_paragraph_margin"}>
            <b>
              <em>System Information</em>
            </b>
          </p>
          <p className={"terms_content_paragraph_margin"}>
            As mentioned above, we collect System Information when you interact
            with our <b>App</b> or <b>Web</b>.
          </p>
        </li>
        <li>
          <h2 className={"terms_list_header"}>HEALTH DATA</h2>
          <p className={"terms_content_paragraph_margin"}>
            In order to provide our services to you, we may be required to
            process special category data, for example your health information.
            Where we process this special category data, data protection law
            requires that we satisfy certain additional conditions. We will only
            process special category data with your explicit consent to the
            processing.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            In particular, we may ask you to grant us access to the Health app
            on your Apple device or the Google Fit app on your Android device
            and to share with us the health data which is collected, processed
            and stored as part of the Health or Fit app. You decide what
            information goes into the Health or Fit app and what information we
            can get from it. By allowing us access to that health data you are
            granting us explicit consent to process it in accordance with the
            terms of this Privacy Policy.
          </p>
        </li>
        <li>
          <h2 className={"terms_list_header"}>
            HOW WE WILL USE YOUR PERSONAL DATA
          </h2>
          <p className={"terms_content_paragraph_margin"}>
            <b>
              <em>Providing our services</em>
            </b>
          </p>
          <p className={"terms_content_paragraph_margin"}>
            As part of the provision of our services, we may use the personal
            information that we collect from you to carry out actions such as
            the examples below:
            <ul className={"terms_list_inner"}>
              <li className={"terms_content_paragraph_margin"}>
                register you as a user of the <b>App</b> or <b>Web</b>;
              </li>
              <li className={"terms_content_paragraph_margin"}>
                process your <b>Supplement</b> orders, take payments and provide
                your details to our personal trainer coaches
              </li>
              <li className={"terms_content_paragraph_margin"}>
                Share with our partners DSM and Novogenia to fulfil your{" "}
                <b>Supplement</b> orders
              </li>
              <li className={"terms_content_paragraph_margin"}>
                share with our trainers and partners who are helping us improve
                and build the programmes offered via the <b>App</b>;
              </li>
              <li className={"terms_content_paragraph_margin"}>
                provide limited health monitoring, live group fitness streaming,
                virtual fitness streaming and workout recordings;
              </li>
              <li className={"terms_content_paragraph_margin"}>
                notify you of renewal reminders and/or payment details;
              </li>
              <li className={"terms_content_paragraph_margin"}>
                provide you with our helpline for support and enquiries relating
                to your use of the <b>App</b> or <b>Web</b> and{" "}
                <b>Supplement</b> orders placed by you; and/or
              </li>
              <li className={"terms_content_paragraph_margin"}>
                manage our relationship with you (for example by notifying you
                about changes to our terms or asking you to complete a survey).
              </li>
            </ul>
          </p>
          <p className={"terms_content_paragraph_margin"}>
            <b>
              <em>Monitoring, administering and improving</em>
            </b>
          </p>
          <p className={"terms_content_paragraph_margin"}>
            We use your personal information to help us to monitor our
            performance, administer and improve our <b>App</b> and <b>Web</b>{" "}
            by, for example:
            <ul className={"terms_list_inner"}>
              <li className={"terms_content_paragraph_margin"}>
                tracking and analysing activity to identify patterns and help us
                improve our <b>App</b>, <b>Web</b> and <b>Supplement</b>{" "}
                ordering process;
              </li>
              <li className={"terms_content_paragraph_margin"}>
                troubleshooting, conducting data analysis, testing, system
                maintenance, support, reporting and hosting of data;
              </li>
              <li className={"terms_content_paragraph_margin"}>
                using data analytics to improve customer relationships and
                experiences;
              </li>
              <li className={"terms_content_paragraph_margin"}>
                analysing information so that we can prioritise features that
                are relevant and popular;
              </li>
              <li className={"terms_content_paragraph_margin"}>
                educating, training and developing our staff’s performance;
              </li>
              <li className={"terms_content_paragraph_margin"}>
                ensuring network and information security, including preventing
                unauthorised access to our computer and electronic
                communications systems and preventing malicious software
                distribution;
              </li>
              <li className={"terms_content_paragraph_margin"}>
                preventing fraud; and/or
              </li>
              <li className={"terms_content_paragraph_margin"}>
                other business administration such as management and planning,
                including accounting and auditing.
              </li>
            </ul>
          </p>
        </li>
        <li>
          <h2 className={"terms_list_header"}>GROUNDS FOR PROCESSING</h2>
          <p className={"terms_content_paragraph_margin"}>
            Data protection law says we only have the right to use your personal
            information where we can identify a lawful basis for doing so. Your
            consent to the processing as specified in this Privacy Policy is our
            primary lawful basis. In some circumstance we may also rely on
            another lawful basis. Most commonly, these will be:
            <ul className={"terms_list_inner"}>
              <li className={"terms_content_paragraph_margin"}>
                where we need to use the information to perform the contract we
                have entered into with you;
              </li>
              <li className={"terms_content_paragraph_margin"}>
                where it is necessary for our legitimate interests (or those of
                a third party) and your interests and fundamental rights do not
                override those interests; and/or
              </li>
              <li className={"terms_content_paragraph_margin"}>
                where we need to comply with a legal or regulatory obligation.
              </li>
            </ul>
          </p>
        </li>
        <li>
          <h2 className={"terms_list_header"}>INFORMATION SECURITY</h2>
          <p className={"terms_content_paragraph_margin"}>
            The Internet is not a secure medium. However, we have put in place
            various security procedures as set out in this Privacy Policy.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            Please be aware that communications over the Internet, such as
            emails and online messages are not secure unless they have been
            encrypted. Your communications may route through a number of
            countries before being delivered – this is the nature of the
            Internet. We cannot accept responsibility for any unauthorised
            access or loss of personal data that is beyond our control.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            We believe that we have appropriate policies, rules and technical
            measures to protect the personal data that we have under our control
            (having regard to the type and amount of that personal data) from
            unauthorised access, improper use or disclosure, unauthorised
            modification, unlawful destruction or accidental loss.
          </p>
        </li>
        <li>
          <h2 className={"terms_list_header"}>
            DISCLOSURE OF YOUR PERSONAL INFORMATION
          </h2>
          <p className={"terms_content_paragraph_margin"}>
            We will not share your personal information with or to third
            parties, except as otherwise provided for in this privacy policy and
            under the following limited circumstances when we want to or are
            compelled to share your personal information, including:
            <ul className={"terms_list_inner"}>
              <li className={"terms_content_paragraph_margin"}>
                with our carefully selected trainers and partners who help us
                improve and build the programmes offered via the <b>App</b> and{" "}
                <b>Web</b>;
              </li>
              <li className={"terms_content_paragraph_margin"}>
                with third party service providers or suppliers to enable us to
                provide our services (for example payment processors, webhosts,
                ID verification partners etc). Where we share data with service
                providers, we require them to sign a contract that obliges them
                amongst other things to have stringent security measures in
                place, comply with our instructions and help us to comply with
                data protection law;
              </li>
              <li className={"terms_content_paragraph_margin"}>
                to another legal entity on a temporary or permanent basis, in
                connection with a business deal, such as a merger, financing,
                acquisition, or sale of our business;
              </li>
              <li className={"terms_content_paragraph_margin"}>
                Novogenia...DSM... etc. to.....
              </li>
              <li className={"terms_content_paragraph_margin"}>
                where we are required to do so by law; and/or
              </li>
              <li className={"terms_content_paragraph_margin"}>
                where you have provided your consent.
              </li>
            </ul>
          </p>
        </li>
        <li>
          <h2 className={"terms_list_header"}>
            INTERNATIONAL TRANSFER OF PERSONAL INFORMATION
          </h2>
          <p className={"terms_content_paragraph_margin"}>
            We may transfer the personal information we collect about you
            outside the EU in order to perform our contract with you. Where this
            occurs we will ensure that your personal information receives an
            adequate level of protection and we will put in place appropriate
            measures to ensure that your personal information is treated in a
            way that is consistent with EU and UK laws on data protection. If
            you require further information about these protective measures, you
            can request it by contacting us at support@bianu.com.
          </p>
        </li>
        <li>
          <h2 className={"terms_list_header"}>SECURITY AND RETENTION</h2>
          <p className={"terms_content_paragraph_margin"}>
            We have put in place appropriate security measures to prevent your
            personal information from being accidentally lost, used or accessed
            in an unauthorised way, altered or disclosed. We also have
            procedures to deal with any suspected data security breach and will
            notify you and any applicable regulator of a suspected breach where
            we are legally required to do so.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            We will only retain your personal information for as long as
            necessary to fulfil the purposes we collected it for, including for
            the purposes of satisfying any legal, accounting, or reporting
            requirements.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            To determine the appropriate retention period for personal
            information, we consider the amount, nature, and sensitivity of the
            information, the potential risk of harm from unauthorised use or
            disclosure of your information, the purposes for which we process it
            and whether we can achieve those purposes through other means, and
            the applicable legal requirements.
          </p>
        </li>
        <li>
          <h2 className={"terms_list_header"}>
            YOUR RIGHTS IN RELATION TO YOUR PERSONAL INFORMATION
          </h2>
          <p className={"terms_content_paragraph_margin"}>
            Under certain circumstances, by law you have the right to:
            <ul className={"terms_list_inner"}>
              <li className={"terms_content_paragraph_margin"}>
                <b>Request access</b> to your personal information (commonly
                known as a “data subject access request”). This enables you to
                receive a copy of the personal information we hold about you and
                to check that we are lawfully processing it;
              </li>
              <li className={"terms_content_paragraph_margin"}>
                <b>Request correction</b> of the personal information that we
                hold about you. This enables you to have any incomplete or
                inaccurate information we hold about you corrected;
              </li>
              <li className={"terms_content_paragraph_margin"}>
                <b>Request erasure</b> of your personal information. This
                enables you to ask us to delete or remove personal information
                where there is no good reason for us continuing to process it;
              </li>
              <li className={"terms_content_paragraph_margin"}>
                <b>Object to processing</b> of your personal information where
                we are relying on a legitimate interest (or those of a third
                party) and there is something about your particular situation
                which makes you want to object to processing on this ground;
              </li>
              <li className={"terms_content_paragraph_margin"}>
                <b>Request the restriction of processing</b> of your personal
                information. This enables you to ask us to suspend the
                processing of personal information about you, for example if you
                want us to establish its accuracy or the reason for processing
                it; and
              </li>
              <li className={"terms_content_paragraph_margin"}>
                <b>Request the transfer</b> of your personal information to
                another party.
              </li>
            </ul>
          </p>
          <p className={"terms_content_paragraph_margin"}>
            You will not have to pay a fee to access your personal information
            (or to exercise any of the other rights). However, we may charge a
            reasonable fee if your request for access is clearly unfounded or
            excessive. Alternatively, we may refuse to comply with the request
            in such circumstances.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            We may need to request specific information from you to help us
            confirm your identity and ensure your right to access the
            information (or to exercise any of your other rights). This is
            another appropriate security measure to ensure that personal
            information is not disclosed to any person who has no right to
            receive it.
          </p>
        </li>
        <li>
          <h2 className={"terms_list_header"}>
            LINKED WEBSITES OR APPLICATIONS
          </h2>
          <p className={"terms_content_paragraph_margin"}>
            We are not responsible for the privacy policies and practices of
            other websites even if you accessed the third party website using
            links from our website. We recommend that you check the policy of
            each website you visit before deciding whether to proceed and
            contact the owner or operator of such website if you have concerns
            or questions.
          </p>
        </li>
        <li>
          <h2 className={"terms_list_header"}>
            CHANGES TO THIS PRIVACY POLICY
          </h2>
          <p className={"terms_content_paragraph_margin"}>
            We reserve the right to update this Privacy Policy at any time, and
            we will provide you with a new Privacy Policy when we make any
            substantial updates. We may also notify you in other ways from time
            to time about the processing of your personal information.
          </p>
        </li>
        <li>
          <h2 className={"terms_list_header"}>
            JURISDICTION AND APPLICABLE LAW
          </h2>
          <p className={"terms_content_paragraph_margin"}>
            English law governs this Privacy Policy, its subject matter and its
            formation. The courts of England and Wales will have exclusive
            jurisdiction except that if you are a resident of Northern Ireland
            you may also bring proceedings in Northern Ireland, and if you are
            resident of Scotland, you may also bring proceedings in Scotland.
          </p>
        </li>
        <li>
          <h2 className={"terms_list_header"}>OTHER IMPORTANT TERMS</h2>
          <p className={"terms_content_paragraph_margin"}>
            This Privacy Policy (together with our{" "}
            <a href={Routes.TERMS}>Terms of Service</a>) constitute the whole
            agreement between us and supersede all previous discussions,
            correspondence, negotiations, previous arrangement, understanding or
            agreement between us relating to the provision of online
            consultations and the purchase of <b>Supplements</b> via our{" "}
            <b>App</b> and <b>Web</b>.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            If any of the terms of this Privacy Policy are found by any
            competent authority to be invalid, unlawful or unenforceable to any
            extent, such term, condition or provision will to that extent be
            severed from the remaining terms, conditions and provisions which
            will continue to be valid to the fullest extent permitted by law.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            If we delay in taking steps against you when you break this
            contract, that will not mean that you do not have to do what we ask
            in order to remedy your breach and it will not prevent us taking
            steps against you at a later date.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            If you have any concerns about this Privacy Policy, please contact:
            support@bianu.com.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            <b>Last updated:</b> 22nd September 2021
          </p>
        </li>
      </ol>
      <hr />
      <h2 className={"terms_list_header"}>COOKIES STATEMENT</h2>
      <p className={"terms_content_paragraph"}>
        Our App uses cookies to distinguish you from other users of our{" "}
        <b>App</b> and <b>Web</b>. This helps us to provide you with a good
        experience when you browse our <b>App</b> and <b>Web</b> and also allows
        us to improve our <b>App</b> and <b>Web</b>.
      </p>
      <p className={"terms_content_paragraph"}>
        A cookie is a small file of letters and numbers that we store on your
        browser or the hard drive of your computer or device if you agree.
        Cookies contain information that is transferred to your computer or
        device's hard drive.
      </p>
      <p className={"terms_content_paragraph"}>
        We use the following cookies:
        <ul className={"terms_list_inner"}>
          <li className={"terms_content_paragraph"}>
            <b>Strictly necessary cookies.</b> These are cookies that are
            required for the operation of our <b>App</b> and <b>Web</b>. They
            include, for example, cookies that enable you to log into secure
            areas of our <b>App</b> and <b>Web</b>, use a shopping cart or make
            use of e-billing services.
          </li>
          <li className={"terms_content_paragraph"}>
            <b>Analytical or performance cookies.</b> These allow us to
            recognise and count the number of visitors and to see how visitors
            move around our <b>App</b> and <b>Web</b> when they are using it.
            This helps us to improve the way our <b>App</b> and <b>Web</b>{" "}
            works, for example, by ensuring that users are finding what they are
            looking for easily. For more information on the Google Analytics
            tools we use and Google’s Privacy Policy click here{" "}
            <a href="https://www.google.com/intl/en- GB/policies/privacy/">
              https://www.google.com/intl/en- GB/policies/privacy/
            </a>
            .
          </li>
          <li className={"terms_content_paragraph"}>
            <b>Functionality cookies.</b> These are used to recognise you when
            you return to our <b>App</b> or <b>Web</b>. This enables us to
            personalise our content for you, greet you by name and remember your
            preferences (for example, your choice of language or region).
          </li>
          <li className={"terms_content_paragraph"}>
            <b>Targeting cookies.</b> These cookies record your visit to our{" "}
            <b>App</b> or <b>Web</b>, the pages you have visited and the links
            you have followed. We will use this information to make our{" "}
            <b>App</b> and <b>Web</b>, and the advertising displayed on them
            more relevant to your interests. We may also share this information
            with third parties for this purpose, in accordance with our{" "}
            <a href={Routes.PRIVACY_POLICY}>Privacy Policy</a>.
          </li>
        </ul>
      </p>
      <p className={"terms_content_paragraph"}>
        You can block cookies by activating the setting on your browser that
        allows you to refuse the setting of all or some cookies. However, if you
        use your browser settings to block all cookies (including essential
        cookies) you may not be able to access all or parts of our <b>App</b>{" "}
        and <b>Web</b>.
      </p>
      <p className={"terms_content_paragraph"}>
        If you would like further guidance on cookies please click here{" "}
        <a href={"https://www.aboutcookies.org/"}>
          https://www.aboutcookies.org/
        </a>
      </p>
    </div>
  );
};

export default PrivacyPolicyText;
