import React, { SetStateAction, useCallback } from "react";
import Button from "../../_common/Button/Button";
import { LocalStorage } from "../../../services/storage";
import { CHECKPOINT } from "../../../constants/namespaces";
import { confirmStep } from "../../../types/quizTypes";
import "./ConfirmForm.scss";
import { useDispatch } from "react-redux";
import { actionOpenModal } from "../../../store/actions/quiz-actions";
import { string } from "prop-types";

interface Props {
  stepData: confirmStep;
  navFunc: () => void;
  dueDate: Date;
}

function ConfirmForm(props: Props) {
  const { stepData, navFunc, dueDate } = props;
  const dispatch = useDispatch();
  const openModal = useCallback(
    (option: string) => {
      if (stepData && stepData.modal) {
        dispatch(
          actionOpenModal({
            header: stepData.modal[option].header,
            open: true,
            content: stepData.modal[option].text,
            canBeClosed: true,
          })
        );
      }
    },
    [dispatch, stepData]
  );
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (stepData.isCheckpoint) LocalStorage.set(CHECKPOINT, stepData.id);
        navFunc();
      }}
      className={"form-wrapper confirm-form"}
    >
      {stepData.subType === "reviewterms" ? (
        <div className="review-terms-wrapper">
          <h1>{stepData.header}</h1>
          <p>{stepData.textParagraph}</p>
          <div className="bottom-wrapper">
            <p>Click below to review our</p>
            <span
              className="review-terms-link"
              onClick={() => openModal("tac")}
            >
              Terms & Conditions
            </span>{" "}
            and{" "}
            <span
              className="review-terms-link"
              onClick={() => openModal("privacy")}
            >
              Privacy Policy
            </span>
          </div>
        </div>
      ) : (
        <>
          <h1>
            {stepData.header}
            {dueDate.toLocaleDateString()}
          </h1>
          <img
            className={"form-image"}
            src={stepData.img}
            alt="future mother"
          />
        </>
      )}
      <Button
        type={"submit"}
        className={"primary_button_green"}
        content={stepData.btnText}
      />
    </form>
  );
}

export default ConfirmForm;
