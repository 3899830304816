import React, { useState } from "react";
import Input from "../../components/_common/Input/Input";
import Button from "../../components/_common/Button/Button";
import LogInWrapper from "../../components/logIn/LogInWrapper";
import { useHistory } from "react-router-dom";
import { Routes } from "../../configs/routes/routes";
import useAccount from "../../hooks/useAccount";

function RegistrationEmail() {
  const [email, setEmail] = useState("");
  const { resetPassword } = useAccount();
  const history = useHistory();
  const authHandler = async () => {
    await resetPassword(email);
    history.push(Routes.REGISTRATION_INBOX);
  };
  return (
    <LogInWrapper>
      <h1>Enter your email</h1>
      <p>
        Enter email associated with your account and we’ll send you
        instructions.
      </p>
      <form onSubmit={authHandler}>
        <Input
          value={email}
          type={"email"}
          onChange={(e) => {
            setEmail(e.currentTarget.value);
          }}
          placeholder={"Email"}
        />
        <Button
          content={"Send reset link"}
          type={"submit"}
          className={"primary_button_green"}
        />
      </form>
    </LogInWrapper>
  );
}
export default RegistrationEmail;
