import React from "react";
import "./FooterMain.scss";
import logo from "../../../assets/static/svg/newBianuLogo.svg";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../configs/routes/routes";
interface Props {
  linksShown?: boolean;
}
function FooterMain({ linksShown }: Props) {
  const history = useHistory();
  return (
    <div className="footer_main">
      <div className="container">
        <div className={"footer_main_left"}>
          <img
            onClick={() => {
              history.push(Routes.HOME);
            }}
            src={logo}
            alt={'main logo'}
          />
        </div>
        <div className={'footer_center'}>
          <p onClick={()=>history.push(Routes.TERMS)}>Terms & Conditions</p>
          <p onClick={()=>history.push(Routes.PRIVACY_POLICY)}>Privacy Policy</p>
          {linksShown && (
              <>
                <p onClick={() => history.push(Routes.CONNECT)}>
                  Connect with us
                </p>
                <p
                    onClick={() => {
                      history.push(Routes.FAQ);
                    }}
                    className={"footer_main_FAQ"}
                >
                  FAQ
                </p>
              </>
          )}
        </div>
        <div className={"footer_main_right"}>
          <div>© 2021 Salus Optima Technologies Limited</div>
        </div>
      </div>
    </div>
  );
}

export default FooterMain;
