import React, { ReactNode, useState } from "react";
import { accountMenu } from "../../constants/constants";

interface Props {
  clickHandler: (value: string) => void;
  children: ReactNode;
}

function AccountMenu({ children, clickHandler }: Props) {
  const [activeItem, setActiveItem] = useState(accountMenu.PERSONAL);

  const menu = Object.values(accountMenu);
  const activeMenuHandler = (value: string) => {
    setActiveItem(value);
    clickHandler(value);
  };

  const renderMenu = () => {
    return menu.map((item, index) => {
      const itemStyle = { color: activeItem === item ? "#006c61" : "" };
      return (
        <li
          key={index}
          onClick={(e) => {
            activeMenuHandler(e.currentTarget.textContent || "");
          }}
          style={itemStyle}
          className={activeItem === item ? "active_menu" : ""}
        >
          {item}
        </li>
      );
    });
  };
  return (
    <div className={"account_body"}>
      <div className={"account_body_left"}>
        <ul className={"account_body_left_side_menu"}>{renderMenu()}</ul>
      </div>
      <div className={"account_body_right"}>{children}</div>
    </div>
  );
}
export default AccountMenu;
