import React, { useState } from "react";
import Input from "../../_common/Input/Input";
import Button from "../../_common/Button/Button";
import { Link } from "react-router-dom";
import "./RegistrationForm.scss";
import { loginPasswordStep } from "../../../types/quizTypes";
import Loader from "../../_common/Loader/Loader";
import { useFormik } from "formik";
import { quizValidation } from "../../../constants/validationSchemas";

interface Props {
  stepData: loginPasswordStep;
  submitHandler: (email: string, password: string) => Promise<true | undefined>;
  formDisabled: boolean;
  name: string;
}

function RegistrationForm(props: Props) {
  const { stepData, submitHandler, formDisabled, name } = props;
  // const [values, setValues] = useState({ email: "", password: "" });
  const [load, setLoad] = useState(false);
  // const buttonIsDisabled = !values.password || !values.email;
  const formik: any = useFormik({
    initialValues: { email: "", password: "" },
    onSubmit: async () => {
      setLoad(true);
      const res = await submitHandler(
        formik.values.email,
        formik.values.password
      );
      setLoad(false);
      // if (!res) setValues({ email: "", password: "" });
    },
    validationSchema: quizValidation[stepData.id],
  });
  return (
    <form
      className={"form-wrapper registration-form"}
      // onSubmit={async (e) => {
      //   e.preventDefault();
      //   setLoad(true);
      //   const res = await submitHandler(values.email, values.password);
      //   setLoad(false);
      //   if (!res) setValues({ email: "", password: "" });
      // }}
      onSubmit={formik.handleSubmit}
    >
      <h1>Thanks {name}</h1>
      <h1>{stepData.header}</h1>
      <span>{stepData.subHeader}</span>
      <fieldset disabled={formDisabled}>
        <Input
          value={formik.values.email}
          // onChange={(e) =>
          //   setValues({
          //     ...values,
          //     email: e.target.value,
          //   })
          // }
          name="email"
          onChange={formik.handleChange}
          // required
          placeholder={stepData.loginPlaceholder}
          type={"email"}
          className={
            formik.errors.email && formik.touched.email && "custom-input_error"
          }
          onBlur={formik.handleBlur}
        />
        <Input
          value={formik.values.password}
          // onChange={(e) =>
          //   setValues({
          //     ...values,
          //     password: e.target.value,
          //   })
          // }
          name="password"
          className={
            formik.errors.password &&
            formik.touched.password &&
            "custom-input_error"
          }
          onChange={formik.handleChange}
          placeholder={stepData.pwdPlaceholder}
          type={"password"}
          onBlur={formik.handleBlur}
          // required
          // min={8}
        />
      </fieldset>
      {load ? (
        <div className={"registration_loader_wrapper"}>
          <Loader />
        </div>
      ) : (
        <Button
          // disabled={buttonIsDisabled}
          className={"primary_button_green"}
          content={stepData.btnText}
          type={"submit"}
        />
      )}
    </form>
  );
}

export default RegistrationForm;
