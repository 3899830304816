import React from "react";
import './RegisterForm.scss'
import '../Terms/Terms.scss'
import {Field, Form, Formik, FormikErrors, FormikTouched, FormikValues} from "formik";
import Input from "../_common/Input/Input";
import {registerForm} from "../../utils/UtilsCommon";
import {TermsData} from "../../types/checkout";
import TermsModal from "../TermsModal/TermsModal";
import PrivacyPolicyText from "../PrivacyPolicy/PrivacyPolicyText";
import Terms from "../Terms/Terms";
import TermsText from "../Terms/TermsText";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";

interface Props {
  values: FormikValues;
  handleChange: any;
  errors: FormikErrors<any>;
  touched: FormikTouched<any>;
  setTerms: (prev: TermsData) => void;
  terms: TermsData;
}

function RegisterForm({values, handleChange, errors, setTerms, terms, touched}: Props) {
  return (
    <div>
      {terms.show && <TermsModal setTerms={setTerms} terms={terms}
                                 component={terms.currentContent === 'privacy' ? <PrivacyPolicy disableHeader={true}/> : <Terms disableHeader={true}/>}/>}
      <h1 className="register-form-header">Create an account</h1>
      <p className={'register-form-info'}>We’ll use these details to save your pregnancy plan and subscription
        details.</p>
      <Form className={'register-main-form'}>
        <div className={'form-container'}>
          <h1 className={'form-container_name'}>Your name</h1>
          <Field name={'firstName'} required={true} onChange={handleChange('firstName')} value={values.firstName} type={'text'}
                 className={
                   touched.firstName && errors.firstName
                     ? "custom-input custom-input_error"
                     : "custom-input  register-form-input"
                 }
                 placeholder={'Enter your first name'}/>
          <Field name={'lastName'} required={true} onChange={handleChange('lastName')} value={values.lastName} type={'text'}
                 className={
                   touched.lastName && errors.lastName
                     ? "custom-input custom-input_error"
                     : "custom-input  register-form-input"
                 }
                 placeholder={'Enter your last name'}/>
        </div>
        <div className={'form-container'}>
          <h1 className={'form-container_details'}>Your account details</h1>
          <Field name={'email'} onChange={handleChange('email')} value={values.email} type={'text'}
                 className={
                    touched.email && errors.email
                     ? "custom-input custom-input_error"
                     : "custom-input  register-form-input"
                 }
                 placeholder={'Enter your email address'}/>
          <Field name={'password'} onChange={handleChange('password')} value={values.password} type={'password'}
                 className={
                   touched.password && errors.password
                     ? "custom-input custom-input_error"
                     : "custom-input  register-form-input"
                 }
                 placeholder={'Enter your password'}/>
          <div className={"terms_info"}>
            <div className="round_monthly">
              <input
                id={"terms"}
                onChange={(e) => setTerms({...terms, accepted: !terms.accepted})}
                type="checkbox"
              />
              <label htmlFor={"terms"}/>
            </div>
            <p className={"terms_info_text"}>
              I have read and understood the <span
              onClick={() => setTerms({...terms, currentContent: 'terms', show: true})}>Terms & Conditions</span> and
              the <span
              onClick={() => setTerms({...terms, currentContent: 'privacy', show: true})}>Privacy Policy</span>
            </p>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default RegisterForm;