import React, { useState } from "react";
import "./Settings.scss";
import useAuth from "../../../hooks/useAuth";
import ModalSettings from "./modal/ModalSettings";
import ResetPasswordModal from "./modal/ResetPassModal";
import Input from "../../_common/Input/Input";
import Button from "../../_common/Button/Button";
import {getAuth, updatePassword} from "firebase/auth";

function Settings() {
  const { logOut } = useAuth();
  const [modalDelete, setModalDelete] = useState(false);
  const [passModal, setPassModal] = useState(false);
  const [password, setPassword] =useState<string>('')
  const [passwordMessage, setPasswordMessage] = useState<string>('')
  const resetPassModal = () => {
    setPassModal(!passModal);
  };
const handlePasswordMessage =(message:string)=>{
  setPasswordMessage(message)
  const cleanUpMessage =()=>setPasswordMessage((prevState => ''))
  setTimeout(cleanUpMessage, 3000)
}
  const deleteAccountHandler = () => {
    setModalDelete(!modalDelete);
  };

 const updateUserPassword =()=>{
   const auth = getAuth();
   const user = auth.currentUser;
  if(user){
    updatePassword(auth.currentUser, password).then(() => {
      setPassword('')
      handlePasswordMessage('Your password has been changed successfully.')
    }).catch((error) => {
      handlePasswordMessage('Please re-login before changing your password')
    });
  }
 }

  return (
    <>
      {modalDelete && (
        <ModalSettings
          clickHandlerClose={deleteAccountHandler}
          clickHandlerButton={() => {}}
        />
      )}
      {passModal && (
        <ResetPasswordModal
          clickHandlerClose={resetPassModal}
          clickHandlerButton={resetPassModal}
        />
      )}
      <div className="settings_account">
        <h2 className="settings_account_title">Settings</h2>
        <div className="settings_account_item">
          <div className="settings_account_name">
            <h3>Password</h3>
          </div>
          <Input min={8} value={password} onChange={(e)=>setPassword(e.currentTarget.value)} type={'password'} placeholder={'New password'}/>
          {<p className={passwordMessage.includes('Your')? 'success_message': 'error_message'}>{passwordMessage}</p>}
          <div className={'settings_account_password'}>
            <Button disabled={password.length<8} clickHandler={updateUserPassword} className={'primary_button_green'} content={'DONE'}/>
          </div>
        </div>
        <div className={"settings_account_delete"}>
          <div className={"settings_account_delete_left"}>
            <h3>Delete account</h3>
            <p>Permanently delete your Bianu account.</p>
          </div>
          <div
            onClick={deleteAccountHandler}
            className={"settings_account_delete_right"}
          >
            Delete
          </div>
        </div>
        <div className={"settings_account_logout"} onClick={logOut}>
          <span>Log out</span>
        </div>
      </div>
    </>
  );
}

export default Settings;
