import React from "react";
import mainLogo from "../../assets/static/svg/newBianuLogo.svg";
import { useHistory } from "react-router-dom";
import chevron from "../../assets/static/icon/Vector.svg";
import { Routes } from "../../configs/routes/routes";
import PrivacyPolicyText from "./PrivacyPolicyText";

interface Props{
  disableHeader:boolean;
}
function PrivacyPolicy({disableHeader}:Props) {
  const history = useHistory();
  return (
    <div className={"terms_and_conditions"}>
      {disableHeader ? null:
        <div className={"terms_and_conditions_header"}>
          <div className={"terms_main_logo"}>
            <img src={mainLogo} alt="main logo" />
          </div>
          <div className={"header-rec-backBtn"} onClick={() => history.goBack()}>
            <img src={chevron} alt="header-rec-close_icon" />
            <p>Back</p>
          </div>
        </div>
      }
      <div className={"terms_content"}>
        <h2 className={"terms_content_title"}>Privacy Policy</h2>
        <PrivacyPolicyText />
      </div>
      <div className={"terms_footer"}>
        <img src={mainLogo} alt="main logo" />
        <p>© 2021 LM3 Technologies Limited</p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
