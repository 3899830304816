import React, { useEffect, useState } from "react";
import AccountMenu from "../../components/account/AccountMenu";
import { accountMenu } from "../../constants/constants";
import PersonalDetails from "../../components/account/personalDetails/PersonalDetails";
import Settings from "../../components/account/settings/Settings";
import Recommendation from "../../components/account/recommendations/Recommendation";
import Payment from "../../components/account/payment/Payment";
import Order from "../../components/account/orders/Order";
import HeaderMain from "../../components/_common/HeaderMain/HeaderMain";
import { useSelector } from "react-redux";
import { store } from "../../types/store";
import { auth } from "../../configs/firebase";
import useAccount from "../../hooks/useAccount";
import { useRecommendations } from "../../hooks/useRecommendations";
import useStripeHook from "../../hooks/useStripeHook";
import "./Account.scss";
import useSubscription from "../../hooks/useSubscription";
import { LocalStorage } from "../../services/storage";
import { useHistory } from "react-router-dom";
import { Routes } from "../../configs/routes/routes";
import Button from "../../components/_common/Button/Button";
import ReactPixel from "react-facebook-pixel";

function Account() {
  const {
    loadAccount,
    setRecommendationsAgain,
    loadOrderData,
    getUserAddress,
  } = useAccount();
  const [menuVisible, setMenuVisible] = useState(false);
  const menuVisibleHandler = () => {
    setMenuVisible(!menuVisible);
  };
  const { loadSupplements } = useRecommendations();
  const { getUserCards } = useStripeHook();
  const { loadSubscription } = useSubscription();
  const { accountData } = useSelector((state: store) => state.account);
  const { firstName } = accountData;
  const [currentSection, setCurrentSection] = useState(accountMenu.PERSONAL);
  const history = useHistory();
  useEffect(() => {
    const listener = auth.onAuthStateChanged((firebaseUser) => {
      if (firebaseUser !== null) {
        loadAccount(firebaseUser.uid);
        setRecommendationsAgain(firebaseUser.uid);
        loadSupplements();
        getUserCards();
        loadSubscription();
        loadOrderData(firebaseUser.uid);
        getUserAddress(firebaseUser.uid);
        LocalStorage.set("recProduct", "");
      }
    });
    return () => listener();
  }, []);
  const renderItem = (pressItem: string) => {
    switch (pressItem) {
      case accountMenu.RECOMMENDATION:
        return accountData.hasCompletedQuizPart1 ? (
          <Recommendation />
        ) : (
          <div>
            <h2 className={"order_account_title"}>Recommendations</h2>
            <div className={"no_subscription_wrapper"}>
              <h2>Finish quiz to get your recommendations</h2>
              <Button
                content={"Start pregnancy quiz"}
                className={"primary_button_orange"}
                clickHandler={() => {
                  //@ts-ignore
                  window.cqet("send", "CustomEvent", {
                    eventName: "Start quiz button clicked on home page",
                  });
                  ReactPixel.trackCustom("Start quiz button clicked on home page")
                  history.push(Routes.QUIZ);
                }}
              />
            </div>
          </div>
        );
      case accountMenu.ORDERS:
        return <Order />;
      case accountMenu.PAYMENT:
        return <Payment />;
      case accountMenu.PERSONAL:
        return <PersonalDetails />;
      case accountMenu.SETTINGS:
        return <Settings />;
      default:
        return null;
    }
  };
  return (
    <>
      <div className="account">
        <HeaderMain menuBtnHandler={menuVisibleHandler} />
        <div className={"container"}>
          <div className={"account_head_img"}>
            <p>Hi {firstName}!</p>
          </div>
          <AccountMenu clickHandler={setCurrentSection}>
            {renderItem(currentSection)}
          </AccountMenu>
        </div>
      </div>
    </>
  );
}

export default Account;
