import Delete from "../../src/assets/static/Cart_img/Delete.png";
import Photo1 from "../../src/assets/static/Cart_img/Photo.png";
import Photo2 from "../../src/assets/static/recommendation_img/rec-item2.svg";
import Photo3 from "../../src/assets/static/recommendation_img/rec-item3.svg";

export const CartImg = {
  delete: Delete,
  photo1: Photo1,
  photo2: Photo2,
  photo3: Photo3,
};
