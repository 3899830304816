import React, { useEffect } from "react";
import "./App.scss";
import { AppRouter } from "./Router";
import { Provider } from "react-redux";
import { store } from "./store";
import ReactPixel from 'react-facebook-pixel';

function App() {
  useEffect(() => {
      ReactPixel.init('865536094174292')
      ReactPixel.trackCustom('PageViewHome')
    //@ts-ignore
    window.cqet("send", "CustomEvent", {eventName: 'PageViewHome'});
  }, []);
  return (
    <div className="App">
      <Provider store={store}>
        <AppRouter />
      </Provider>
    </div>
  );
}

export default App;
