import Logo from "../assets/static/svg/newBianuLogo.svg";
import ChevronLeft from "../assets/static/svg/Chevron-left.svg";
import closeIcon from "../assets/static/icon/close.svg";
import Close from "../assets/static/icon/close.svg";
import FormImage from "../assets/static/svg/FormImage.svg";
import LoaderIcon from "../assets/static/png/newLoader.png";

export const SVG = {
  LOGO: Logo,
  CHEVRON_LEFT: ChevronLeft,
  CLOSE_ICON: closeIcon,
  CLOSE: Close,
  FORM_IMAGE: FormImage,
  LOADER_ICON: LoaderIcon,
};
