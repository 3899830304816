import React from "react";
import imgOne from "../../../assets/static/homePageImages/images/icons/app-store-icon.svg";
import imgSecond from "../../../assets/static/png/Biaeat.png";
import imgThree from "../../../assets/static/homePageImages/images/icons/google-play-icon.svg";
import imgFour from "../../../assets/static/homePageImages/images/nutritionImg/nutrition-icon1.svg";
import imgFive from "../../../assets/static/png/Biamove.png";
import imgSix from "../../../assets/static/homePageImages/images/nutritionImg/nutrition-icon2.svg";
import imgSeven from "../../../assets/static/png/Biabreathe.png";
import imgEight from "../../../assets/static/homePageImages/images/nutritionImg/nutrition-icon3.svg";

function SectionFour() {
  return (
    <section id={"nutrition_anchor"} className="nutrition">
      <div className="container">
        <div className="nutrition-title">
          <div className="nutrition-title-text">
            Personalised holistic wellbeing on tap via the Bianu app
          </div>
          <div className="nutrition-title-buttons">
              <img className="nutrition-button_app" src={imgOne} alt="" />
              <img className="nutrition-button_google" src={imgThree} alt="" />
          </div>
        </div>
        <div className="nutrition-items">
          <div className="nutrition-item">
            <img className="nutrition-item_img" src={imgSecond} alt="" />
            <div className="nutrition-item_logo">
              <div className="nutrition-item_iconContainer1">
                <img className="nutrition-item_img1" src={imgFour} alt="" />
              </div>
              <p className="nutrition-item_logoName">
                {" "}
                bia<span>eat</span>
              </p>
            </div>
            <p className="nutrition-item_text">
              Build a healthy, balanced diet around your supplements with quick,
              easy recipes and simple nutrition tips based on your interests and
              pregnancy symptoms.
            </p>
          </div>
          <div className="nutrition-item">
            <img className="nutrition-item_img" src={imgFive} alt="" />
            <div className="nutrition-item_logo">
              <div className="nutrition-item_iconContainer2">
                <img className="nutrition-item_img2" src={imgSix} alt="" />
              </div>
              <p className="nutrition-item_logoName">
                {" "}
                bia<span>move</span>
              </p>
            </div>
            <p className="nutrition-item_text">
              Be strong as you prepare for birth with dozens of prenatal
              workouts recommended to you based on your specific motivation and
              ability level.
            </p>
          </div>
          <div className="nutrition-item">
            <img className="nutrition-item_img" src={imgSeven} alt="" />
            <div className="nutrition-item_logo">
              <div className="nutrition-item_iconContainer3">
                <img className="nutrition-item_img3" src={imgEight} alt="" />
              </div>
              <p className="nutrition-item_logoName">
                {" "}
                bia<span>breathe</span>
              </p>
            </div>
            <p className="nutrition-item_text">
              Relax and focus with affirmations, meditations and podcasts to
              help you navigate the twists and turns of your pregnancy.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SectionFour;
