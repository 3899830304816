import {
  createUserWithEmailAndPassword, getAuth, onAuthStateChanged, signInAnonymously,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

import { auth, db } from "../configs/firebase";
import { useHistory } from "react-router-dom";
import { Routes } from "../configs/routes/routes";
import useAccount from "./useAccount";
import useStripeHook from "./useStripeHook";
import {
  actionResetQuizReducer,
  actionResetQuizzes,
  actionSetQuizNav,
} from "../store/actions/quiz-actions";
import { defaultNavState } from "../constants/defaultData";
import { useDispatch } from "react-redux";
import { LocalStorage } from "../services/storage";
import {actionGetAnonymousUid, actionResetAccountReducerAC} from "../store/actions/account-actions";
import { resetSubscriptionReducerAction } from "../store/actions/currentSubscription-action";
import { actionResetProductsReducer } from "../store/actions/product-action";
import { actionResetRecReducer } from "../store/actions/rec-actions";
import { showDetailResetAction } from "../store/actions/showDetailAction";
import { actionResetSubscription } from "../store/actions/subscription-action";
import { doc, setDoc, Timestamp } from "firebase/firestore";

function useAuth() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { firstCreateStripeCustomer } = useStripeHook();
  const { loadAccount, setAccountDocument } = useAccount();
  const registration = async (data: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
  }) => {
    try {
      const { email, password } = data;
      const res = await createUserWithEmailAndPassword(auth, email, password);
      await setAccountDocument(res.user, data);
      await setDoc(
        doc(db, "users", res.user.uid),
        {
          id: res.user.uid,
          path: `users/${res.user.uid}`,
          hasStartedQuiz: true,
          hasCompletedQuizPart1: false,
          createdAt: Timestamp.now(),
        },
        { merge: true }
      );
      await setDoc(
        doc(db, "users", res.user.uid, "userData", "supplementQuiz"),
        {
          id: "supplementQuiz",
          path: `users/${res.user.uid}/userData/supplementQuiz`,
          userId: res.user.uid,
        }
      );

      await firstCreateStripeCustomer();
      return res;
    } catch (e: any) {
      return e;
    }
  };

  const login = async (data: { email: string; password: string }) => {
    try {
      const { email, password } = data;
      const res = await signInWithEmailAndPassword(auth, email, password);
      await loadAccount(res.user.uid);
      await firstCreateStripeCustomer();
      return res;
    } catch (e) {
      console.log(e);
      return e;
    }
  };
  const logOut = async () => {
    await LocalStorage.clear();
    await signOut(auth);
    dispatch(actionResetAccountReducerAC());
    dispatch(resetSubscriptionReducerAction());
    dispatch(actionResetProductsReducer());
    dispatch(actionResetQuizzes());
    dispatch(actionResetQuizReducer());
    dispatch(actionResetRecReducer());
    dispatch(showDetailResetAction());
    dispatch(actionResetSubscription());
    dispatch(actionSetQuizNav(defaultNavState));
    history.push(Routes.LOGIN);
  };
  const anonymousAuth = async ()=>{
    try {
      const auth = getAuth();
      await signInAnonymously(auth)
      await onAuthStateChanged(auth, (user) => {
        if (user){
          dispatch(actionGetAnonymousUid(user.uid))
        }
      })
    } catch (e) {
      console.log(e)
    }
  }
  return { registration, login, logOut,anonymousAuth };
}

export default useAuth;
