import { createStore, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { showDetailsReducer } from "./reducers/showDetailsReducer";
import { quizReducer } from "./reducers/quiz-reducer";
import { subscriptionReducer } from "./reducers/subscriptionReducer";
import { currentSubscriptionReducer } from "./reducers/currentSubscriptionReducer";
import { accountReducer } from "./reducers/accountReducer";
import { recReducer } from "./reducers/recReducer";
import { productReducer } from "./reducers/productReducer";
import { quizNavReducer } from "./reducers/quizNavReducer";
import { quizModalReducer } from "./reducers/quizModalReducer";

export const reducer = combineReducers({
  showDetail: showDetailsReducer,
  quizzes: quizReducer,
  account: accountReducer,
  subscription: subscriptionReducer,
  currentSub: currentSubscriptionReducer,
  rec: recReducer,
  products: productReducer,
  nav: quizNavReducer,
  quizModal: quizModalReducer,
});

export type RootState = ReturnType<typeof reducer>;
export const store = createStore(reducer, composeWithDevTools());
