import React from "react";
import { ReactSVG } from "react-svg";
import "./Svg.scss";

interface Props {
  icon: string;
  size?: number;
}

export const Svg: React.FC<Props> = (props) => {
  const { icon, size = 20 } = props;
  const style = {
    width: size,
    height: size,
  };

  return (
    <ReactSVG
      afterInjection={(error, svg) => {
        if (error) {
          console.error(error, "error");
          return;
        }
      }}
      style={style}
      beforeInjection={(svg) => {
        svg.classList.add("svg-class-name");
      }}
      className="wrapper-class-name"
      evalScripts="always"
      fallback={() => <span>Error!</span>}
      loading={() => <span>Loading</span>}
      onClick={() => {}}
      renumerateIRIElements={false}
      src={icon}
      useRequestCache={false}
      wrapper="span"
    />
  );
};
