import React from "react";
import "./Programme.scss";
import { SVG } from "../../../constants/svg";
import Button from "../../_common/Button/Button";
import { useSelector } from "react-redux";
import Loader from "../../_common/Loader/Loader";

interface Props {
  items: any;
  learnMore?: (e: React.MouseEvent) => void;
  className?: boolean;
  loading?: boolean;
  buttonHandler?: any;
  defaultProduct?: string;
  short?: boolean;
}

export default function Program(props: Props) {
  const {
    items,
    learnMore,
    className,
    loading,
    buttonHandler,
    defaultProduct,
    short,
  } = props;
  const productId = useSelector((state: any) => state.rec.favorite);
  const renderList = () => {
    return Object.values(items).map((item: any, index: number) => {
      if (item.title !== "1:1 coach") {
        return (
            <div
              id={item.id}
              key={index}
              className={
                className
                  ? "recommendation-program_item_account"
                  : "recommendation-program_item"
              }
            >
              <div className={"program-item_images"}>
                {productId === item.id && (
                  <div className={'rec_label_container'}>
                    <p className="recommended_label">Recommended</p>
                  </div>
                )}
                <img
                  className={"program-item_img"}
                  src={item.img}
                  alt="program icon"
                />
              </div>
              <p className={"program-item_title"}>{item.title}</p>
              {!short && <p className={"program-item_text"}>{item.text}</p>}
              {index === 0  ? (
                  <div
                      onClick={learnMore}
                      className={"recommendation-program_link"}
                  >
                    Learn more
                  </div>
              ) : null}
            </div>
        );
      } else {
        return (
          <div key={index}>
            <div
              id={item.id}
              className={
                className
                  ? "recommendation-program_item_account"
                  : "recommendation-program_item"
              }
            >
              <img
                className={"program-item_img"}
                src={item.img}
                alt="program icon"
              />
              <p className={"program-item_title"}>{item.title}</p>
              {!short && <p className={"program-item_text"}>{item.text}</p>}
            </div>
            {index === 0 && className ? (
              <div
                onClick={learnMore}
                className={"recommendation-program_link"}
              >
                Learn more
              </div>
            ) : null}
          </div>
        );
      }
    });
  };

  return (
    <div
      className={
        className ? "recommendation-program_account" : "recommendation-program"
      }
    >
      <div
        className={
          className
            ? "recommendation-program_items_account"
            : "recommendation-program_items"
        }
      >
        {loading ? (
          renderList()
        ) : (
          <Loader/>
          // <div className={"loader_wrapper"}>
          //   <img className={"loader_icon"} src={SVG.LOADER_ICON} alt="loader" />
          // </div>
        )}
      </div>
      {/*{!className && loading && (*/}
      {/*  <div onClick={learnMore} className={"recommendation-program_link"}>*/}
      {/*    Learn more*/}
      {/*  </div>*/}
      {/*)}*/}
      {className && (
        <div className={"programme_button_wrapper"}>
          {buttonHandler && (
            <Button
              content={"add & continue"}
              clickHandler={buttonHandler}
              className={"primary_button_green"}
            />
          )}
        </div>
      )}
    </div>
  );
}
