import logo from "../../../assets/static/svg/newBianuLogo.svg";
import React from "react";
import "./Header.scss";
import { useHistory } from "react-router-dom";
import closeIcon from "../../../assets/static/icon/close.svg";
import arrowBack from "../../../assets/static/icon/Vector.svg";
import { Routes } from "../../../configs/routes/routes";
import {auth} from "../../../configs/firebase";

interface Props {
  noButton?: boolean;
}

function Header({ noButton }: Props) {
  const history = useHistory();
  const backButtonHandler = (pathname:string)=>{
    switch (pathname){
      case Routes.CONFIRMATION:{
        return history.push(Routes.ACCOUNT)
      }
      case Routes.RECOMMENDATION:{
        return history.push(Routes.HOME)
      }
      default: history.goBack()
    }
  }
  return (
    <div className="header-rec">
      <div
        className={"header-rec-backBtn"}
        onClick={() =>backButtonHandler(history.location.pathname) }
      >
        <img
          src={noButton ? arrowBack : closeIcon}
          alt="header-rec-close_icon"
        />
        <p>{noButton ? "back" : "close"}</p>
      </div>
      <img
        className={"header-rec-logo"}
        src={logo}
        onClick={() => history.push(Routes.HOME)}
        alt={"logo-icon"}
      />
      <div className={"div"} />
    </div>
  );
}

export default Header;
