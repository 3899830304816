import React, { useEffect, useState } from "react";
import one from "../../../../assets/static/account_img/Frame 699.svg";
import two from "../../../../assets/static/recommendation_img/rec-item2.svg";
import three from "../../../../assets/static/recommendation_img/rec-item3.svg";
import useAccount from "../../../../hooks/useAccount";
import { onAuthStateChanged } from "firebase/auth";
import {auth, firebaseConfig} from "../../../../configs/firebase";
import { Routes } from "../../../../configs/routes/routes";
import { useSelector } from "react-redux";
import { useRecommendations } from "../../../../hooks/useRecommendations";
import Loader from "../../../_common/Loader/Loader";
import {getDownloadURL, getStorage, ref} from "firebase/storage";

interface Props {
  product: any;
}

function OrderCartItems(props: Props) {
  const [prodImage, setProdImage] = useState<string>('')
  const storage = getStorage();

  // const { setRecommendationsAgain, loadAccount } = useAccount();
  // const { loadSupplements } = useRecommendations();
  // const [isLoading, setIsLoading] = useState(true);
  // const { productId } = useSelector((state: any) => state.account.orderData);
  // useEffect(() => {
  //   onAuthStateChanged(auth, (user) => {
  //     if (user) {
  //       loadAccount(user.uid);
  //       setRecommendationsAgain(user.uid);
  //       loadSupplements().then(() => setIsLoading(false));
  //     }
  //   });
  // }, []);
  // const { supplements, recommended } = useSelector((state: any) => state.rec);
  // const findRecProduct = supplements.find((item: any) => {
  //   if (productId === "") {
  //     return item.id === recommended;
  //   }
  //   if (productId !== "") {
  //     return item.id === productId;
  //   }
  // });
  const { product } = props;

  const getSrc = async () => {

    if(product){
      return await getDownloadURL(
        ref(
          storage,
          `gs://${firebaseConfig.storageBucket}/${product.webImagePath}`
        )
      )
    }
  }
  useEffect(() => {
    getSrc().then((url) => url && setProdImage(url))
  }, [product])
  return (
    <div className={"order_account_cart_items"}>
      {
        <>
          <h3>Prepare for birth programme</h3>
          <div className={"order_account_cart_item"}>
            <img className={"current-product"} src={one && prodImage} />
            <p>{product && product.name}</p>
            <div className={"cart_item_delivering"}>Active</div>
          </div>
          <div className={"order_account_cart_item"}>
            <img src={two} />
            <p>Nutrition & wellness programme</p>
            <div>Active</div>
          </div>
          <div className={"order_account_cart_item"}>
            <img src={three} />
            <p>Access to 1:1 coaching</p>
            <div>Active</div>
          </div>
        </>
      }
    </div>
  );
}

export default OrderCartItems;
