import React, {useEffect, useState} from "react";
import Input from "../../components/_common/Input/Input";
import Button from "../../components/_common/Button/Button";
import LogInWrapper from "../../components/logIn/LogInWrapper";
import { useHistory } from "react-router-dom";
import { Routes } from "../../configs/routes/routes";
import useAuth from "../../hooks/useAuth";
import { SVG } from "../../constants/svg";
import {auth} from "../../configs/firebase";

function LogIn() {
  const history = useHistory();
  const { login } = useAuth();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState({ email: "", password: "" });

  const userDataHandler = (value: string, name: string) => {
    setUserData({ ...userData, [name]: value });
  };
  const logInHandler = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    const res: any = await login(userData);
    if (res.message) {
      setError(true);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setError(false);
      history.push(Routes.ACCOUNT);
    }
  };
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      if(firebaseUser !== null && !firebaseUser.isAnonymous) {
        history.push(Routes.ACCOUNT)
      }
    });
    return unsubscribe;
  }, []);
  const { email, password } = userData;
  return (
    <LogInWrapper footer>
      <h1>Login as existing customer</h1>
      <form className={"login_form"} onSubmit={logInHandler}>
        <Input
          onChange={(e) =>
            userDataHandler(e.currentTarget.value, e.currentTarget.name)
          }
          value={email}
          type={"email"}
          name={"email"}
          placeholder={"Email"}
        />
        <Input
          onChange={(e) =>
            userDataHandler(e.currentTarget.value, e.currentTarget.name)
          }
          value={password}
          type={"password"}
          name={"password"}
          placeholder={"Password"}
        />
        {error && (
          <span className={"error_login_msg"}>Wrong email or password.</span>
        )}

        {isLoading && (
          <img className={"loader_icon"} src={SVG.LOADER_ICON} alt="loader" />
        )}
        <Button
          type={"submit"}
          content={"Log In"}
          className={"primary_button_green"}
          disabled={isLoading}
        />
      </form>

      <div
        onClick={() => {
          history.push(Routes.REGISTRATION_EMAIL);
        }}
      >
        Forgot password?
      </div>
    </LogInWrapper>
  );
}

export default LogIn;
