import React from "react";
import one from "../../../assets/static/homePageImages/updateIMG/Ellipse 49.png";
import second from "../../../assets/static/homePageImages/updateIMG/Ellipse 49-2.png";
import three from "../../../assets/static/homePageImages/updateIMG/Ellipse 49-3.png";
import four from "../../../assets/static/homePageImages/updateIMG/Ellipse 49-4.png";

function SectionStories() {
  return (
    <section className="section_stories desktop_content">
      <div className="container">
        <h2>Bianu stories</h2>
        <div className="section_stories_content_wrapper">
          <div className="section_stories_content_item ">
            <div className="section_stories_content_item_left story_1">
              <h3>
                Pregnancy
                <br /> Essentials
              </h3>
              <img src={one} alt="" />
            </div>
            <div className="section_stories_content_item_right">
              <p>Georgina, London</p>
              <span>
                I love that the Bianu supplements are personalised – it feels
                like I’m getting exactly what I need during my first pregnancy
                rather than just taking vitamins for the sake of it.
              </span>
            </div>
          </div>
          <div className="section_stories_content_item">
            <div className="section_stories_content_item_left story_2">
              <h3>
                Plant
                <br /> Based
              </h3>
              <img src={second} alt="" />
            </div>
            <div className="section_stories_content_item_right">
              <p>Sienna, Norwich</p>
              <span>
                I was so worried about not getting the right nutrients during my
                first trimester, especially as I couldn’t swallow pills due to
                nausea. It’s such a relief to have granules rather than pills.
              </span>
            </div>
          </div>
          <div className="section_stories_content_item">
            <div className="section_stories_content_item_left story_3">
              <h3>
                Dairy
                <br /> Free
              </h3>
              <img src={three} alt="" />
            </div>
            <div className="section_stories_content_item_right">
              <p>Valery, Manchester</p>
              <span>
                Bianu is more than just supplements for me – having 24/7 access
                to the app, the fitness coach, all the meditations, articles and
                recipes, has helped me feel less anxious throughout my
                pregnancy.
              </span>
            </div>
          </div>
          <div className="section_stories_content_item">
            <div className="section_stories_content_item_left story_4">
              <h3>
                The
                <br /> Flexitarian
              </h3>
              <img src={four} alt="" />
            </div>
            <div className="section_stories_content_item_right">
              <p>Krisha, Warwick</p>
              <span>
                It’s impressive how Bianu took into consideration my dietary
                preferences to put together a supplement which was the best for
                me and my growing baby – and it adapts as your pregnancy
                progresses!
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SectionStories;
