import React, { useEffect, useState } from "react";
import "./QuizForm.scss";
import { stepTypes } from "../../constants/defaultData";
import { useHistory } from "react-router-dom";
import InputForm from "../Forms/InputForm/InputForm";
import DateForm from "../Forms/DateForm/DateForm";
import ConfirmForm from "../Forms/ConfirmForm/ConfirmForm";
import SingleForm from "../Forms/SingleForm/SingleForm";
import RegistrationForm from "../Forms/RegistrationForm/RegistrationForm";
import MultipleForm from "../Forms/MultipleForm/MultipleForm";
import { makeQuizObject, UtilsCommon } from "../../utils/UtilsCommon";
import { UtilsPregnancy } from "../../utils/UtilsPregnancy";
import useAuth from "../../hooks/useAuth";
import useQuizzes from "../../hooks/useQuizzes";
import { Routes } from "../../configs/routes/routes";
import { useRecommendations } from "../../hooks/useRecommendations";
import {
  confirmStep,
  dateStep,
  inputStep,
  loginPasswordStep,
  multipleStep,
  QuizNav,
  singleStep,
} from "../../types/quizTypes";
import useAccount from "../../hooks/useAccount";
import { useDispatch, useSelector } from "react-redux";
import {
  actionOpenModal,
  actionResetQuizReducer,
  actionSetQuizNav,
  actionSetQuizNavCurrent,
} from "../../store/actions/quiz-actions";
import QuizModal from "../QuizModal/QuizModal";
import { RootState } from "../../store";
import { actionGetDataQuizAC } from "../../store/actions/account-actions";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../configs/firebase";
import ReactPixel from "react-facebook-pixel";
import {store} from "../../types/store";

function QuizForm() {
  const nav: QuizNav = useSelector((state: any) => state.nav);
  const [steps, setSteps] = useState(UtilsCommon.makeSteps());
  const [stepData, setStepData] = useState(
    UtilsCommon.getCurrentStepData(nav.currentStepId)
  );
  const  {anonymousUid} = useSelector((state:store)=>state.account.accountData)
  const [dueDate, setDueDate] = useState(new Date());
  const [trimester, setTrimester] = useState(0);
  const [registerDisabled, setRegisterDisabled] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { registration } = useAuth();
  const [userId, setUserId] = useState("");
  const { sendQuiz } = useQuizzes();
  const { setRecommendations } = useRecommendations();
  const { loadAccount } = useAccount();
  useEffect(() => {

    const listener = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
        loadAccount(user.uid);
      }
    });
    return function cleanup() {
      listener();
      dispatch(actionSetQuizNavCurrent("stp0001"));
    };
  }, []);

  useEffect(() => {
    if (stepData) {
      dispatch(
        actionSetQuizNav({
          nextStepId: stepData.nextStepId,
          prevStepId: stepData.prevStepId,
          currentStepId: stepData.id,
          altNextStepId: stepData.altNextStep,
        })
      );
    }
  }, [stepData]);
const test =()=>    ReactPixel.trackCustom(`Quiz step ${stepData?.displayStepNumber} completed`)

  const goToNext = () => {
    //@ts-ignore
    window.cqet("send", "CustomEvent", {
      eventName: `Quiz step ${stepData?.displayStepNumber} completed`,
    });
    ReactPixel.trackCustom(`Quiz step ${stepData?.displayStepNumber} completed`)
    dispatch(actionSetQuizNavCurrent(nav.nextStepId));
  };

  const goToAltNext = () => {
    //@ts-ignore
    window.cqet("send", "CustomEvent", {
      eventName: `Quiz step ${stepData?.displayStepNumber} completed`,
    });
    ReactPixel.trackCustom(`Quiz step ${stepData?.displayStepNumber} completed`)
    dispatch(actionSetQuizNavCurrent(nav.altNextStepId));
  };

  useEffect(() => {
    setStepData(UtilsCommon.getCurrentStepData(nav.currentStepId));
    // changeBar();
  }, [nav.currentStepId]);

  const submitInput = (newValue: { firstName: string; lastName: string }) => {
    setSteps({
      ...steps,
      [nav.currentStepId]: { answer: newValue },
    });
    if (stepData?.isCheckpoint) {
      UtilsCommon.setCheckpoint(stepData.id);

    }
    if (stepData) {

      // setStep(stepData.nextStepId);
      goToNext();
    }
  };

  const submitDate = (
    values: { dd: string; mm: string; yyyy: string },
    cycle: string
  ) => {
    if (stepData && values) {
      if ("isBirthday" in stepData && stepData.isBirthday) {
        setSteps({
          ...steps,
          [nav.currentStepId]: {
            mm: values.mm,
            dd: values.dd,
            yyyy: values.yyyy,
          },
        });
      } else {
        const initialDate = `${values.yyyy}-${values.mm}-${values.dd}`;
        const date = cycle
          ? UtilsPregnancy.getDueDate(cycle, Date.parse(initialDate))
          : new Date(initialDate);
        const daysLeft = UtilsPregnancy.getDaysToDueDate(date);
        const trimester = UtilsPregnancy.getTrimester(daysLeft);
        setDueDate(date);
        setTrimester(trimester);
        setSteps({
          ...steps,
          [nav.currentStepId]: {
            mm: values.mm,
            dd: values.dd,
            yyyy: values.yyyy,
            cycle,
          },
        });
      }
      if (stepData?.isCheckpoint) UtilsCommon.setCheckpoint(stepData.id);
      // setStep(stepData.nextStepId);
      goToNext();
    }
  };

  const submitSingle = async (
    action: string,
    text: string,
    enumItem?: string
  ) => {
    if (!action) {
      setSteps({
        ...steps,
        [nav.currentStepId]: {
          ...steps[nav.currentStepId],
          answer: { text, enumItem },
        },
      });
      if (stepData) {
        if (stepData.isCheckpoint) {
          UtilsCommon.setCheckpoint(stepData.id);
        }
        trimester === 1 && stepData.altNextStep && stepData.id === "stp0009"
          ? goToAltNext()
          : goToNext();
      }
    } else if (action === "next") {
      goToNext();
    } else if (action === "altNext") {
      goToAltNext();
    } else if (action === "showWarning") {
      if (
        stepData &&
        "modal" in stepData &&
        stepData.modal &&
        stepData.modal.supplements.button &&
        stepData.modal.supplements.subHeader
      ) {
        dispatch(
          actionOpenModal({
            header: stepData.modal.supplements.header,
            open: true,
            button: stepData.modal.supplements.button,
            content: stepData.modal.supplements.text,
            subHeader: stepData.modal.supplements.subHeader,
            canBeClosed: false,
          })
        );
      }
    } else if ((stepData && stepData.isReset) || action === "end") {
      //@ts-ignore
      window.cqet("send", "CustomEvent", {
        //@ts-ignore
        eventName: `Quiz step ${stepData?.displayStepNumber - 1} completed`,
      });
      //@ts-ignore
      ReactPixel.trackCustom(`Quiz step ${stepData?.displayStepNumber - 1} completed`)
      // await sendQuiz(
      //   userId,
      //   makeQuizObject(steps, dueDate, nav.currentStepId, userId, trimester),
      //   dueDate
      // );
      // await sendQuiz(
      //   userId,
      //   makeQuizObject(steps, dueDate, nav.currentStepId, userId, trimester),
      //   dueDate
      // );
      // setSteps((current: any) => {
      //   return {
      //     ...steps,
      //     [nav.currentStepId]: {
      //       ...steps[nav.currentStepId],
      //       answer: { text, enumItem },
      //     },
      //   };
      // });
      // debugger;
      // (steps.stp0008.answer);
      localStorage.setItem('name', steps.stp0001.answer.firstName)
      //@ts-ignore
      await sendQuiz(anonymousUid, {
        lastKnownTrimester: trimester,
        dateOfBirth:
          steps.stp0002.yyyy && steps.stp0002.mm && steps.stp0002.dd
            ? new Date(
                `${steps.stp0002.yyyy}-${steps.stp0002.mm}-${steps.stp0002.dd}`
              )
            : undefined,
        dueDate:
          steps.stp0003.yyyy && steps.stp0003.mm && steps.stp0003.dd
            ? new Date(
                `${steps.stp0003.yyyy}-${steps.stp0003.mm}-${steps.stp0003.dd}`
              )
            : dueDate,
        firstDayOfLastPeriod:
          steps.stp0006.yyyy && steps.stp0006.mm && steps.stp0006.dd
            ? new Date(
                `${steps.stp0006.yyyy}-${steps.stp0006.mm}-${steps.stp0006.dd}`
              )
            : undefined,
        cycleLength: Number(steps.stp0006.cycle),
        takingSupplements: steps.stp0008.text === "Yes",
        currentDiet: steps.stp0009.answer.text,
        currentDietEnum: steps.stp0009.answer.enumItem,
        hasSickness:
          steps.stp0011.answer === "Yes" || steps.stp0010.answer === "Yes",
        foodIntake: steps.stp0012.answer.text || text,
        foodIntakeEnum: steps.stp0012.answer.enumItem || enumItem,
        healthyDiet: action === "sendYes",
        anonymousUid,
        id: "supplementQuiz",
        path: `users/${anonymousUid}/userData/supplementQuiz`,
      });
      //@ts-ignore
      await setRecommendations(trimester, anonymousUid);
      //@ts-ignore

      await loadAccount(anonymousUid);
      // dispatch(
      //   actionGetDataQuizAC({ firstName: steps.stp0001.answer, lastName: "" })
      // );
      history.push(Routes.RECOMMENDATION);
    }
  };

  const submitMultiple = async (selected: string[]) => {
    setSteps({
      ...steps,
      [nav.currentStepId]: {
        ...steps[nav.currentStepId],
        selected,
      },
    });
    // if (stepData && stepData.isCheckpoint) {
    //   UtilsCommon.setCheckpoint(stepData.id);
    goToNext();
    // }
    // else if (stepData && stepData.isReset) {
    //   await sendQuiz(
    //     userId,
    //     makeQuizObject(steps, dueDate, nav.currentStepId, userId, trimester),
    //     dueDate
    //   );
    //   await setRecommendations(trimester, userId);
    //   if (stepData) {
    //     await sendQuiz(
    //       userId,
    //       makeQuizObject(steps, dueDate, nav.currentStepId, userId, trimester),
    //       dueDate
    //     );
    //     await loadAccount(userId);
    //     (steps.stp0001.answer);
    //     dispatch(
    //       actionGetDataQuizAC({ firstName: steps.stp0001.answer, lastName: "" })
    //     );
    //     history.push(Routes.RECOMMENDATION);
    //   }
    // }
  };

  const submitRegistration = async (email: string, password: string) => {
    setRegisterDisabled(true);
    const res = await registration({
      email,
      password,
      firstName: steps.stp0001.answer.firstName,
      lastName: steps.stp0001.answer.lastName,
    });
    // if (stepData.isCheckpoint) UtilsCommon.setCheckpoint(stepData.id);
    // setStep(stepData.nextStepId);
    // (res);
    // } else
    if (res.code && res.code === "auth/email-already-in-use") {
      setRegisterDisabled(false);
      alert("Please choose an email that is not already in use");
      return true;
    } else if (res.code) {
      alert("check the credentials");
    }

    if (res.user.uid && !res.code) {
      setUserId(res.user.uid);
      await loadAccount(res.user.uid);
      goToNext();
      return true;
    }

    setRegisterDisabled(false);
  };

  const modalOpen = useSelector((state: RootState) => state.quizModal.open);

  const makeForm = () => {
    switch (stepData?.type) {
      case stepTypes.INPUT: {
        return (
          <>
            <InputForm
              stepData={stepData as inputStep}
              submitHandler={submitInput}
            />
          </>
        );
      }
      case stepTypes.DATE: {
        return (
          <DateForm
            submitDate={submitDate}
            stepData={stepData as dateStep}
            navFunc={goToAltNext}
          />
        );
      }
      case stepTypes.CONFIRM: {
        return (
          <ConfirmForm
            navFunc={goToNext}
            stepData={stepData as confirmStep}
            dueDate={dueDate}
          />
        );
      }
      case stepTypes.SINGLE: {
        return (
         <>
           <SingleForm
             stepData={stepData as singleStep}
             submitHandler={submitSingle}
           /></>
        );
      }
      case stepTypes.MULTIPLE: {
        return (
        <>
          <MultipleForm
            submitHandler={submitMultiple}
            stepData={stepData as multipleStep}
          />
        </>
        );
      }
      case stepTypes.LOGIN_PASSWORD: {
        return (
          <RegistrationForm
            formDisabled={registerDisabled}
            stepData={stepData as loginPasswordStep}
            submitHandler={submitRegistration}
            name={steps.stp0001.answer.firstName}
          />
        );
      }
    }
  };

  return (
    <div className="quiz-form">
      {modalOpen && <QuizModal />}
      {makeForm()}
    </div>
  );
}

export default QuizForm;
