import { Account } from "../../types/account";
import { accountActionTypes } from "../account-action-types";
import { SubscriptionHistoryItem } from "../../types/subscription";

export function actionGetDataAccountAC(data: Account) {
  return {
    type: accountActionTypes.GET_DATA,
    data,
  };
}
export function actionGetLastAnswer(data: string) {
  return {
    type: accountActionTypes.GET_LAST_ANSWER,
    data,
  };
}
export function actionGetDataQuizAC(data: Account) {
  return {
    type: accountActionTypes.GET_DATA_QUIZ,
    data,
  };
}
export function actionGetDataAddressAC(data: Account) {
  return {
    type: accountActionTypes.GET_DATA_ADDRESS,
    data,
  };
}
export function actionGetKeySTRAC(data: any) {
  return {
    type: accountActionTypes.GET_STR_KEY,
    data,
  };
}
export function actionGetPayMethodAC(data: any) {
  return {
    type: accountActionTypes.GET_PAY_METHOD,
    data,
  };
}
export function actionGetOrderDataAC(data: any) {
  return {
    type: accountActionTypes.GET_ORDER_DATA,
    data,
  };
}
export function actionGetDefaultPayMethodAC(data: string) {
  return {
    type: accountActionTypes.GET_DEFAULT_PAY_METHOD,
    data,
  };
}
export function actionResetAccountReducerAC() {
  return {
    type: accountActionTypes.USER_LOGOUT,
  };
}
export function actionGetAnonymousUid(data:string) {
  return {
    type: accountActionTypes.GET_ANONYMOUS_UID,
    data
  };
}


export function actionUserSubscriptionHistory(data: SubscriptionHistoryItem[]) {
  return {
    type: accountActionTypes.GET_SUBSCRIPTION_HISTORY,
    data,
  };
}
