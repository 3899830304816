import React, { useState } from "react";
import imgPreview from "../../../../assets/static/account_img/Frame 668.svg";
import OrderModalModify from "../orderModals/OrderModalModify";
import OrderModalModifySecond from "../orderModals/OrderModalModifySecond";
import ModalEndSubscription from "../orderModals/ModalEndSubscription";
import ModalEndSubscriptionSecond from "../orderModals/ModalEndSubscriptionSecond";
import { useSelector } from "react-redux";
import { store } from "../../../../types/store";
import { SVG } from "../../../../constants/svg";
import { Routes } from "../../../../configs/routes/routes";
import { useHistory } from "react-router-dom";
import ModalChangePaymentMethod from "../orderModals/ModalChangePaymentMethod";
import Loader from "../../../_common/Loader/Loader";
interface Props {
  loading: boolean;
  currentSubscription: any;
}

function OrderDetails({ loading, currentSubscription }: Props) {
  const { orderData } = useSelector((state: store) => state.account);
  const { supplements } = useSelector((state: any) => state.rec);
  const history = useHistory();
  const [modalVisible, setModalVisible] = useState({
    stepOne: false,
    stepSecond: false,
    endSubscribeOne: false,
    endSubscribeTwo: false,
    changePaymentMethod: false,
  });
  const modalHandler = () => {
    setModalVisible({ ...modalVisible, stepOne: true });
  };
  const modalHandlerSecond = () => {
    setModalVisible({ ...modalVisible, stepSecond: true });
  };
  const endSubscribe = () => {
    setModalVisible({ ...modalVisible, endSubscribeOne: true });
  };
  const endSubscribeSecond = () => {
    setModalVisible({ ...modalVisible, endSubscribeTwo: true });
  };
  const changePaymentMethod = () => {
    setModalVisible({ ...modalVisible, changePaymentMethod: true });
  };
  const closeModalHandler = () => {
    setModalVisible({
      ...modalVisible,
      stepSecond: false,
      stepOne: false,
      endSubscribeOne: false,
      endSubscribeTwo: false,
      changePaymentMethod: false,
    });
  };
  const handlerButtonToCheckout = () => {
    history.push(Routes.ACCOUNT);
  };
  return (
    <>
      {modalVisible.stepOne && (
        <OrderModalModify
          orderData={orderData}
          currentSubscription={currentSubscription}
          clickHandlerClose={closeModalHandler}
          clickHandlerButton={modalHandlerSecond}
        />
      )}
      {modalVisible.changePaymentMethod && (
        <ModalChangePaymentMethod clickHandlerClose={closeModalHandler} />
      )}
      {modalVisible.stepSecond && (
        <OrderModalModifySecond
          orderData={orderData}
          currentSubscription={currentSubscription}
          clickHandlerButton={handlerButtonToCheckout}
          clickHandlerClose={closeModalHandler}
        />
      )}

      <div className={"order_account_top_wrapper"}>
        {loading ? (
          <>
            <div className={"order_account_top_wrapper_left"}>
              <img src={imgPreview} />
            </div>
            <div className={"order_account_top_wrapper_right"}>
              <div className={"order_account_top_wrapper_right_title"}>
                <div className={"order_account_top_wrapper_right_name"}>
                </div>
              </div>
              <div>
                <h2 className={'order_account_sub_title'}>Get access to everything!</h2>
                <ul className={"order_account_top_wrapper_right_order_list"}>
                  <li className={"first_item"}>
                    <span>✔</span>
                    {supplements &&
                      supplements.map((item: any) => {
                        if (item.id === orderData.productId) {
                          return item.name;
                        }
                      })}
                  </li>
                  <li className={"first_item"}><span>✔</span>Access to 1:1 coaching</li>
                  <li className={"first_item"}><span>✔</span> Nutrition & wellness programme</li>
                </ul>
              </div>
              <div className={"order_account_top_wrapper_right_control"}>
                {/*<div onClick={modalHandler}>Modify</div>*/}
                {/*<div onClick={changePaymentMethod}>Сhange payment method</div>*/}

              </div>
            </div>
          </>
        ) : (
          <div className={"loader_wrapper"}>
            <Loader/>
          </div>
        )}
      </div>
    </>
  );
}
export default OrderDetails;
