import {SVG} from "../../../constants/svg";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import "./ProgramDetail.scss";
import {Supplement} from "../../../types/supplements";
import {
  fallbackValues,
  supplementIngredientImages,
  supplementsData,
} from "../../../constants/defaultData";
import Button from "../../_common/Button/Button";
import {LocalStorage} from "../../../services/storage";
import {useHistory} from "react-router-dom";
import {Routes} from "../../../configs/routes/routes";
import {useRecommendations} from "../../../hooks/useRecommendations";
import {actionUpdateFavorite} from "../../../store/actions/rec-actions";
import granules from "../../../assets/static/icon/granules.svg";
import {onAuthStateChanged} from "firebase/auth";
import {auth, firebaseConfig} from "../../../configs/firebase";
import useAccount from "../../../hooks/useAccount";
import {getDownloadURL, getStorage, ref} from "firebase/storage";
import {log} from "util";

interface Props {
  sup: Supplement;
  closeOnClick: () => void;
  className?: boolean;
  showModal?: boolean;
  setShowDetail?: any;
  setProductInStorage?: any;
  setSuggestion?: any;
  suggestion?: any;
  productImage: string;
}

const benefits = [
  "Lactose-free",
  "Gluten-free",
  "Easy to consume",
  "Flavourless",
];

const ProgramDetail: React.FC<Props> = ({productImage,
                                          sup,
                                          closeOnClick,
                                          showModal,
                                          className,
                                        }) => {
  const storage = getStorage();
  const {setRecommendationsAgain} = useAccount();
  const productId = useSelector((state: any) => state.rec.favorite);
  useEffect(() => {
    const listener = onAuthStateChanged(auth, (user) => {
      if (user) {
        setRecommendationsAgain(user.uid);
      } else {
        history.push(Routes.QUIZ);
      }
    });
    return () => listener();
  }, []);
  const {
    imagePath,
    path,
    id,
    benefitHighlights,
    benefitSupplementsAre,
    ingredientInfo,
    ingredientNote,
    ingredients,
    name,
    description,
    suggestedUse,
    updatedAt,
    createdAt,
    webImagePath,
  } = sup;
  const dispatch = useDispatch();
  const history = useHistory();

  // const filterSuggestions = (id: string, event: any) => {
  //   event.preventDefault();
  //   if (id !== productId) {
  //     setSuggestion([...suggestion, productId]);
  //     setSuggestion(
  //       suggestion.map((item2: any) => (item2 === id ? productId : item2))
  //     );
  //   }
  // };

  // const newSupplement = async (id: string, e: any) => {
  //   e.preventDefault();
  //   filterSuggestions(id, e);
  //   dispatch(actionUpdateFavorite(id));
  //   setProductInStorage(id);
  //   LocalStorage.set("recProduct", id);
  //   setShowDetail(false);
  // };
  const getSrc = async () => {
    try {
      return await getDownloadURL(
        ref(
          storage,
          `gs://${firebaseConfig.storageBucket}/${webImagePath}`
        )
      );
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div
      style={{right: showModal ? "0" : "-100%"}}
      className={
        className ? "recommendation-details_account" : "recommendation-details"
      }
    >
      <img
        onClick={() => closeOnClick()}
        className={"recommendation-closeIcon"}
        src={SVG.CLOSE_ICON}
        alt="close-icon"
      />

      <div className={"recommendation-details_header"}>
        <p className={"recommendation-details_nameOfSupMob"}>{name}</p>
        <img
          className={"recommendation-details_logo"}
          src={productImage}
          alt="rec-details-logo"
        />
        <div className={"recommendation-details_content"}>
          <p className={"recommendation-details_nameOfSup"}>{name}</p>
          <ul className={"recommendation-details_headerList"}>
            {benefitHighlights.map((item, index: number) => {
              return <li key={index}>{item}</li>;
            })}
          </ul>
          {/*<Button*/}
          {/*  clickHandler={(e: React.MouseEvent<HTMLButtonElement>) =>*/}
          {/*    newSupplement(e.currentTarget.name, e)*/}
          {/*  }*/}
          {/*  name={id}*/}
          {/*  className={"primary_button_green"}*/}
          {/*  content={"Pick to be my supplement"}*/}
          {/*/>*/}
        </div>
      </div>
      <p className={"recommendation-details_text"}>{description}</p>
      <ul className={"recommendation-details_advantages"}>
        {benefits.map((item, index: number) => {
          return (
            <div className={"recommendation_benefit"}>
              {index === 2 ? (
                <img src={granules} alt={"icon"}/>
              ) : (
                <span className={"recommendation_benefit_icon"}/>
              )}
              <li key={index}>{item}</li>
            </div>
          );
        })}
      </ul>
      <div className={"recommendation-details_whatInside"}>
        <p className={"what_inside"}>What's inside?</p>
        <p className={"recommendation-details-insideText"}>{ingredientInfo}</p>
        <img
          className={"recommendation-details-comprisesImg"}
          src={supplementIngredientImages[id]}
          alt="comprises"
        />
      </div>
    </div>
  );
};

export default ProgramDetail;
