import React, { useEffect } from "react";
import Button from "../../_common/Button/Button";
import "./SingleForm.scss";
import { singleStep } from "../../../types/quizTypes";

interface Props {
  submitHandler: (action: string, text: string, enumItem?: string) => void;
  stepData: singleStep;
}

function SingleForm(props: Props) {
  const { submitHandler, stepData } = props;
  const getClass = () => {
    if (stepData.options.length === 2) {
      if (
        stepData.options[0].text === "Yes" &&
        stepData.options[1].text === "No"
      ) {
        return "yes-no-container";
      } else return "two-button-container";
    } else return "button-container";
  };
  return (
    <div className={"form-wrapper single-form"}>
      <h1>{stepData.header}</h1>
      {stepData.bottomText ? (
        <>
          {stepData.bottomHtml && typeof stepData.bottomText !== "string" ? (
            <div className={"bottom-text-wrapper"}>
              <p>{stepData.bottomText?.header}</p>
              <ul>
                {stepData.bottomText.listItems.map((item: string) => (
                  <li>{item}</li>
                ))}
              </ul>
            </div>
          ) : (
            stepData.bottomText
          )}
        </>
      ) : null}
      <div className={getClass()}>
        {stepData.options?.map((opt) => {
          return (
            <Button
              className={"quiz_button_gray"}
              content={opt.text}
              clickHandler={() => {
                submitHandler(opt.action, opt.text, opt.enum);
              }}
            />
          );
        })}
      </div>
    </div>
  );
}

export default SingleForm;
