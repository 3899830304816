import React, { useEffect, useState } from "react";
import "./PersonalDetails.scss";
import UpdateName from "../updateData/UpdateName";
import UpdateEmail from "../updateData/UpdateEmail";
import UpdateAddress from "../updateData/UpdateAddress";
import { PersonalDetailsSetting } from "../../../constants/defaultData";
import { useSelector } from "react-redux";
import { store } from "../../../types/store";
import UpdatePhone from "../updateData/UpdatePhone";
import UpdateSecondAddress from "../updateData/UpdateSecondAddress";

function PersonalDetails() {
  const [editDetails, setEditDetails] = useState(PersonalDetailsSetting);
  const [secondAddress, setSecondAddress] = useState(false);
  const { accountData } = useSelector((state: store) => state.account);
  const [secondAddressValue, setSecondAddressValue] = useState({
    line1: accountData.secondAddress?.line1,
    line2: accountData.secondAddress?.line2,
    town: accountData.secondAddress?.town,
    postcode: accountData.secondAddress?.postcode,
    isPrimary: false,
    path: `users/${accountData.uid}/userData/address`,
  });
  const [addressOne, setAddressOne] = useState({
    line1: accountData.line1,
    line2: accountData.line2,
    town: accountData.town,
    postcode: accountData.post_code,
    isPrimary: true,
    path: `users/${accountData.uid}/userData/address`,
    fullName: accountData.firstName,
  });
  useEffect(() => {
    if (accountData.line1) {
      setAddressOne({
        ...addressOne,
        line1: line1,
        line2: line2,
        postcode: post_code,
        town: town,
      });
    }
  }, [accountData.line1]);
  useEffect(() => {
    if (
      accountData.second_address_id !== "" &&
      accountData.second_address_id !== undefined
    ) {
      setSecondAddress(true);
    } else {
      setSecondAddress(false);
    }
  }, [accountData.second_address_id]);
  useEffect(() => {
    setSecondAddressValue({
      ...secondAddressValue,
      line1: accountData.secondAddress?.line1,
      line2: accountData.secondAddress?.line2,
      town: accountData.secondAddress?.town,
      postcode: accountData.secondAddress?.postcode,
    });
  }, [accountData.secondAddress]);

  const secondAddressHandler = (value: string, name: string) => {
    setSecondAddressValue({ ...secondAddressValue, [name]: value });
  };
  const {
    firstName,
    lastName,
    email,
    uid,
    line1,
    phone,
    line2,
    town,
    post_code,
  } = accountData;
  const editNameHandler = () => {
    setEditDetails({ ...editDetails, name: !editDetails.name });
  };
  const editEmailHandler = () => {
    setEditDetails({ ...editDetails, email: !editDetails.email });
  };
  const editPhoneHandler = () => {
    setEditDetails({ ...editDetails, phone: !editDetails.phone });
  };
  const editAddressHandler = () => {
    setEditDetails({ ...editDetails, address: !editDetails.address });
  };
  const editSecondAddressHandler = () => {
    setEditDetails({ ...editDetails, address2: !editDetails.address2 });
  };
  const itemDetails = [
    {
      field: "Name",
      data: `${firstName} ${lastName}`,
      setData: (
        <UpdateName
          lastName={lastName && lastName}
          firstName={firstName}
          editNameHandler={editNameHandler}
        />
      ),
      trigger: editDetails.name,
      onClick: editNameHandler,
    },
    {
      field: "Email",
      data: email,
      setData: (
        <UpdateEmail emailData={email} editEmailHandler={editEmailHandler} />
      ),
      trigger: editDetails.email,
      onClick: editEmailHandler,
    },
    {
      field: "Phone",
      data: phone,
      setData: (
        <UpdatePhone phoneData={phone} editPhoneHandler={editPhoneHandler} />
      ),
      trigger: editDetails.phone,
      onClick: editPhoneHandler,
    },
    {
      field: "Address 1",
      data:
        line1 !== undefined &&
        addressOne.line1 !== "" &&
        `${addressOne.line1 && `${addressOne.line1.trim()},`}
                   ${addressOne.line2 && `${addressOne.line2.trim()},`}
                     ${addressOne.town && `${addressOne.town.trim()},`}
                        ${
                          addressOne.postcode && `${addressOne.postcode.trim()}`
                        }`,
      setData: (
        <UpdateAddress
          addressOne={addressOne}
          setAddressOne={setAddressOne}
          editAddressHandler={editAddressHandler}
        />
      ),
      trigger: editDetails.address,
      onClick: editAddressHandler,
    },
  ];
  const renderItem = () => {
    return itemDetails.map((item, index) => {
      const { field, onClick, data, trigger, setData } = item;
      return (
        <div
          key={index}
          className={
            field === "Address 1"
              ? "personal_details_account_item last_item"
              : "personal_details_account_item"
          }
        >
          <div className="personal_details_account_name">
            <h3>{field}</h3>
            {!trigger ? <p>{data}</p> : setData}
          </div>
          <div
            className="personal_details_account_edit_button"
            style={{ display: trigger ? "none" : "flex" }}
            onClick={onClick}
          >
            Edit
          </div>
        </div>
      );
    });
  };
  return (
    <div className="personal_details_account">
      <h2 className="personal_details_account_title">Personal details</h2>
      {renderItem()}
      <div style={{ display: line1 !== undefined || "" ? "block" : "none" }}>
        {secondAddress ? (
          <div className={"personal_details_account_item last_item"}>
            <div className="personal_details_account_name">
              <h3>Address 2</h3>
              {!editDetails.address2 ? (
                <p>
                  {accountData.second_address_id !== "" &&
                    accountData.second_address_id !== undefined &&
                    `${
                      secondAddressValue.line1 &&
                      `${secondAddressValue.line1.trim()},`
                    }  
                    ${
                      secondAddressValue.line2 &&
                      `${secondAddressValue.line2.trim()},`
                    }  
                    ${
                      secondAddressValue.town &&
                      `${secondAddressValue.town.trim()},`
                    }   
                    ${
                      secondAddressValue.postcode &&
                      `${secondAddressValue.postcode.trim()}`
                    }  `}
                </p>
              ) : (
                <UpdateSecondAddress
                  uid={accountData.uid || ""}
                  data={secondAddressValue}
                  editAddressHandler={editSecondAddressHandler}
                  handlerInput={secondAddressHandler}
                />
              )}
            </div>
            <div
              className="personal_details_account_edit_button"
              style={{ display: editDetails.address2 ? "none" : "flex" }}
              onClick={editSecondAddressHandler}
            >
              Edit
            </div>
          </div>
        ) : (
          <div
            onClick={() => {
              setSecondAddress(!secondAddress);
            }}
            className={"personal_details_account_add_address"}
          >
            + Add another address
          </div>
        )}
      </div>
    </div>
  );
}

export default PersonalDetails;
