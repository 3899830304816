import React, { ChangeEvent, FormEvent, useRef, useState } from "react";
import "./ConnectWithUs.scss";
import Button from "../../components/_common/Button/Button";
import LogInWrapper from "../../components/logIn/LogInWrapper";
import Input from "../../components/_common/Input/Input";
import emailjs from "emailjs-com";
import Modal from "../../components/_common/Modal/Modal";
import { useHistory } from "react-router-dom";
import { Routes } from "../../configs/routes/routes";

function ConnectWithUs() {
  const [formStateData, setFormStateData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();

  const disable =
    formStateData.name.length > 0 &&
    formStateData.email.length > 0 &&
    formStateData.message.length > 0;

  const form = useRef<HTMLFormElement>(null);

  function sendEmail(e: FormEvent) {
    e.preventDefault();
    history.push(Routes.CONNECT_FEED_BACK)
    if (form.current) {
      emailjs
        .sendForm(
          "service_4ivlpbm",
          "template_8j8fdlr",
          form.current,
          "user_PnFyIrhkELXsJx9Jww2PE"
        )
        .then(
          (result) => {
            setFormStateData({
              name: "",
              email: "",
              message: "",
            });

            setShowModal(true);
          },
          (error) => {
            console.log(error.text);
          }
        );
    }
  }

  function handleChange(
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    type: string
  ) {
    setFormStateData({ ...formStateData, [type]: e.target.value });
  }
  // @ts-ignore
  return (
    <div className="connect_with_us">
      {/*{showModal && (*/}
      {/*  <Modal clickHandlerClose={() => history.push(Routes.HOME)}>*/}
      {/*    <p>Your message was sent</p>*/}
      {/*  </Modal>*/}
      {/*)}*/}
      <LogInWrapper footerConnect>
        <h1 className={"connectWithUs_title"}>Connect with us online</h1>
        <p className={"connectWithUs_text"}>
          Have any questions? We'd love to hear from you
        </p>
        <form
          className="content_form"
          action=""
          ref={form}
          onSubmit={(e) => sendEmail(e)}
        >
          <Input
            type={"text"}
            value={formStateData.name}
            name={"name"}
            placeholder={"Name"}
            onChange={(e) => handleChange(e, "name")}
          />
          <Input
            type={"Email"}
            name={"email"}
            value={formStateData.email}
            placeholder={"Email"}
            onChange={(e) => handleChange(e, "email")}
          />
          <textarea
            className="textarea"
            name={"message"}
            value={formStateData.message}
            placeholder="Message"
            onChange={(e) => handleChange(e, "message")}
          />
          <Button
            className={"primary_button_green"}
            content={"Submit"}
            type={"submit"}
            disabled={!disable}
          />
        </form>
      </LogInWrapper>
    </div>
  );
}

export default ConnectWithUs;
