import React, {useEffect, useState} from 'react';
import Input from "../../_common/Input/Input";
import Button from "../../_common/Button/Button";

import {useSelector} from "react-redux";
import {store} from "../../../types/store";
import useAccount from "../../../hooks/useAccount";

interface Props {
    editAddressHandler: () => void;
    addressOne: any;
    setAddressOne:any;
}

function UpdateAddress({editAddressHandler,addressOne, setAddressOne}: Props) {
    const {accountData} = useSelector((state: store) => state.account)
    const {uid, line1, line2, country, town, post_code} = accountData
    // const [addressOne, setAddressOne] = useState({
    //     line1: line1,
    //     line2: line2,
    //     town: town,
    //     postcode: post_code,
    //     primary: true,
    //     path: `users/${uid}/userData/address`, fullName: accountData.firstName
    // })
    const {sendAddress,getUserAddress} = useAccount()

    const addressOneHandler = (value: string, name: string) => {
        setAddressOne({
            ...addressOne, [name]: value,
        })
    }
    const updateAddressHandler = async () => {
        await sendAddress(uid || '', addressOne, accountData.address_id, false)
        if(uid){
            await getUserAddress(uid)
        }
        editAddressHandler()
    }
    const itemAddress = [
        {name: 'line1', value: addressOne.line1},
        {name: 'line2', value: addressOne.line2},
        {name: 'town', value: addressOne.town},
        {name: 'postcode', value: addressOne.postcode}]

    const renderItemOne = () => {
        return (
            itemAddress.map((item, index) => {
                if (index <= 1) {
                    return (
                        <div key={index}>
                            <Input
                                type={'text'}
                                placeholder={item.name}
                                name={item.name}
                                value={item.value}
                                onChange={
                                    (e) => {
                                        addressOneHandler(e.currentTarget.value, e.currentTarget.name)
                                    }}/>
                        </div>
                    )
                }
            }))
    }
    const renderItemTwo = () => {
        return (
            itemAddress.map((item, index) => {
                if (index > 1) {
                    return (
                        <div key={index}>
                            <Input
                                type={'text'}
                                placeholder={item.name}
                                name={item.name}
                                value={item.value}
                                onChange={
                                    (e) => {
                                        addressOneHandler(e.currentTarget.value, e.currentTarget.name)
                                    }}/>
                        </div>
                    )
                }
            }))
    }
    return (
        <>
            <div className={'name_input_wrapper'}>
                {renderItemOne()}
            </div>
            <div className={'name_input_wrapper_two'}>
                {renderItemTwo()}
            </div>
            <div className={'name_button_wrapper'}>
                <Button content={'Done'} clickHandler={updateAddressHandler}
                        className={'primary_button_green'}/>
                <Button content={'Cancel'} clickHandler={editAddressHandler} className={'secondary_button_gray'}/>
            </div>
        </>
    );
}

export default UpdateAddress;
