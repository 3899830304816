import { actionInterface } from "../../types/common";
import { PRODUCT_ACTION_TYPES } from "../action-types";

export const productReducer = (state: any = {}, action: actionInterface) => {
  switch (action.type) {
    case PRODUCT_ACTION_TYPES.GET_PRODUCT: {
      return action.data;
    }
    case PRODUCT_ACTION_TYPES.USER_LOGOUT: {
      return {};
    }
    default: {
      return state;
    }
  }
};
