import React from "react";
import "./FAQItem.scss";
import icon from "../../assets/static/FAQ_img/Vector.png";

interface Props {
  item: {
    h2: string;
    p: string[];
  };
  onClick: any;
  active: boolean;
}

function FAQItem(props: Props) {
  const { onClick, active, item } = props;

  const styleDivNone = active ? { display: "block" } : { display: "none" };
  const styleIconChange = active
    ? "faq_container_icon_rotate"
    : "faq_container_icon";

  return (
    <div className={"faq_item"}>
      <div className={"faq_item_container"} onClick={onClick}>
        <h2 className={"faq_item_h2"}>{item.h2}</h2>
        <img src={icon} className={styleIconChange} alt={"icon"} />
      </div>
      <div style={styleDivNone}>
        {item.p.map((item: string, index: number) => (
          <p key={index} className={"faq_item_p"}>
            {item}
          </p>
        ))}
      </div>
    </div>
  );
}

export default FAQItem;
