import React from "react";
import { useHistory } from "react-router-dom";
import imgOne from "../../../assets/static/homePageImages/updateIMG/Question.png";
import imgSecond from "../../../assets/static/homePageImages/updateIMG/Coach Advice 4.png";
import Button from "../../_common/Button/Button";
import reply from '../../../assets/static/png/Image.png'
interface Props {
  clickBtnHandler: any;
}
function SectionFive({ clickBtnHandler }: Props) {
  const history = useHistory();
  return (
    <section id={"discover_anchor"} className="discover">
      <div className="discover-container">
        <div className="discover-content">
          <div className={"discover_leftcontent_wrapper"}>
            <div>
              <h2 className="discover-content_title">
                Guidance from prenatal experts that's unique to you
              </h2>
              <p className="discover-content_text">
                Get the wellbeing advice you can trust from our team of prenatal
                experts via one-to-one instant messaging in the Bianu app.
              </p>
            </div>
            <img className={'mobile_reply'} src={reply} alt="mobile_reply"/>
            <div className={"section_five_img_wrapper"}>
              <img src={imgOne} alt="" />
            </div>
          </div>
          {/*<video*/}
          {/*  id="discover-video"*/}
          {/*  src={bubbleVideo}*/}
          {/*  autoPlay*/}
          {/*  loop*/}
          {/*  muted*/}
          {/*  poster={imgOne}*/}
          {/*/>*/}
          <div>
            <Button
              content={"Start pregnancy quiz"}
              clickHandler={clickBtnHandler}
              className={"primary_button_orange"}
            />
          </div>
        </div>
        <div className="discover-img">
          <img src={imgSecond} alt="" />
        </div>
      </div>
    </section>
  );
}

export default SectionFive;
