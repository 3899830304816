import { useDispatch, useSelector } from "react-redux";
import {collection, getDocs, setDoc, doc, Timestamp} from "firebase/firestore";
import { db } from "../configs/firebase";
import {
  actionSetArticles,
  actionSetQuizzes,
  actionSetTagging,
} from "../store/actions/quiz-actions";
import LogIn from "../pages/LogIn/LogIn";

function useQuizzes() {
  const dispatch = useDispatch();
  const loadQuizzes = async () => {
    const qs = await getDocs(
      collection(db, "bianu_1.00", "en_uk", "habitScenarios")
    );
    let forDispatch: any[] = [];
    qs.docs.forEach((doc) => (forDispatch = forDispatch.concat(doc.data())));
    dispatch(actionSetQuizzes(forDispatch));
  };
  const sendQuiz = async (uid: string, addedData: any) => {
    // (addedData, "addedData");
    // const supQuizRef = doc(db, "users", uid, "userData", "supplementQuiz");
    // const tagsRef = doc(db, "users", uid, "userData", "userTags");
    // const userRef = doc(db, "users", uid);
    // await setDoc(supQuizRef, addedData.supplementQuiz, { merge: true });
    // await setDoc(
    //   userRef,
    //   {
    //     id: uid,
    //     firstName: addedData.supplementQuiz.firstName,
    //     lastName: addedData.supplementQuiz.lastName,
    //     email: addedData.supplementQuiz.email,
    //     subscribeToNewsLetter: false,
    //     createdAt: addedData.supplementQuiz.createdAt,
    //     updatedAt: addedData.supplementQuiz.createdAt,
    //     path: addedData.supplementQuiz.path,
    //     startedFreeTrialDate: addedData.supplementQuiz.createdAt,
    //     hasCompletedMaternalOnboarding: true,
    //   },
    //   { merge: true }
    // );
    // await setDoc(
    //   doc(db, "users", uid, "userData", "onboarding"),
    //   {
    //     id: "onboarding",
    //     path: `users/${uid}/userData/onboarding`,
    //     userId: uid,
    //     completedMaternalOnboarding: true,
    //     isPregnant: true,
    //     expectedDeliveryDate,
    //   },
    //   { merge: true }
    // );
    // await addDoc(collection(db, "users", uid, "timeline"), {
    //   userId: uid,
    //   contentType: "supplementQuiz",
    //   contentId: "supplement_quiz",
    //   event: "details_updated",
    //   context: "supplementQuiz",
    //   tags: null,
    //   bag: {
    //     isPregnant: true,
    //     expectedDeliveryDate,
    //   },
    // });
    // const docRef = await addDoc(collection(db, "users", uid, "trophies"), {
    //   appName: "BiaNu",
    //   attainmentDate: addedData.supplementQuiz.createdAt,
    //   level: 0,
    //   progress: 1,
    //   trophyId: "superstart",
    //   id: "",
    //   updatedAt: addedData.supplementQuiz.updatedAt,
    //   userId: uid,
    //   path: "",
    // });
    // const trophyId = docRef.id;
    // await setDoc(
    //   doc(db, "users", uid, "trophies", trophyId),
    //   {
    //     id: trophyId,
    //     path: `users/${uid}/trophies/${trophyId}`,
    //   },
    //   { merge: true }
    // );
    // await setDoc(tagsRef, addedData.userTags, { merge: true });
    // if (addedData.userTags.tagList.length > 1) {
    //   const tags = addedData.userTags.tagList;
    //   // const dataToSet = articles.filter(
    //   //   (art: any) =>
    //   //     art.goodForTags.some((r: any) => tags.includes(r)) &&
    //   //     !art.avoidIfTags.some((r: any) => tags.includes(r)) &&
    //   //     tagging[1].tags[art]
    //   // );
    //   const filteredTags = tags.filter((tag: any) => {
    //     return tagging[1].tags[tag].flags.includes("advice");
    //   });
    //   const dataToSet = articles.filter(
    //     (art: any) =>
    //       art.goodForTags.some((r: any) => filteredTags.includes(r)) &&
    //       !art.avoidIfTags.some((r: any) => filteredTags.includes(r)) &&
    //       art.goodForTags.some((r: any) => r.includes("advice"))
    //   );
    //   await dataToSet.forEach((art: any) =>
    //     setDoc(
    //       doc(db, "users", uid, "userData", "favourites", "articles", art.id),
    //       {
    //         articleId: art.id,
    //         createdAt: new Date(),
    //         id: art.id,
    //         path: `users/${uid}/userData/favourites/articles/${art.id}`,
    //         updatedAt: new Date(),
    //         userId: uid,
    //       },
    //       {
    //         merge: true,
    //       }
    //     )
    //   );
    // }
    await setDoc(
      doc(db, "users", uid, "userData", "supplementQuiz"),
      addedData
    );
    await setDoc(
      doc(db, "users", uid),
      {
        id:uid,
        path: `users/${uid}`,
        hasStartedQuiz: true,
        hasCompletedQuizPart1: true,
        createdAt: Timestamp.now(),
      },
      { merge: true }
    );
    await setDoc(
      doc(db, "users", uid, "userData", "supplementQuiz"),
      {
        id: "supplementQuiz",
        path: `users/${uid}/userData/supplementQuiz`,
        userId: uid,
      },
      { merge: true }
    );
  };
  const loadArticles = async () => {
    const qs = await getDocs(collection(db, "bianu_1.00", "en_uk", "articles"));
    let forDispatch: any[] = [];
    qs.docs.forEach((doc) => (forDispatch = forDispatch.concat(doc.data())));
    dispatch(actionSetArticles(forDispatch));
  };
  const loadTagging = async () => {
    const qs = await getDocs(collection(db, "bianu_1.00", "en_uk", "tagging"));
    let forDispatch: any[] = [];
    qs.docs.forEach((doc) => (forDispatch = forDispatch.concat(doc.data())));
    dispatch(actionSetTagging(forDispatch));
  };
  return { loadQuizzes, sendQuiz, loadArticles, loadTagging };
}

export default useQuizzes;
