import React, { useEffect, useState } from "react";
import "./HeaderMain.scss";
import logo from "../../../assets/static/svg/newBianuLogo.svg";
import cart from "../../../assets/static/svg/shopping-bag.svg";
import cartActive from "../../../assets/static/svg/shopping-bagActive.svg";
import user from "../../../assets/static/svg/user.svg";
import activeUser from "../../../assets/static/svg/activeUser.svg";
import icon from "../../../assets/static/icon/Icons.png";
import { useHistory } from "react-router-dom";
import Button from "../Button/Button";
import { Routes } from "../../../configs/routes/routes";
import Cart from "../../Cart/Cart";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../../configs/firebase";
import { useSelector } from "react-redux";
import { store } from "../../../types/store";
import { LocalStorage } from "../../../services/storage";
import { COMPLETE_PAY } from "../../../constants/namespaces";
import ReactPixel from "react-facebook-pixel";

function HeaderMain({ menuBtnHandler }: any) {
  const history = useHistory();
  const [openCart, setOpenCart] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [authd, setAuthd] = useState(false);
  const { orderData } = useSelector((state: store) => state.account);
  const completedPay = LocalStorage.get(COMPLETE_PAY);
  // const listenerSubscription = Object.keys(orderData).length !== 0;

  const clickHandler = () => {
    menuBtnHandler();
    setMenuVisible(!menuVisible);
  };

  useEffect(() => {
    const listener = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthd(true);
      } else {
        setAuthd(false);
      }
    });
    return () => listener();
  }, []);
  return (
    <>
      <div
        className="header_main"
        style={
          menuVisible
            ? { position: "fixed", padding: "20px" }
            : { position: "relative" }
        }
      >
        <div className={"headerContainer"}>
          <div className={"header_main_left"}>
            <img
              onClick={() => {
                history.push(Routes.HOME);
              }}
              src={logo}
            />
            <p
              onClick={() => {
                history.push(Routes.TRUST);
              }}
              className={"header_main_trust_link"}
            >
              Why trust us?
            </p>
          </div>
          <div className={"header_main_right"}>
            <div className={"header_main_right_first_icon_wrapper"}>
              <img
                onClick={() => {
                  if (authd && !auth?.currentUser?.isAnonymous) {
                    history.push(Routes.ACCOUNT);
                  } else {
                    history.push(Routes.LOGIN);
                  }
                }}
                src={
                  history.location.pathname === "/account" ? activeUser : user
                }
                className={"mobile_icon_1"}
              />
              <div className={"header_main_cart_wrapper"}>
                <img
                  onClick={
                    authd
                      ? () => {
                          if (!openCart) {
                            //@ts-ignore
                            window.cqet("send", "CustomEvent", {
                              eventName: "Cart updated",
                            });
                            ReactPixel.trackCustom("Cart updated")
                          }
                          setOpenCart(!openCart);
                        }
                      : () => {}
                  }
                  src={openCart ? cartActive : cart}
                  className={"mobile_icon_2"}
                />
                {openCart && (
                  <div className={"cart_header_wrapper"}>
                    <Cart />
                  </div>
                )}
                {completedPay === "true" && <span />}
              </div>
            </div>
            <img
              src={icon}
              className={"mobile_icon_3"}
              onClick={clickHandler}
            />
          </div>
        </div>
      </div>
      {menuVisible && (
        <div className="header-mobile">
          <div className="header_dropDown-links">
            <a
              onClick={() => history.push(Routes.TRUST)}
              className="header_dropDown-link"
              href="#"
            >
              Why trust us?
            </a>
            <a
              onClick={() => history.push(Routes.LOGIN)}
              className="header_dropDown-link"
              href="#"
            >
              Log In
            </a>
          </div>
          <Button
            content={"Start pregnancy quiz"}
            className={"primary_button_orange"}
            clickHandler={() => {
              //@ts-ignore
              window.cqet("send", "CustomEvent", {
                eventName: "Start quiz button clicked on home page",
              });
              ReactPixel.trackCustom("Start quiz button clicked on home page")
              history.push(Routes.QUIZ);
            }}
          />
        </div>
      )}
    </>
  );
}

export default HeaderMain;
