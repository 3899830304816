import React from 'react';
import './Modal.scss';
import {Svg} from "../Svg/Svg";
import {SVG} from "../../../constants/svg";

interface Props {
  clickHandlerClose?: ()=>void;
  children: any;
  title?: string;
}

function Modal({clickHandlerClose, children, title}: Props) {
  return (
    <div className={'modal'}>
     <div className={'modal_body'}>
       <div className={'close_button_wrapper'}>
         <div className={'modal_head_title'}>{title && title}</div>
         <div className={'icon_wrap'} onClick={clickHandlerClose}><Svg icon={SVG.CLOSE} size={25}/></div>
       </div>
       <div className={'modal_content'}>
         {children}
       </div>
     </div>
    </div>
  );
}

export default Modal;
