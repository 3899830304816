import React, { ChangeEvent, forwardRef } from "react";
import "./Input.scss";

interface Props {
  type: string;
  min?: number;
  max?: number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  value?: string | number;
  className?: string;
  name?: string;
  pattern?: any;
  required?: boolean;
  label?: string;
  id?: string;
  checked?: boolean;
  autoFocus?: boolean;
  title?: string;
  onSelect?: any;
  autoComplete?: string;
  disabled?: boolean;
  formikProps?: any;
  onBlur?: any;
}

const Input = forwardRef<HTMLInputElement, Props>((props: Props, ref) => {
  const {
    type,
    min,
    max,
    onChange,
    placeholder,
    value,
    className,
    name,
    pattern,
    required,
    label,
    autoFocus,
    title,
    checked,
    onSelect,
    autoComplete,
    disabled,
    onBlur,
  } = props;
  return (
    <>
      <input
        type={type}
        minLength={min}
        maxLength={max}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        className={className ? `${className} custom-input` : "custom-input"}
        name={name}
        pattern={pattern}
        required={required}
        checked={checked}
        autoFocus={autoFocus}
        title={title}
        onSelect={onSelect}
        autoComplete={autoComplete}
        disabled={disabled}
        onBlur={onBlur}
      />
      {label && <label htmlFor={name}>{label}</label>}
    </>
  );
});

export default Input;
