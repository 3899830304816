import React, {ReactChild, useEffect} from "react";
import './TermsModal.scss';
import '../Terms/Terms.scss'
import closeIcon from '../../assets/static/svg/close_back.svg'
import {TermsData} from "../../types/checkout";
import Terms from "../Terms/Terms";
import TermsText from "../Terms/TermsText";

interface Props {
  component: ReactChild;
  setTerms: (prev: TermsData) => void;
  terms: TermsData;
}

function TermsModal({component, setTerms, terms}: Props) {
  useEffect(() => {
    if (terms.show) {
      document.body.style.overflow = "hidden"
    }
    return () => {
       document.body.style.overflow = "scroll"
    }
  }, [terms.show])
  return (
    <div className={'modal_terms'}>
      <div className={'modal_terms_content'}>
        <img className={'close_modal_terms'} onClick={() => setTerms({...terms, show: false})} src={closeIcon}
             alt="close"/>
        <div className={'modal_terms_wrapper'}>
          {component}
        </div>

      </div>
    </div>
  )
}

export default TermsModal;