import React, { ChangeEvent, useEffect, useState } from "react";
import Modal from "../../../_common/Modal/Modal";
import Button from "../../../_common/Button/Button";
import { Subscription } from "../../../../types/subscription";
import { useDispatch, useSelector } from "react-redux";
import useSubscription from "../../../../hooks/useSubscription";
import { SVG } from "../../../../constants/svg";
import { currentSubscriptionAction } from "../../../../store/actions/currentSubscription-action";
import { defaultSubscriptionState } from "../../../../constants/defaultData";
import { LocalStorage } from "../../../../services/storage";
import {
  checkTrimester,
  subscriptionDate,
} from "../../../../utils/UtilsCommon";
import useStripeHook from "../../../../hooks/useStripeHook";
import Loader from "../../../_common/Loader/Loader";

interface Props {
  clickHandlerButton?: () => void;
  clickHandlerClose?: () => void;
  currentSubscription: any;
  orderData: any;
}

function ModalModifyOne({
  clickHandlerButton,
  currentSubscription,
  orderData,
  clickHandlerClose,

}: Props) {
  const dispatch = useDispatch();
  const [activeSub, setActiveSub] = useState(defaultSubscriptionState);
  const { title, currencySymbol, amount } = currentSubscription;
  const {
    productId,
    currentPeriodEnd,
    stripeSubscriptionId,
    defaultPaymentMethod,
  } = orderData;
  const localStorageSubId = LocalStorage.get("SubscriptionId");
  const { loadSubscription } = useSubscription();
  const { supplements } = useSelector((state: any) => state.rec);
  const { createSubscriptionHandler, updateSubscriptionHandler } =
    useStripeHook();
  const [isLoading, setIsLoading] = useState(false);
  const subscriptions = useSelector((state: any) => state.subscription);
  const [active, setActive] = useState("");
  const updateSubscription = async () => {
    await updateSubscriptionHandler({
      subscriptionId: stripeSubscriptionId,
      paymentMethodId: defaultPaymentMethod,
      productId: productId,
      cancelAtPeriodEnd: true,
    });
    await createSubscriptionHandler({
      priceId: localStorageSubId || currentSubscription.stripePriceId,
      productId: productId,
      rootPath: "bianu_1.00/en_uk",
      paymentMethodId: defaultPaymentMethod,
    });
    // clickHandlerButton
    // history.push(Routes.ACCOUNT)
  };
  const changeHandler = (id: string) => {
    setActive(id);
    LocalStorage.set("SubscriptionId", `${id}`);
    const currentSub = subscriptions.filter(
      (item: Subscription) => item.id === id
    );
    setActiveSub(currentSub);
    const { amount, title } = currentSub[0];
    LocalStorage.set("SubscriptionPrice", `${amount / 100}`);
    LocalStorage.set("SubscriptionTitle", `${title}`);
  };
  const handlerButton = () => {
    dispatch(currentSubscriptionAction(activeSub));
    setIsLoading(true);
    updateSubscription().then(() => {
      loadSubscription().then(() => {
        setIsLoading(false);
        if (clickHandlerButton) {
          clickHandlerButton();
        }
      });
    });
  };
  const renderSubItem = () =>
    subscriptions.map((item: Subscription, index: number) => {
      return item.id !== "prc0001" ? (
        <div
          className={"subscription_item"}
          onClick={(e: any) => changeHandler(e.currentTarget.id)}
          id={item.id}
          key={index}
        >
          <div className={"subscription_option"}>
            <div className={"sub_container"}>
              <div className="round_monthly">
                <input
                  onChange={() => {}}
                  type="checkbox"
                  checked={item.id === active}
                />
                <label htmlFor={item.id} />
              </div>
              <p>{item.title}</p>
              {item.profitText ? (
                <div className={"best_price_sub"}>{item.profitText}</div>
              ) : null}
            </div>
            <div className={"sub_price"}>
              <span className={"new_price"}>{`${item.amount / 100}${
                item.currencySymbol
              }`}</span>
            </div>
          </div>
          <p className={"subscription_description"}>{item.description}</p>
        </div>
      ) : null;
    });
  useEffect(() => {
    loadSubscription().then(() => setIsLoading(false));
  }, []);

  return (
    <Modal title={"Modify subscription"} clickHandlerClose={clickHandlerClose}>
      {isLoading ? (
        <img className={"loader_icon"} src={SVG.LOADER_ICON} alt="loader" />
      ) : (
        <div>
          <div className={"order_subscription"}>
            <ul className={"order_account_top_wrapper_right_order_list"}>
              <li className={"first_item"}>
                ✔
                {supplements &&
                  supplements.map((item: any) => {
                    if (item.id === orderData.productId) {
                      return item.name;
                    }
                  })}
              </li>
              <li>✔ 30 sachets per pack</li>
              <li>✔ Access to 1:1 coaching</li>
              <li>✔ Nutrition & wellness programme</li>
              <li>✔ Cancel subscription any time</li>
            </ul>
          </div>
          <div className={"current_subscription"}>
            <div className={"choose_subscription_left"}>
              <span>
                Next payment - {subscriptionDate(currentPeriodEnd.seconds)}
              </span>
              <p>{title}</p>
            </div>
            <div className={"choose_subscription_right"}>
              <div>Subscribed</div>
              <p>
                {currencySymbol}
                {amount / 100}
              </p>
            </div>
          </div>
          <p className={"other_options_title"}>Other available options</p>
          {renderSubItem()}
          {isLoading ? (
            <div className={"loader_modal-Wrapper"}>
              <Loader />
            </div>
          ) : (
            <Button
              content={"Update"}
              clickHandler={handlerButton}
              className={"primary_button_green"}
              disabled={!active}
            />
          )}
        </div>
      )}
    </Modal>
  );
}

export default ModalModifyOne;
