import React, {useEffect, useState} from "react";
import "./Payment.scss";
import AddPayment from "./addPayment/AddPayment";
import PaymentItem from "./paymentItem/PaymentItem";
import {useSelector} from "react-redux";
import {store} from "../../../types/store";
import useStripeHook from "../../../hooks/useStripeHook";
import {SVG} from "../../../constants/svg";
import useAccount from "../../../hooks/useAccount";

function Payment() {
    const [addPayment, setPayment] = useState(false);
    const [load, setLoad] = useState(false);
    const {paymentMethod, defaultPaymentMethod, accountData} = useSelector(
        (state: store) => state.account
    );
    const [checked, setChecked] = useState([{id: "", isDefault: false}]);
    useEffect(() => {
        setChecked(
            paymentMethod.map((item: any) => {
                return {
                    id: item.id,
                    isDefault: item.id === defaultPaymentMethod,
                };
            })
        );
    }, [paymentMethod, defaultPaymentMethod]);
    const {getUserCards} = useStripeHook();
    const addPaymentHandler = async () => {
        setPayment(!addPayment);
    };
    const loadingHandler = (value: boolean) => {
        setLoad(value);
    };
    const {loadOrderData} = useAccount();
    useEffect(() => {
        if (accountData.uid) {
            loadOrderData(accountData.uid || "");
        }
        getUserCards().then(() => {
            setLoad(true);
        });
    }, []);
    const renderPaymentItem = () => {
        return paymentMethod.map((item: any, index: number) => {
            return (
                <PaymentItem
                    checkedStatus={item.id === checked.find((item) => item.isDefault)?.id}
                    key={index}
                    item={item}
                />
            );
        });
    };
    return (
        <div className="payment_account">
            <h2 className="payment_account_title">Payment details</h2>
            {load ? (
                renderPaymentItem()
            ) : (
                <div className={"loader_wrapper"}>
                    <img className={"loader_icon"} src={SVG.LOADER_ICON} alt="loader"/>
                </div>
            )}
            {addPayment ? (
                <AddPayment
                    payment={addPayment}
                    setLoading={loadingHandler}
                    addPayment={addPaymentHandler}
                />
            ) : (
                <div className={"payment_add"} onClick={addPaymentHandler}>
                    + Add new
                </div>
            )}
        </div>
    );
}

export default Payment;
