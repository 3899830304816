import {
  accountAction,
  accountActionTypes,
  accountState,
} from "../account-action-types";
import { Account } from "../../types/account";

const initialState: accountState = {
  accountData: {
    firstName: "",
    recommendedProduct: "",
    lastName: "",
    uid: "",
    anonymousUid: '',
    email: "",
    address_id: "",
    second_address_id: "",
    country: "",
    line1: "",
    line2: "",
    post_code: "",
    primary: true,
    town: "",
    phone: "",
    promoCodeId: "",
    secondAddress: {
      country: "",
      line1: "",
      line2: "",
      post_code: "",
      primary: true,
      town: "",
    },
  } as Account,
  lastAnswerQuiz:'',
  defaultPaymentMethod: "",
  keySTR: "",
  paymentMethod: [],
  orderData: {},
};

export const accountReducer = (
  state = initialState,
  action: accountAction
): accountState => {
  switch (action.type) {
    case accountActionTypes.GET_ANONYMOUS_UID:
      return { ...state,accountData:{...state.accountData, anonymousUid: action.data} };
    case accountActionTypes.GET_DATA:
      return {
        ...state,
        accountData: {
          ...state.accountData,
          email: action.data.email,
          uid: action.data.userId,
          phone: action.data.phone,
          recommendedProduct: action.data.recommendedProduct,
          chosenProduct: action.data.chosenProduct,
          promoCodeId: action.data.promoCodeId,
        },
      };
    case accountActionTypes.GET_DATA_QUIZ: {
      return {
        ...state,
        accountData: {
          ...state.accountData,
          hasCompletedQuizPart1: action.data.hasCompletedQuizPart1,
          hasStartedQuiz: action.data.hasStartedQuiz,
          hasCompletedSupplementQuiz: action.data.hasCompletedSupplementQuiz,
          firstName: action.data.firstName,
          lastName: action.data.lastName,
          dueDate: new Date(action.data.dueDate.seconds * 1000),
        },
      };
    }
    case accountActionTypes.GET_DATA_ADDRESS:
      const { country, line1, line2, post_code, primary, town, secondAddress } =
        action.data;
      return {
        ...state,
        accountData: {
          ...state.accountData,
          country: country,
          address_id: action.data.address_id,
          second_address_id: action.data.second_address_id,
          line1: line1,
          line2: line2,
          post_code: post_code,
          primary: primary,
          town: town,
          secondAddress: secondAddress,
        },
      };
    case accountActionTypes.GET_STR_KEY:
      return { ...state, keySTR: action.data };
    case accountActionTypes.GET_LAST_ANSWER:
      return { ...state, lastAnswerQuiz: action.data };
    case accountActionTypes.GET_PAY_METHOD:
      return { ...state, paymentMethod: action.data };
    case accountActionTypes.GET_ORDER_DATA:
      return { ...state, orderData: action.data };
    case accountActionTypes.GET_DEFAULT_PAY_METHOD:
      return { ...state, defaultPaymentMethod: action.data };
    case accountActionTypes.GET_SUBSCRIPTION_HISTORY:
      return {
        ...state,
        accountData: { ...state.accountData, subscriptionHistory: action.data },
      };
    case accountActionTypes.USER_LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
};
