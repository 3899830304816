import React from "react";
import chevron from "../../../../assets/static/icon/Vector.svg";

interface Props {
  clickHandler: (e?: any) => void;
  visible?: boolean;
  currentSubscription: any;
  currentIndex?: number;
  indexFromMap?: number;
}

function OrderCartTitle({
  clickHandler,
  indexFromMap,
  visible,
  currentSubscription,
  currentIndex,
}: Props) {
  const { title, amount, currencySymbol } = currentSubscription;
  // currentSubscription, "cur";
  const titleWithoutBrackets = title.replace(/[{}\s]/g, " ");
  return (
    <div className={"order_account_title_bottom"}>
      <div className={"order_account_title_bottom_left"}>
        <h3>
          {currentSubscription.title === "One-off purchase"
            ? "Monthly subscription"
            : titleWithoutBrackets}
        </h3>
        {/*<p>June 21, 2021 at 15:30</p>*/}
      </div>
      <div className={"order_account_title_bottom_right"}>
        <p>
          {currentSubscription && currencySymbol}
          {currentSubscription && amount / 100}
        </p>
        <img
          id={`${indexFromMap}`}
          onClick={clickHandler}
          className={visible ? "visible_menu" : ""}
          src={chevron}
        />
      </div>
    </div>
  );
}

export default OrderCartTitle;
