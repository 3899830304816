import React from "react";
import "./LogInWrapper.scss";
import logo from "../../assets/static/svg/newBianuLogo.svg";
import { useHistory } from "react-router-dom";
import { Routes } from "../../configs/routes/routes";

function LogInWrapper({ children, footer }: any) {
  const history = useHistory();
  return (
    <div className="login">
      <div className="login_wrapper">
        <div className="login_header">
          <div
            className={"login_header_button_back"}
            onClick={() => {
              history.location.pathname === "/login"
                ? history.push(Routes.HOME)
                : history.goBack();
            }}
          >
            Back
          </div>
          <img src={logo} onClick={() => history.push(Routes.HOME)} />
          <div className={"div"} />
        </div>
        <div className="login_body">
          <div className={"log_in_container"}>{children}</div>
        </div>
        {footer ? (
          <div className="login_footer">
            <div>Don’t have an account?</div>
            <div
              className={"log_in_create_account"}
              onClick={() => history.push(Routes.QUIZ)}
            >
              Create account
            </div>
          </div>
        ) : (
          <div />
        )}
      </div>
    </div>
  );
}

export default LogInWrapper;
