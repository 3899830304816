import React, {useState} from "react";
import Input from "../../components/_common/Input/Input";
import Button from "../../components/_common/Button/Button";
import LogInWrapper from "../../components/logIn/LogInWrapper";

function NewPassword() {
  const [password, setPassword] = useState({pass: '', confirm: ''})
  const {pass, confirm} = password
  const passwordHandler = (value: string, name: string) => {
    setPassword({...password, [name]: value})
  }
  return (
    <LogInWrapper>
      <h1>Create new password</h1>
      <p>Your new password must contain at least 8 characters.</p>
      <Input
        value={pass}
        type={'text'}
        placeholder={'Password'}
        name={'pass'}
        onChange={(e)=>{passwordHandler(e.currentTarget.value, e.currentTarget.name)}}
      />
      <Input
        value={confirm}
        type={'text'}
        placeholder={'Confirm Password'}
        name={'confirm'}
        onChange={(e)=>{passwordHandler(e.currentTarget.value, e.currentTarget.name)}}
      />
      <Button content={'Set new password'} className={'primary_button_green'}/>
    </LogInWrapper>
  );
}
export default NewPassword;



