import React, { useCallback, useEffect, useState } from "react";
import Input from "../../_common/Input/Input";
import Button from "../../_common/Button/Button";
import "./InputForm.scss";
import { inputStep } from "../../../types/quizTypes";
import { Link, useHistory } from "react-router-dom";
import { Formik, Form, Field, useFormik } from "formik";
import { quizValidation } from "../../../constants/validationSchemas";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../../configs/firebase";
import useAccount from "../../../hooks/useAccount";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { actionSetQuizNavCurrent } from "../../../store/actions/quiz-actions";
import { Routes } from "../../../configs/routes/routes";

interface Props {
  stepData: inputStep;
  submitHandler: (value: { firstName: string; lastName: string }) => void;
}

function InputForm(props: Props) {
  // const [value, setValue] = useState<{
  //   [key: string]: string;
  //   firstName: string;
  //   lastName: string;
  // }>({ firstName: "", lastName: "" });
  const { stepData, submitHandler } = props;
  // const handleInputChange = useCallback(
  //   (e) => {
  //     setValue({ ...value, [e.target.name]: e.target.value });
  //   },
  //   [value]
  // );
  const { accountData } = useSelector((state: RootState) => state.account);
  const { loadAccount } = useAccount();
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    const listener = onAuthStateChanged(auth, (user) => {
      if (user) {
        loadAccount(user.uid).then(() => {
          if (
            accountData.hasStartedQuiz &&
            !accountData.hasCompletedQuizPart1
          ) {
            dispatch(actionSetQuizNavCurrent("stp0003"));
          } else if (accountData.hasCompletedQuizPart1) {
            history.push(Routes.RECOMMENDATION);
          }
        });
      }
    });
    return () => listener();
  }, [accountData]);
  const formik: any = useFormik({
    initialValues: { firstName: ""/*, lastName: ""*/ },
    onSubmit: () => {
      submitHandler(formik.values);
    },
    validationSchema: quizValidation[stepData.id],
  });
  return (
    <form
      // onSubmit={(e) => {
      //   e.preventDefault();
      //   submitHandler(value);
      // }}
      onSubmit={formik.handleSubmit}
      className={"form-wrapper input-form"}
    >
      <h1>{stepData.header}</h1>
      {stepData.inputs.map((inp) => (
        <Input
          title={"Max. 20 Latin characters"}
          autoFocus={inp.name === "firstName"}
          // pattern={"[a-zA-Z-]{1,20}"}
          value={formik.values[inp.name]}
          // value={value[inp.name]}
          name={inp.name}
          id={inp.name}
          // onChange={(e) => handleInputChange(e)}
          onChange={formik.handleChange}
          type={"text"}
          className={
            formik.errors[inp.name] &&
            formik.touched[inp.name] &&
            "custom-input_error"
          }
          // required
          placeholder={inp.inputPlaceHolder}
          onBlur={formik.handleBlur}
        />
      ))}
      <Button
        // disabled={!value.firstName || !value.lastName}
        className={"primary_button_green"}
        content={stepData.btnText}
        type={"submit"}
      />
      {stepData.stepOptions?.bottomText && (
        <p className="">{stepData.stepOptions?.bottomText}</p>
      )}
      {stepData.stepOptions?.linkHref && stepData.stepOptions?.linkText && (
        <Link to={stepData.stepOptions?.linkHref}>
          {stepData.stepOptions?.linkText}
        </Link>
      )}
    </form>
  );
}

export default InputForm;
