import { actionInterface } from "../../types/common";
import { defaultNavState } from "../../constants/defaultData";
import { NAV_ACTION_TYPES } from "../action-types";
import { QuizNav } from "../../types/quizTypes";

export const quizNavReducer = (
  state: QuizNav = defaultNavState,
  action: actionInterface
) => {
  switch (action.type) {
    case NAV_ACTION_TYPES.SET_CURRENT_STEP: {
      return { ...state, currentStepId: action.data };
    }
    case NAV_ACTION_TYPES.SET_NEXT_STEP: {
      return { ...state, nextStepId: action.data };
    }
    case NAV_ACTION_TYPES.SET_PREVIOUS_STEP: {
      return { ...state, prevStepId: action.data };
    }
    case NAV_ACTION_TYPES.SET_NAV: {
      return action.data;
    }
    case NAV_ACTION_TYPES.SET_ALT: {
      return action.data;
    }
    case NAV_ACTION_TYPES.USER_LOGOUT: {
      return defaultNavState;
    }
    default:
      return state;
  }
};
