import { useDispatch } from "react-redux";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../configs/firebase";
import { actionSetSubscription } from "../store/actions/subscription-action";

function useSubscription() {
  const dispatch = useDispatch();
  const loadSubscription = async () => {
    const subscription = await getDocs(
      collection(db, "bianu_1.00", "en_uk", "prices")
    );
    let forDispatch: any[] = [];
    subscription.docs.forEach(
      (doc) => (forDispatch = forDispatch.concat(doc.data()))
    );
    // (forDispatch);
    dispatch(actionSetSubscription(forDispatch));
  };
  return { loadSubscription };
}

export default useSubscription;
