import React, { useState } from "react";
import "./FAQ.scss";
import HeaderMain from "../../components/_common/HeaderMain/HeaderMain";
import FAQItem from "../../components/FAQItem/FAQItem";
import { FAQItems } from "../../constants/FAQconstans";
import FooterMain from "../../components/_common/FooterMain/FooterMain";

function FAQ() {
  const [showInfo, setShowInfo] = useState(FAQItems.map(() => false));
  const showDetail = (itemIndex: number) => {
    if (showInfo[itemIndex]) {
      return setShowInfo(FAQItems.map(() => false));
    }
    setShowInfo(
      showInfo.map((item, index) => {
        return index === itemIndex;
      })
    );
  };

  return (
    <div className="faq_main">
      <HeaderMain />
      <div className={"faq_container"}>
        <h1 className={"faq_container_h1"}>FAQ</h1>
        {FAQItems.map((item, index) => (
          <FAQItem
            key={index}
            item={item}
            active={showInfo[index]}
            onClick={() => showDetail(index)}
          />
        ))}
      </div>
      <FooterMain  linksShown={true}/>
    </div>
  );
}

export default FAQ;
