import React, { useCallback, useEffect, useState } from "react";
import "./Cart.scss";
import { CartImg } from "../../constants/Cart";
import { useHistory } from "react-router-dom";
import { Routes } from "../../configs/routes/routes";
import Button from "../_common/Button/Button";
import { useSelector } from "react-redux";
import { useRecommendations } from "../../hooks/useRecommendations";
import { store } from "../../types/store";
import { LocalStorage } from "../../services/storage";
import { COMPLETE_PAY } from "../../constants/namespaces";
import { Subscription } from "../../types/subscription";
import {
  generateSubPrices,
  renderConfirmationText,
} from "../../utils/UtilsCommon";
import { RootState } from "../../store";
import ReactPixel from 'react-facebook-pixel';

function Cart() {
  const history = useHistory();
  const { loadSupplements } = useRecommendations();
  const { supplements, favorite } = useSelector((state: any) => state.rec);
  const { accountData } = useSelector((state: store) => state.account);
  const { dueDate } = useSelector(
    (state: RootState) => state.account.accountData
  );
  const subscriptions = useSelector((state: any) => state.subscription);
  const recommendedStorage = localStorage.getItem("productId");
  const completedPay = LocalStorage.get(COMPLETE_PAY);
  const localStorageSubId = LocalStorage.get("chosenPrice");
  const [calculated, setCalculated] = useState({
    savedAmount: 0,
    monthCount: 0,
    totalChargeMonthly: 0,
    totalChargePregnancy: 0,
    lastMonth: false,
  });

  const createSubscriptionList = useCallback(
    (subs) => {
      if (calculated.monthCount > 1) {
        const pregnancy =
          calculated.monthCount <= 3
            ? subs.filter(
                (item: Subscription) =>
                  item.interval === "day" &&
                  item.type === "one_time" &&
                  item.intervalCount === calculated.monthCount * 30
              )
            : subs.filter(
                (item: Subscription) =>
                  item.interval === "month" &&
                  item.intervalCount === calculated.monthCount
              );
        const monthly = subs.filter(
          (item: Subscription) =>
            item.interval === "day" && item.type === "recurring"
        );
        return [...pregnancy, ...monthly];
      }
      return subs.filter(
        (item: Subscription) =>
          item.interval === "day" && item.type === "one_time"
      );
    },
    [calculated.monthCount]
  );

  useEffect(() => {
    const subs = createSubscriptionList(subscriptions);
    if (dueDate && subs.length > 1) {
      setCalculated(
        generateSubPrices(dueDate, subs[0].amount / 100, subs[1].amount / 100)
      );
    }
  }, [createSubscriptionList, dueDate, subscriptions]);
  const [product, setProduct] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const RecommendedProduct = supplements.find((item: any) => {
    if (!recommendedStorage || recommendedStorage === "") {
      LocalStorage.set("productId", favorite);
      LocalStorage.set("recProduct", favorite);
      return item.id === favorite;
    }
    if (recommendedStorage !== "") {
      return item.id === recommendedStorage;
    }
  });
  const cartButtonHandler = () => {
    if (accountData.hasCompletedQuizPart1) {
      setIsLoading(true);
      history.push(Routes.SUBSCRIPTION);
    } else {
      //@ts-ignore
      window.cqet("send", "CustomEvent", {
        eventName: "Start quiz button clicked on home page",
      });
      ReactPixel.trackCustom("Start quiz button clicked on home page")
      setIsLoading(true);
      history.push(Routes.QUIZ);
    }
  };

  useEffect(() => {
    loadSupplements().then(() => {
      setProduct(RecommendedProduct);
      setIsLoading(false);
    });
  }, []);
  useEffect(() => {
    setProduct(RecommendedProduct);
  }, [RecommendedProduct]);
  // chosenSubscription, "prod";

  return (
    <div className="main_container">
      <div className="main_container_head">
        {completedPay === "false" ? (
          <p>There's nothing in your cart yet.</p>
        ) : (
          <p>
            {completedPay
              ? renderConfirmationText()
              : "There's nothing in your cart yet."}
          </p>
        )}
        {/*<img src={CartImg.delete} alt="delete" />*/}
      </div>
      {isLoading
        ? null
        : completedPay === "true" && (
            <div className="main_container_content">
              <>
                <p className="main_container_content_p">
                  Prepare for birth programme
                </p>
                <div className="main_container_content_item1">
                  <img
                    src={CartImg?.photo1 && product?.imagePath}
                    alt="supplement pack"
                  />
                  <p>{"Pregnancy Essentials Trimester 1" && product?.name}</p>
                </div>
                <div className="main_container_content_item2">
                  <img
                    className={"cart_icons_img"}
                    src={CartImg.photo2}
                    alt="mobile phone"
                  />
                  <p>Nutrition & wellness programme</p>
                </div>
                <div className="main_container_content_item3">
                  <img
                    className={"cart_icons_img"}
                    src={CartImg.photo3}
                    alt="chat"
                  />
                  <p>Access to 1:1 coaching</p>
                </div>
              </>
            </div>
          )}
      {completedPay ? (
        <div className="main_container_subtotal">
          {/*<div className="main_container_subtotal_item">*/}
          {/*  <p className="subtotal">Subtotal</p>*/}
          {/*  <div className="main_container_subtotal_item_price">*/}
          {/*    <p>27</p>*/}
          {/*    <p>£</p>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div>*/}
          {/*  <p className="total">Total price will be calculated at checkout</p>*/}
          {/*</div>*/}
        </div>
      ) : (
        <div className={"subscription_total"} />
      )}
      <div className={"cart_button_wrapper"}>
        <Button
          content={
            accountData.hasCompletedQuizPart1
              ? "Go to Checkout"
              : "Take pregnancy quiz"
          }
          clickHandler={cartButtonHandler}
          className={"primary_button_green"}
          disabled={isLoading}
        />
      </div>
    </div>
  );
}
export default Cart;
