import React from "react";
import {useHistory} from "react-router-dom";
import {Routes} from "../../configs/routes/routes";
import logo from "../../assets/static/icon/main_logo.svg";


function LandingFooter() {
  const history = useHistory();
  return (
    <div className="footer_main">
      <div className="container">
        <div className={"footer_main_left"}>
          <img
            onClick={() => {
              history.push(Routes.HOME);
            }}
            src={logo}
            alt={'main logo'}
          />
        </div>
        <div className={'footer_landing'}>
          <p onClick={() => history.push(Routes.TERMS)}>Terms & Conditions</p>
          <p onClick={() => history.push(Routes.PRIVACY_POLICY)}>Privacy Policy</p>
        </div>
        <div className={"footer_main_right"}>
          <div>© 2021 Salus Optima Technologies Limited</div>
        </div>
      </div>
    </div>
  );
}


export default LandingFooter;