import supplementImage0 from "./Supplement States.svg";
import supplementImage1 from "./Supplement States-1.svg";
import supplementImage2 from "./Supplement States-2.svg";
import supplementImage3 from "./Supplement States-3.svg";
import supplementImage4 from "./Supplement States-4.svg";
import supplementImage5 from "./Supplement States-5.svg";
import supplementImage5a from "./Supplement States 5a.svg";
import supplementImage5b from "./Supplement States 5b.svg";
import supplementImage6 from "./Supplement States-6.svg";
import supplementImage7 from "./Supplement States-7.svg";

export const supplementIngredients = {
  supplementImage0,
  supplementImage1,
  supplementImage2,
  supplementImage3,
  supplementImage4,
  supplementImage5,
  supplementImage5a,
  supplementImage5b,
  supplementImage6,
  supplementImage7,
};
