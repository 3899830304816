import {Field} from "formik";
import React from "react";
import {checkoutInputData} from "../../constants/defaultData";

interface Props {
    paymentShippingDataHandler: any;
}

function ShippingInputs({}:Props) {

    const renderShippingInputs = () =>
        checkoutInputData.map((item: any, index: number) => {
            if (item.shippingName === "shipping_line2") {
                return <Field
                    key={index}
                    type={"text"}
                    name={item.shippingName}
                    className={item.class_name}
                    placeholder={item.placeholder}
                    autoFocus={false}
                />
            } else {
                return (
                    <Field
                        key={index}
                        type={"text"}
                        required={true}
                        name={item.shippingName}
                        className={item.class_name}
                        placeholder={item.placeholder}
                        autoFocus={false}
                    />
                );
            }
        });

    return (
        <div className={"checkout-cardInfo_address"}>
            {renderShippingInputs()}
        </div>
    );
}

export default ShippingInputs;
