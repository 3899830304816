import React, {ChangeEvent, useCallback, useEffect, useState} from "react";
import "./SubscriptionScreen.scss";
import Button from "../../components/_common/Button/Button";
import Header from "../../components/_common/Header/Header";
import Footer from "../../components/_common/Footer/Footer";
import {useHistory} from "react-router-dom";
import {Routes} from "../../configs/routes/routes";
import useSubscription from "../../hooks/useSubscription";
import {useDispatch, useSelector} from "react-redux";
import {Subscription} from "../../types/subscription";
import {useRecommendations} from "../../hooks/useRecommendations";
import useAccount from "../../hooks/useAccount";
import {auth} from "../../configs/firebase";
import {LocalStorage} from "../../services/storage";
import {RootState} from "../../store";
import {generateSubPrices} from "../../utils/UtilsCommon";
import {COMPLETE_PAY, SELECTED_PLAN} from "../../constants/namespaces";
import closeIcon from "../../assets/static/icon/close.svg";
import Banner from "../../components/Banner/Banner";
import FooterMain from "../../components/_common/FooterMain/FooterMain";

interface Props {
  modalTitle?: string;
  closeModalSubScreen?: () => void;
  isModal?: boolean;
}

function SubscriptionScreen({
                              modalTitle,
                              closeModalSubScreen,
                              isModal,
                            }: Props) {
  const history = useHistory();
  const {loadSubscription} = useSubscription();
  const {setRecommendationsAgain} = useAccount();
  const subscriptions = useSelector((state: any) => state.subscription);
  const userName = localStorage.getItem('name')
  const [calculated, setCalculated] = useState({
    savedAmount: 0,
    monthCount: 0,
    totalChargeMonthly: 0,
    totalChargePregnancy: 0,
    lastMonth: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [active, setActive] = useState(LocalStorage.get(SELECTED_PLAN));
  const [chosenPrice, setChosenPrice] = useState("");

  useEffect(() => {
    if (active) {
      LocalStorage.set(SELECTED_PLAN, active);
    }
  }, [active]);
  useEffect(() => {
    LocalStorage.set(COMPLETE_PAY, "true");
  }, []);
  // const [activeSub, setActiveSub] = useState(defaultSubscriptionState);

  // const changeHandler = (id: string) => {
  //   setActive(id);
  //   // LocalStorage.set("SubscriptionId", `${id}`);
  //   // const currentSub = subscriptions.filter(
  //   //   (item: Subscription) => item.id === id
  //   // );
  //   // setActiveSub(currentSub);
  //   // const { amount, title } = currentSub[0];
  //   // LocalStorage.set("SubscriptionPrice", `${amount / 100}`);
  //   // LocalStorage.set("SubscriptionTitle", `${title}`);
  // };
  // const backLinkHandler = () => history.push(Routes.RECOMMENDATION);

  const subButtonHandler = () => {
    // dispatch(currentSubscriptionAction(activeSub));
    // setIsLoading(true);
    LocalStorage.set(
      "chosenPrice",
      calculated.lastMonth ? "prc0001" : chosenPrice
    );
    LocalStorage.set("monthCount", String(calculated.monthCount));
    history.push(Routes.CHECKOUT);
  };

  const modalCheckoutHandler = () => {
    LocalStorage.set(
      "chosenPrice",
      calculated.lastMonth ? "prc0001" : chosenPrice
    );
    LocalStorage.set("monthCount", String(calculated.monthCount));
    if (closeModalSubScreen) {
      closeModalSubScreen();
    }
  };
  const renderSubItem = (list: Subscription[]) => {
    return !calculated.lastMonth ? (
      list.map((item: Subscription, index: number) => {
        const isPregnancy = item.planType === "pregnancy";
        return (
          <div
            className={
              item.planType === active ? "currentBig" : "subscription_itemBig"
            }
            onClick={(e: any) => {
              setActive(item?.planType || "");
              setChosenPrice(item.id);
            }}
            id={item.id}
            key={index}
          >
            {item.planType === active ? (
              isPregnancy ? (
                <div className={"subscription_offer"}>Best value</div>
              ) : (
                <div className={"subscription_offer-green"}>Selected plan</div>
              )
            ) : (
              isPregnancy && (
                <div className={"subscription_offer"}>Best value</div>
              )
            )}
            {index === 0 && !calculated.lastMonth && !isModal && (
              <div className={"subscription_promo"}>
                <div className={"subscription_promo-inner"}>
                  <span>
                    Got a promo code? Add at checkout to save an additional 15%
                  </span>
                </div>
                <span>Save</span>
                <span>£{calculated.savedAmount}.00</span>
              </div>
            )}
            <div
              className={"subscription_option"}
              onClick={() => LocalStorage.set(COMPLETE_PAY, "true")}
            >
              <div>
                {isPregnancy ? (
                  <p>{`Pregnancy Plan - billed at £${item.amount / 100} (${
                    calculated.monthCount
                  } month plan)`}</p>
                ) : (
                  <p>Monthly Plan</p>
                )}
                <div className={"sub_price"}>
                  <span className={"new_price"}>{`£${
                    isPregnancy
                      ? (item.amount / 100 / calculated.monthCount).toFixed(2)
                      : item.amount / 100
                  }/ month`}</span>
                </div>
                <p className={"subscription_description"}>
                  30-day money back guarantee included*
                </p>
              </div>
              <div className="round_monthly">
                <input
                  onChange={() => {
                  }}
                  type="checkbox"
                  checked={item.planType === active}
                />
                <label htmlFor={item.id}/>
              </div>
            </div>
          </div>
        );
      })
    ) : (
      <div
        className={"currentBig"}
        onClick={(e: any) => {
          setActive("monthly");
          setChosenPrice("prc0002");
        }}
        // id={item.id}
        // key={index}
      >
        <div className={"subscription_option"}>
          <div>
            <p>Single Month Plan</p>
            <div className={"sub_price"}>
              <span className={"new_price"}>{`£${4499 / 100}/ month`}</span>
            </div>
            <p className={"subscription_description"}>
              30-day money back guarantee included**
            </p>
          </div>
          <div className="round_monthly">
            <input onChange={() => {
            }} type="checkbox" checked={true}/>
            <label/>
          </div>
        </div>
      </div>
    );
  };
  const {loadAccount} = useAccount();

  const {firstName, dueDate} = useSelector(
    (state: RootState) => state.account.accountData
  );

  useEffect(
    () => {
      // setIsLoading(true);

      const listener = auth.onAuthStateChanged((user) => {
        // if (dueDate) {
        //   console.log(generateSubPrices(dueDate));
        // }
        if (user !== null) {
          // loadSubscription();
          setRecommendationsAgain(user.uid);
          // console.log(1);
          loadAccount(user.uid);
          loadSubscription();
          setIsLoading(false);
          // loadSupplements().then(() => setIsLoading(false));
        } else {
          history.push(Routes.HOME);
        }
      });
      return () => listener();
    },
    [
      // history,
      // loadAccount,
      // loadSubscription,
      // loadSupplements,
      // setRecommendationsAgain,
    ]
  );

  const createSubscriptionList = useCallback(
    (subs) => {
      if (calculated.monthCount > 1) {
        const pregnancy =
          calculated.monthCount <= 3
            ? subs.filter(
              (item: Subscription) =>
                item.interval === "day" &&
                item.type === "one_time" &&
                item.intervalCount === calculated.monthCount * 30
            )
            : subs.filter(
              (item: Subscription) =>
                item.interval === "month" &&
                item.intervalCount === calculated.monthCount
            );
        const monthly = subs.filter(
          (item: Subscription) =>
            item.interval === "day" && item.type === "recurring"
        );
        return [...pregnancy, ...monthly];
      }
      return subs.filter(
        (item: Subscription) =>
          item.interval === "day" && item.type === "one_time"
      );
    },
    [calculated.monthCount]
  );

  useEffect(() => {
    const subs = createSubscriptionList(subscriptions);
    if (dueDate && subs.length > 1) {
      setCalculated(
        generateSubPrices(dueDate, subs[0].amount / 100, subs[1].amount / 100)
      );
    }
  }, [createSubscriptionList, dueDate, subscriptions]);

  const [listItems, setListItems] = useState<any>([{}]);
  useEffect(() => {
    setListItems(createSubscriptionList(subscriptions));
  }, [createSubscriptionList, subscriptions]);

  useEffect(() => {
    const itemToChange = listItems.find(
      (item: any) => item.planType === active
    );
    if (listItems && active && itemToChange) {
      setChosenPrice(itemToChange.id);
    }
  }, [listItems, active]);

  useEffect(() => {
    LocalStorage.set("chosenPrice", chosenPrice);
  }, [chosenPrice]);

  useEffect(() => {
    if (calculated.monthCount === 1) {
      LocalStorage.set("selectedPlan", "one_off");
    }
  }, [calculated.monthCount]);
  // useEffect(() => {
  //   setPlanType(LocalStorage.get("selectedPlan"))
  // }, []);
  return (
    <>
      <Banner/>
      <div className={"subscriptions"}>
        <Header noButton/>

        {/*{isLoading ? (*/}
        {/*  <img className={"loader_icon"} src={SVG.LOADER_ICON} alt="loader" />*/}
        {/*) : (*/}
        <div className={"subscription_main"}>
          {closeModalSubScreen && (
            <img
              onClick={() => closeModalSubScreen()}
              className={"close_icon_btn"}
              src={closeIcon}
              alt="closeicon"
            />
          )}
          {modalTitle && <h2>{modalTitle}</h2>}
          <div
            style={
              calculated.lastMonth
                ? {marginBottom: "20px"}
                : {marginBottom: "60px"}
            }
            className={"subscription_firstBlock"}
          >
            <h1 className={"subscription_firstBlock_name"}>{userName || firstName}</h1>
            {calculated.lastMonth ? (
              <>
                <h1
                  style={{marginBottom: "12px"}}
                  className={"subscription_firstBlock_name"}
                >
                  You have 1 month left of your pregnancy
                </h1>
                <h1 className={"subscription_firstBlock_name"}>
                  Please select our Single Month Plan*
                </h1>
              </>
            ) : (
              <h1 className={"subscription_firstBlock_h1"}>
                {`Save £${calculated.savedAmount} today with a ${calculated.monthCount} month personalized pregnancy plan`}
              </h1>
            )}
          </div>
          {!calculated.lastMonth && !isModal && (
            <div className={"subscription_promoMobile"}>
              <div className={"subscription_promo-inner"}>
              <span>
                Got a promo code? Add at checkout to save an additional 15%
              </span>
              </div>
              <span>Save</span>
              <span>£{calculated.savedAmount}.00</span>
            </div>
          )}
          <div
            className={"subscription_secondBlock"}
            onClick={() => {
              LocalStorage.set(COMPLETE_PAY, "true");
            }}
          >
            {renderSubItem(listItems)}
          </div>
          <div className={'additional_info'}>
            <h1 className={'additional_info-title'}>On both plans, you’ll get:</h1>
            <p className={'additional_info-text'}>Supplements personalised to you by prenatal experts</p>
            <p className={'additional_info-text'}>Access to one-to-one coaching</p>
            <p className={'additional_info-text'}>Nutrition & wellness programme via companion app</p>
            <p className={'additional_info-text'}>Risk free, 30-day money back guarantee*</p>
          </div>
          <div className={"subscription-bottom-text"}>
            <small>
              {calculated.lastMonth
                ? "*You previously selected our Pregnancy Plan, which is only available for 2 months+ pregnancy terms."
                : active === "monthly"
                  ? `*If you don\'t love Bianu you can easily cancel in the first 30-days and have your subscription refunded. The monthly subscription is £${
                    listItems && listItems[1] ? listItems[1].amount / 100 : ""
                  }  and renews each month.`
                  : active === "pregnancy"
                    ? `*If you don't love Bianu you can easily cancel in the first 30-days
          and have your subscription refunded. The Pregnancy Plan is billed at
          £${calculated.totalChargePregnancy} (${calculated.monthCount} month plan) and doesn't renew.`
                    : null}
            </small>
            {calculated.lastMonth && (
              <small>
                **If you don't love Bianu you can easily cancel in the first
                30-days and have your subscription refunded. The Single Month Plan
                is billed at £44.99 and doesn’t renew.
              </small>
            )}
          </div>

          <div className={"button-wrapper-subscriptionscreen"}>

            <Button
              className={"primary_button_orange"}
              content={isModal ? "Back to checkout" : "Continue to checkout"}
              clickHandler={
                closeModalSubScreen
                  ? () => modalCheckoutHandler()
                  : () => subButtonHandler()
              }
              disabled={!active && !calculated.lastMonth}
            />
          </div>
        </div>
        {/*)}*/}
        <FooterMain linksShown={false}/>

      </div>
    </>
  );
}

export default SubscriptionScreen;
