import React from "react";
import Slider from "react-slick";
import four from "../../../assets/static/homePageImages/updateIMG/Ellipse 49-4.png";
import three from "../../../assets/static/homePageImages/updateIMG/Ellipse 49-3.png";
import second from "../../../assets/static/homePageImages/updateIMG/Ellipse 49-2.png";
import one from "../../../assets/static/homePageImages/updateIMG/Ellipse 49.png";

function SliderSection() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };


  return (
    <div className={"section_stories mobile_slider"}>
      <div className="container">
        <h2 className={'slider_header_title'}>Bianu stories</h2>
        <Slider {...settings}>
          <div className="section_stories_content_item">
            <div className="section_stories_content_item_left">
              <h3 className={'slider_title'}>
                Pregnancy Essentials
              </h3>
              <img src={one} alt="" />
            </div>
            <div className="section_stories_content_item_right">
              <p>Georgina, London</p>
              <span>
                Having Bianu personalised supplements for my baby and I delivered to my door is super convenient and means there is now one less thing for me to worry about during my pregnancy.
              </span>
            </div>
          </div>
          <div className="section_stories_content_item1 ">
            <div style={{background:'#F1F8E6'}} className="section_stories_content_item_left">
              <h3 className={'slider_title'}>
                Plant Based
              </h3>
              <img src={second} alt="" />
            </div>
            <div className="section_stories_content_item_right">
              <p>Sienna, Norwich</p>
              <span>
          As it’s my first pregnancy things can get overwhelming, so it's nice having the Bianu wellbeing programme and knowing that the right vegan supplements are tailored to each of my trimesters.
              </span>
            </div>
          </div>
          <div className="section_stories_content_item2">
            <div style={{background:'#EFF6FD'}} className="section_stories_content_item_left 3rd_slide">
              <h3 className={'slider_title'}>
                Dairy Free
              </h3>
              <img src={three} alt="" />
            </div>
            <div className="section_stories_content_item_right">
              <p>Valery, Manchester</p>
              <span>
                Very cool that Bianu has a dairy free supplement option, personalised for my first trimester. The granules are also easy to swallow and I love having access to the prenatal coaches on my phone.
              </span>
            </div>
          </div>
          <div className="section_stories_content_item3">
            <div style={{background:'#FDF7E0'}} className="section_stories_content_item_left 4th_slide">
              <h3 className={'slider_title'}>
                The Flexitarian
              </h3>
              <img src={four} alt="" />
            </div>
            <div className="section_stories_content_item_right">
              <p>Krisha, Warwick</p>
              <span>
              As a vegetarian, I was delighted to discover that Bianu had personalised supplements for my exact dietary needs. Also my fitness coach in the app recommends great workouts and is so supportive.
              </span>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
}
export default SliderSection;
