import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Routes } from "./configs/routes/routes";
import { auth } from "./configs/firebase";
import { onAuthStateChanged } from "firebase/auth";
import ConnectWithUs from "./pages/ConnectWithUs/ConnectWithUs";
import Home from "./pages/Home/Home";
import Quiz from "./pages/Quiz/Quiz";
import Recommendation from "./pages/Recommendation/Recommendation";
import WhyTrustUs from "./pages/WhyTrustUs/WhyTrustUs";
import LogIn from "./pages/LogIn/LogIn";
import RegistrationEmail from "./pages/LogIn/RegistrationEmail";
import CheckInBox from "./pages/LogIn/CheckInbox";
import NewPassword from "./pages/LogIn/NewPassword";
import Confirmation from "./components/Confirmation/Confirmation";
import SubscriptionScreen from "./pages/SubscriptionScreen/SubscriptionScreen";
import Cart from "./components/Cart/Cart";
import Checkout from "./pages/Checkout/Checkout";
import FAQ from "./pages/FAQ/FAQ";
import Loader from "./components/_common/Loader/Loader";
import Account from "./pages/Account/Account";
import useQuizzes from "./hooks/useQuizzes";
import Terms from "./components/Terms/Terms";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import ConnectFeedBack from "./components/_common/ConnectFeedBack/ConnectFeedBack";
import { useSelector } from "react-redux";
import { store } from "./types/store";

export const AppRouter = () => {
  const [authd, setAuthd] = useState(false);
  const [loading, setLoading] = useState(true);
  const { loadArticles, loadTagging } = useQuizzes();
  useEffect(() => {
    loadArticles();
    loadTagging();
    const listener = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthd(true);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
    return () => listener();
  }, []);

  const protectRoute = (component: JSX.Element) => {
    if (authd) {
      // return account.hasCompletedQuizPart1 ? component : <Quiz />;
      // if (
      //   address === Routes.RECOMMENDATION ||
      //   address === Routes.SUBSCRIPTION
      // ) {
      //   return !accountData.hasCompletedQuizPart1 ? component : <Quiz />;
      // }
      return component;
    } else {
      return <LogIn />;
    }
    // return authd ? component : <LogIn />;
  };

  return (
    <Router>
      <Switch>
        {loading && (
          <div
            style={{
              width: "100%",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader />
          </div>
        )}

        <Route path={Routes.ACCOUNT} render={() => protectRoute(<Account />)} />
        <Route path={Routes.CART} render={() => protectRoute(<Cart />)} />
        <Route path={Routes.CONNECT} component={ConnectWithUs} />
        <Route path={Routes.CONNECT_FEED_BACK} component={ConnectFeedBack} />
        <Route exact path={Routes.HOME} component={Home} />
        <Route path={Routes.LOGIN} component={LogIn} />
        <Route path={Routes.QUIZ} component={Quiz} />
        <Route
          path={Routes.RECOMMENDATION}
          render={() => protectRoute(<Recommendation />)}
        />
        <Route path={Routes.TRUST} component={WhyTrustUs} />
        <Route path={Routes.REGISTRATION_EMAIL} component={RegistrationEmail} />
        <Route path={Routes.REGISTRATION_INBOX} component={CheckInBox} />
        <Route path={Routes.REGISTRATION_PASSWORD} component={NewPassword} />
        <Route path={Routes.CONFIRMATION} component={Confirmation} />
        <Route
          path={Routes.SUBSCRIPTION}
          render={() => protectRoute(<SubscriptionScreen />)}
        />
        <Route
          path={Routes.CHECKOUT}
          render={() => protectRoute(<Checkout />)}
        />
        <Route path={Routes.FAQ} component={FAQ} />
        <Route path={Routes.TERMS} component={Terms} />
        <Route path={Routes.PRIVACY_POLICY} component={PrivacyPolicy} />
        <Route component = {Home}/>
      </Switch>
    </Router>
  );
};
