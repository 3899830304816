import React, {useEffect, useState} from "react";
import "./Order.scss";
import OrderDetails from "./orderDetails/OrderDetails";
import OrderCartTitle from "./orderCartTitle/OrderCartTitle";
import OrderCartItems from "./orderCartItems/OrderCartItems";
import OrderCartPay from "./orderCartPay/OrderCartPay";
import OrderCartAddress from "./orderCartAddress/OrderCartAddress";
import useAccount from "../../../hooks/useAccount";
import {useSelector} from "react-redux";
import {store} from "../../../types/store";
import OrderNoSubscription from "./orderNoSubscription/OrderNoSubscription";
import useSubscription from "../../../hooks/useSubscription";
import {Subscription} from "../../../types/subscription";
import useStripeHook from "../../../hooks/useStripeHook";
import Loader from "../../_common/Loader/Loader";
import ModalEndSubscription from "./orderModals/ModalEndSubscription";
import ModalEndSubscriptionSecond from "./orderModals/ModalEndSubscriptionSecond";
import {RootState} from "../../../store";
import {useRecommendations} from "../../../hooks/useRecommendations";
import OrderList from "./orderNoSubscription/OrderList";

function Order() {
    const {loadOrderData, getSubscriptionHistory} = useAccount();
    const [load, setLoad] = useState(true);
    const {accountData, orderData} = useSelector(
        (state: store) => state.account
    );
    const [modalVisible, setModalVisible] = useState({
        stepOne: false,
        stepSecond: false,
        endSubscribeOne: false,
        endSubscribeTwo: false,
        changePaymentMethod: false,
    });
    const dataSubscription = useSelector((state: any) => state.subscription);
    const currentSubscription = dataSubscription.find(
        (item: Subscription) => item.id === orderData.priceId
    );
    const endSubscribeSecond = () => {
        setModalVisible({...modalVisible, endSubscribeTwo: true});
    };
    const endSubscribe = () => {
        setModalVisible({...modalVisible, endSubscribeOne: true});
    };
    const closeModalHandler = () => {
        setModalVisible({
            ...modalVisible,
            stepSecond: false,
            stepOne: false,
            endSubscribeOne: false,
            endSubscribeTwo: false,
            changePaymentMethod: false,
        });
    };
    const {loadSubscription} = useSubscription();
    const [visible, setVisible] = useState(false);
    const {getUserCards} = useStripeHook();
    useEffect(() => {
        loadSubscription();
        loadSupplements();
        getUserCards().then(() => {
            setLoad(false);
        });
        if (accountData.uid) {
            loadOrderData(accountData.uid || "").then(() => {
                setLoad(false);
            });
            getSubscriptionHistory(accountData.uid);
        }
    }, []);
    const visibleHandler = () => {
        setVisible(!visible);
    };


    const {loadSupplements} = useRecommendations();
    const {supplements} = useSelector((state: RootState) => state.rec);

    return (
        <>
            {modalVisible.endSubscribeOne && (
                <ModalEndSubscription
                    orderData={orderData}
                    currentSubscription={currentSubscription}
                    clickHandlerClose={closeModalHandler}
                    clickHandlerButton={endSubscribeSecond}
                />
            )}
            {modalVisible.endSubscribeTwo && (
                <ModalEndSubscriptionSecond
                    clickHandlerButton={() => {
                    }}
                    clickHandlerClose={closeModalHandler}
                />
            )}
            <h2 className="order_account_title">Orders & subscriptions</h2>
            {Object.keys(orderData).length !== 0 ? (
                <OrderDetails
                    currentSubscription={currentSubscription}
                    loading={!load}
                />
            ) : (
                <OrderNoSubscription/>
            )}
            {Object.keys(orderData).length !== 0 ? (
                <div className="order_account">
                    <OrderCartTitle
                        currentSubscription={currentSubscription}
                        clickHandler={visibleHandler}
                        visible={visible}
                    />
                    {load ? (
                        <Loader/>
                    ) : (
                        <div style={{display: !visible ? "block" : "none"}}>
                            <OrderCartItems
                                product={supplements.find(
                                    (el: any) => el.id === orderData.productId
                                )}
                            />
                            <OrderCartPay promoCode={accountData.promoCodeId} currentSubscription={currentSubscription}/>
                            <OrderCartAddress/>
                            {orderData.status === "active" && (
                                <div
                                    onClick={endSubscribe}
                                    className={"end_subscription"}
                                >
                                    Cancel subscription
                                </div>
                            )}
                        </div>
                    )}
                </div>
            ) : null}
            {/*<OrderList dataSubscription={dataSubscription} supplements={supplements} load={load}/>*/}
        </>
    );
}

export default Order;
