import React from "react";
import { useHistory } from "react-router-dom";
import imgOne from "../../../assets/static/homePageImages/images/headerImages/rec-img1.png";

interface Props {
  clickBtnHandler: any;
}
function SectionSix({ clickBtnHandler }: Props) {
  const history = useHistory();
  return (
    <section className="application">
      <div className="header_recommendation">
        <img className="header_recommendation-img" src={imgOne} alt="" />
        <div className="header_recommendation-content">
          <p className="recommendation-title">Laura Tilt, Dietician</p>
          <p className="recommendation-text">
            Pregnancy nutrition is complicated and can feel overwhelming. We
            understand.<br/> We're here to make things easier and prepare you for
            birth on your terms, using our prenatal expertise."
          </p>
        </div>
      </div>
    </section>
  );
}

export default SectionSix;
