import React, { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../../configs/firebase";

function OrderCartPay({ currentSubscription, promoCode }: any) {
  const { amount, currencySymbol } = currentSubscription;
  const [promo, setPromo] = useState({ text: "", percent: 0 });
  const currentPromoCode = async () => {
    const promoCodesRef = collection(db, "bianu_1.00", "en_uk", "promoCodes");
    const q = query(promoCodesRef, where("id", "==", promoCode));
    promoCode &&
      (await getDocs(q).then((data) => {
        if (data.docs.length > 0) {
          data.docs.forEach((doc) => {
            const promoCodeObject = doc.data();
            setPromo({
              text: promoCodeObject.name,
              percent: promoCodeObject.percent,
            });
          });
        }
      }));
  };
  useEffect(() => {
    currentPromoCode();
  }, []);
  return (
    <div className={"order_account_cart_total"}>
      <div className={"order_account_cart_total_info"}>
        <div>
          <p>Subtotal</p>
          <span>
            {currencySymbol}
            {amount / 100}
          </span>
        </div>
        <div>
          <p>PROMO CODE</p>
          <span className={"promo_code"}>
            {promoCode ? (
              promo.text
            ) : (
              <span style={{ color: "#383942" }}>-</span>
            )}
          </span>
        </div>
        <div>
          <p>Shipping & handling</p>
          <span>Free</span>
        </div>
        <div>
          <p>Tax</p>
          <span>£0,00</span>
        </div>
      </div>
      <div className={"order_account_cart_total_pay"}>
        <div>
          <p>Total</p>
          <span>
            {currencySymbol}
            {promo.percent
              ? ((amount * (100 - promo.percent)) / 100 / 100).toFixed(2)
              : amount / 100}
          </span>
        </div>
      </div>
    </div>
  );
}

export default OrderCartPay;
