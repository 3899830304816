import React from "react";
import "./ProgressBar.scss";

interface Props {
  barWidth: number;
  currentStep: number;
  totalSteps: number;
}

function ProgressBar(props: Props) {
  const { barWidth, totalSteps, currentStep } = props;
  return (
    <div className="progress-bar">
      <small>
        Step {currentStep} of {totalSteps}
      </small>
      <div style={{ width: `${barWidth}vw` }} className="bar-top" />
    </div>
  );
}

export default ProgressBar;
