import { actionInterface } from "../../types/common";
import { REC_ACTION_TYPES } from "../action-types";

export const recReducer = (
  state: any = { recommended: "", favorite: "", supplements: [] },
  action: actionInterface
) => {
  switch (action.type) {
    case REC_ACTION_TYPES.SET_REC: {
      return {
        ...state,
        favorite: action.data.favorite,
        recommended: action.data.recommended,
      };
    }
    case REC_ACTION_TYPES.UPDATE_RECOMMENDED: {
      return {
        ...state,
        favorite: action.data,
      };
    }
    case REC_ACTION_TYPES.SET_PRODUCT: {
      return { ...state, product: action.data };
    }
    case REC_ACTION_TYPES.SET_SUPPLEMENTS: {
      return { ...state, supplements: action.data };
    }
    case REC_ACTION_TYPES.USER_LOGOUT: {
      return { recommended: "", favorite: "", supplements: [] };
    }
    default: {
      return state;
    }
  }
};
