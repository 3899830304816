import React, {useState} from 'react';
import Input from "../../_common/Input/Input";
import Button from "../../_common/Button/Button";
import useAccount from "../../../hooks/useAccount";
import {useSelector} from "react-redux";
import {store} from "../../../types/store";
import {validatePhone} from "../../../utils/UtilsCommon";
interface Props {
  editPhoneHandler: ()=> void
  phoneData: string | undefined
}
function UpdatePhone({editPhoneHandler, phoneData}: Props) {
  const [phone, setPhone] = useState(phoneData || '')
  const [error, setError] = useState(false)
  const {uid} = useSelector((state: store) => state.account.accountData)
  const {updateField, loadAccount} = useAccount()
  const phoneHandler = async (value: string) => {
    setPhone(value)
    const valid = validatePhone(value)
    setError(valid)
  }

  const updatePhoneData = async () => {
    const valid = validatePhone(phone)
    setError(valid)
    if(valid){
      await updateField(uid || '', 'phone', phone)
      editPhoneHandler()
      await loadAccount(uid || '')
    }
  }
  return (
    <>
      <div className={'name_input_wrapper'}>
        <Input
          type={'text'}
          placeholder={'Phone'}
          name={'phone'}
          value={phone}
          onChange={(e)=>{phoneHandler(e.currentTarget.value)}} />
      </div>
      {!error && <p className={'error_phone_message'}>Only number / max length 13 symbol</p>}
      <div className={'name_button_wrapper'}>
        <Button
          disabled={phone === '' && true}
          content={'Done'}
          clickHandler={updatePhoneData}
          className={'primary_button_green'}/>
        <Button content={'Cancel'} clickHandler={editPhoneHandler} className={'secondary_button_gray'}/>
      </div>
    </>
  );
}

export default UpdatePhone;
