import React from 'react';
import Modal from "../../../_common/Modal/Modal";
import Button from "../../../_common/Button/Button";

interface Props {
  clickHandlerButton?: ()=>void;
  clickHandlerClose?: ()=>void;
}
function ResetPasswordModal({clickHandlerButton, clickHandlerClose}: Props) {
  return (
    <Modal clickHandlerClose={clickHandlerClose}>
        <p>djshdja</p>
      <h3>Please check your email</h3>
      <Button content={'OK'} clickHandler={clickHandlerClose} className={'primary_button_green'} />
    </Modal>
  );
}
export default ResetPasswordModal;
