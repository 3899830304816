import { collection, getDocs } from "firebase/firestore";
import { db } from "../configs/firebase";
import useStripeHook, { subObj } from "./useStripeHook";

function useUpdateSubscription() {
  const { createSubscriptionHandler, updateSubscriptionHandler } =
    useStripeHook();

  const updateSubscription = async (
    uid: any,
    { priceId, productId, rootPath, paymentMethodId, couponId }: subObj
  ) => {
    const getUserInfo = await getDocs(
      collection(db, "users", `${uid}`, "productSubscriptions")
    );
    let userData: any[] = [];
    getUserInfo.docs.forEach((doc) => (userData = userData.concat(doc.data())));
    if (userData.length > 0) {
      const { stripeSubscriptionId } = userData[0];
      await updateSubscriptionHandler({
        subscriptionId: stripeSubscriptionId,
        paymentMethodId,
        productId,
        cancelAtPeriodEnd: true,
      });
      return await createSubscriptionHandler({
        priceId,
        productId,
        rootPath,
        paymentMethodId,
        couponId,
      });
    } else {
      return await createSubscriptionHandler({
        priceId,
        productId,
        rootPath,
        paymentMethodId,
        couponId,
      });
    }
  };
  return { updateSubscription };
}

export default useUpdateSubscription;
