import React from "react";
import { useHistory } from "react-router-dom";
import imgOne from "../../../assets/static/png/newBianuPromise.png";
function SectionOne() {
  const history = useHistory();
  return (
    <section className="personalise">
      <div className="container_second_screen">
        <div className="section_second_left">
          <img src={imgOne} />
        </div>
        <div className="section_second_right">
          <h2>Our promise to you</h2>
          <p>
            Ensuring you have the best pregnancy possible is our #1 priority.
            Which means we’re obsessed with providing you and your baby with a
            brilliant product and service. And in the first 30-days if you don’t
            love Bianu for any reason at all, we’ll refund your subscription.
            Just reach out to the Bianu team. We’re here for you and your baby.
          </p>
        </div>
      </div>
    </section>
  );
}

export default SectionOne;
