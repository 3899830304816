import React, { useCallback, useEffect, useMemo, useState } from "react";
import { dateStep } from "../../../types/quizTypes";
import Input from "../../_common/Input/Input";
import Button from "../../_common/Button/Button";
import { Link } from "react-router-dom";
import "./DateForm.scss";
import { UtilsCommon } from "../../../utils/UtilsCommon";
import { useFormik } from "formik";
import { quizValidation } from "../../../constants/validationSchemas";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

interface Props {
  stepData: dateStep;
  navFunc: () => void;
  submitDate: (
    values: { dd: string; mm: string; yyyy: string },
    cycle: string
  ) => void;
}

const initialDate = { dd: "", mm: "", yyyy: "" };

function DateForm(props: Props) {
  const { stepData, navFunc, submitDate } = props;
  // const [values, setValues] = useState<any>(initialDate);
  // const [cycle, setCycle] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const { nav } = useSelector((state: RootState) => state);

  const submitHandler = (e?: React.FormEvent) => {
    // e.preventDefault();
    if (
      !disabled &&
      formik.values.dd !== "" &&
      formik.values.mm !== "" &&
      formik.values.yyyy !== ""
    ) {
      submitDate(
        {
          dd: formik.values.dd,
          mm: formik.values.mm,
          yyyy: formik.values.yyyy,
        },
        formik.values.cycle
      );
      formik.resetForm();
      setError("");
    } else {
      return;
    }
  };

  const formik: any = useFormik({
    initialValues: { ...initialDate, cycle: "" },
    onSubmit: () => {
      submitHandler();
    },
    validationSchema: quizValidation[stepData.id],
  });

  const resultingDate = useMemo(
    () =>
      new Date(
        Date.parse(
          `${formik.values.yyyy}-${formik.values.mm}-${formik.values.dd}`
        )
      ),
    [formik.values]
  );

  const formIsDisabled = useCallback(() => {
    if (
      formik.values.dd !== "" &&
      formik.values.mm !== "" &&
      formik.values.yyyy !== ""
    ) {
      switch (stepData.subType) {
        case "dob": {
          setDisabled(!UtilsCommon.validAge(resultingDate));
          break;
        }
        case "due": {
          setDisabled(!UtilsCommon.validDueDate(resultingDate));
          break;
        }
        case "lastPer": {
          setDisabled(!UtilsCommon.validLastPeriod(resultingDate));
          break;
        }
      }
    }
  }, [formik.values, resultingDate, stepData.subType]);
  useEffect(() => {
    formIsDisabled();
  }, [formIsDisabled, formik.values, nav.currentStepId]);

  useEffect(() => {
    if (disabled) {
      setError("Please enter a valid date");
    } else {
      setError("");
    }
  }, [disabled]);

  useEffect(() => {
    formik.resetForm();
    setError("");
  }, [nav.currentStepId]);

  return (
    <>
      <form className={"form-wrapper date-form"} onSubmit={formik.handleSubmit}>
        <h1>{stepData.header}</h1>
        {stepData.cycleLength && (
          <span className={"cycle-title"}>
            The first day of your last period
          </span>
        )}
        <div className="multi-input">
          {stepData.inputPlaceHolders.map((ph: string, index: number) => (
            <Input
              className={"date-input"}
              value={formik.values[ph]}
              autoFocus={index === 0}
              title={
                ph === "yyyy"
                  ? "Year as 'yyyy'"
                  : ph === "mm"
                  ? "Month as 'mm'"
                  : "Day as 'dd'"
              }
              name={ph}
              onChange={formik.handleChange}
              pattern={
                ph === "yyyy"
                  ? "19[0-9][0-9]|20[0-9][0-9]"
                  : ph === "mm"
                  ? "0[1-9]|1[0-2]"
                  : "0[1-9]|1[0-9]|2[0-9]|3[0-1]"
              }
              max={ph === "yyyy" ? 4 : 2}
              key={ph}
              type={"text"}
              placeholder={ph}
            />
          ))}
          <small>{error}</small>
        </div>
        {stepData.cycleLength && (
          <>
            <div className={"cycle-length"}>Your cycle length</div>
            <Input
              className={"cycle-input"}
              max={2}
              title={"Only numeric characters"}
              pattern={"[0-9]+"}
              name="cycle"
              value={formik.values.cycle}
              onChange={formik.handleChange}
              type={"text"}
              placeholder={"ex. 25 days"}
            />
          </>
        )}
        <Button
          type={"submit"}
          className={"primary_button_green"}
          content={stepData.btnText}
          // disabled={disabled}
        />
        <div className={"bottom-text-wrapper"}>
          {stepData.stepOptions.bottomText && (
            <span>{stepData.stepOptions.bottomText}</span>
          )}
          {stepData.stepOptions.linkText && (
            <Link
              to={"#"}
              onClick={(e) => {
                e.preventDefault();
                navFunc();
              }}
            >
              {stepData.stepOptions.linkText}
            </Link>
          )}
        </div>
      </form>
    </>
  );
}

export default DateForm;
