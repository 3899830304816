import React, { useState } from "react";
import Input from "../../_common/Input/Input";
import Button from "../../_common/Button/Button";
import { useSelector } from "react-redux";
import { store } from "../../../types/store";
import useAccount from "../../../hooks/useAccount";

interface Props {
  editNameHandler: () => void;
  firstName: any;
  lastName: any;
}

function UpdateName({ editNameHandler, firstName, lastName }: Props) {
  const { accountData } = useSelector((state: store) => state.account);
  const { uid } = accountData;
  const { updateField, loadAccount } = useAccount();
  const [nameData, setName] = useState({ name: firstName, lastName: lastName });
  const itemName = [
    {
      name: firstName,
      inputName: "name",
      value: nameData.name,
      placeholder: "First name",
    },
    {
      name: lastName,
      inputName: "lastName",
      value: nameData.lastName,
      placeholder: "Last name",
    },
  ];
  const nameHandler = (value: string, name: string) => {
    setName({ ...nameData, [name]: value });
  };
  const nameUpdateHandler = async () => {
    await updateField(uid || "", "name", nameData.name, nameData.lastName);
    editNameHandler();
    await loadAccount(uid || "");
  };

  const renderItem = () => {
    return itemName.map((item, index) => {
      return (
        <div key={index}>
          <Input
            type={"text"}
            placeholder={item.placeholder}
            name={item.inputName}
            value={item.value}
            onChange={(e) => {
              nameHandler(e.currentTarget.value, e.currentTarget.name);
            }}
          />
        </div>
      );
    });
  };
  return (
    <>
      <div className={"name_input_wrapper"}>{renderItem()}</div>
      <div className={"name_button_wrapper"}>
        <Button
          disabled={nameData.name === "" || (nameData.lastName === "" && true)}
          content={"Done"}
          clickHandler={nameUpdateHandler}
          className={"primary_button_green"}
        />
        <Button
          content={"Cancel"}
          clickHandler={editNameHandler}
          className={"secondary_button_gray"}
        />
      </div>
    </>
  );
}

export default UpdateName;
