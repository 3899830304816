import { useDispatch } from "react-redux";
import {
  doc,
  getDoc,
  updateDoc,
  setDoc,
  getDocs,
  collection,
  addDoc,
  Timestamp,
} from "firebase/firestore";
import { sendPasswordResetEmail, deleteUser } from "firebase/auth";
import { auth, db } from "../configs/firebase";
import {
  actionGetDataAccountAC,
  actionGetDataAddressAC,
  actionGetDataQuizAC,
  actionGetDefaultPayMethodAC, actionGetLastAnswer,
  actionGetOrderDataAC,
  actionUserSubscriptionHistory,
} from "../store/actions/account-actions";
import { getRecommendations } from "../utils/UtilsCommon";
import { actionSetRec } from "../store/actions/rec-actions";

function useAccount() {
  const dispatch = useDispatch();
  const loadAccount = async (uid: string) => {
    // const data = await getDocs(collection(db, "users", `${uid}`, "userData"));
    const userDoc = await getDoc(doc(db, "users", uid));
    const supplementQuiz = await getDoc(
      doc(db, "users", uid, "userData", "supplementQuiz")
    );
    if (userDoc.exists() && supplementQuiz.exists()) {
      dispatch(actionGetDataAccountAC({ ...userDoc.data() }));
      dispatch(
        actionGetDataQuizAC({
          ...userDoc.data(),
          dueDate: supplementQuiz.data().dueDate || 0,
        })
      );
    }
    // let hasCompletedQuizPart1: boolean;
    // if (userDoc.exists()) {
    //   hasCompletedQuizPart1 = userDoc.data().hasCompletedQuizPart1;
    // }
    // data.forEach((doc) => {
    //   switch (doc.id) {
    //     case "user":
    //       return dispatch(
    //         actionGetDataAccountAC({
    //           ...doc.data(),
    //           hasCompletedQuizPart1,
    //           firstName: doc.data().firstName,
    //         })
    //       );
    //     case "supplementQuiz":
    //       return dispatch(actionGetDataQuizAC(doc.data()));
    //     case "address":
    //       return dispatch(actionGetDataAddressAC(doc.data()));
    //     default:
    //       return null;
    //   }
    // });
  };
  const resetPassword = async (email: string) => {
    sendPasswordResetEmail(auth, email).catch((error) => {});
  };
  const setAccountDocument = async (user: any, data: any) => {
    const { email, uid } = user;
    const userRef = doc(db, "users", `${user.uid}`, "userData", "user");
    const userData = doc(db, "users", `${user.uid}`);
    await setDoc(userData, {
      email,
      userId: user.uid,
      firstName: data.firstName,
      lastName: data.lastName,
      appName: "BiaNu",
    },{merge:true});
    await setDoc(userRef, {
      email,
      uid,
      path: `users/${uid}/userData/user`,
      name: "userData",
    },
      {merge:true});
  };
  const getUserAddress = async (uid: string) => {
    const forDispatch: any[] = [];
    const querySnap = await getDocs(collection(db, "users", uid, "addresses"));
    querySnap.forEach((el) => forDispatch.push(el.data()));

    if (forDispatch.length > 0) {
      const primaryAddress = forDispatch.find(
        (item) => item.isPrimary === true
      );
      const secondAddress = forDispatch.find(
        (item) => item.isPrimary === false
      );
      dispatch(
        actionGetDataAddressAC({
          line1: primaryAddress?.line1,
          line2: primaryAddress?.line2,
          town: primaryAddress?.town,
          post_code: primaryAddress?.postcode,
          address_id: primaryAddress?.id,
          second_address_id: secondAddress && secondAddress.id,
          secondAddress,
        })
      );
    }
  };
  const sendAddress = async (
    uid: string,
    data: any,
    addressId?: string,
    second?: boolean
  ) => {
    if (addressId) {
      const addressRef = collection(db, "users", uid, "addresses");
      await setDoc(
        doc(addressRef, addressId),
        {
          line1: data.line1,
          line2: data.line2,
          town: data.town,
          postcode: data.postcode,
          updatedAt: Timestamp.now(),
          primary: data.primary,
        },
        { merge: true }
      );
      await loadAccount(uid);
      await getUserAddress(uid);
    } else {
      const addressRef = collection(db, "users", uid, "addresses");
      const { id } = await addDoc(addressRef, {
        ...data,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
      });
      await setDoc(
        doc(addressRef, id),
        { id, path: `users/${uid}/addresses/${id}` },
        { merge: true }
      );
      await loadAccount(uid);
    }
  };
  const updateField = async (
    uid: string,
    fieldName: string,
    value: string,
    lastName?: string
  ) => {
    const userRef = doc(db, "users", uid, "userData", "user");
    const phoneRef = doc(db, "users", uid);
    const userDocRef = doc(db, "users", uid);
    switch (fieldName) {
      case "email":
        return await updateDoc(userRef, { email: value });
      case "name":
        return await updateDoc(userDocRef, {
          firstName: value,
          lastName: lastName,
        });
      case "phone":
        return await updateDoc(phoneRef, { phone: value });
      case "promoCodeId":
        return await updateDoc(userDocRef, { promoCodeId: value });
      default:
        return null;
    }
  };
  const removeAccount = async () => {
    const user = auth.currentUser;
    if (user !== null) {
      deleteUser(user)
        .then(() => {})
        .catch((error) => {
          console.log(error.message);
        });
    }
  };
  const setRecommendationsAgain = async (uid: string) => {
    const quizObj = await getDoc(
      doc(db, "users", uid, "userData", "supplementQuiz")
    );
    const secondOption = quizObj.data()?.lastKnownTrimester === 1? "pregnancy_complete_t1": "pregnancy_complete_t2";
    const showOption = quizObj.data()?.foodIntake === 'Yes' ? secondOption : '' ;
    dispatch(actionGetLastAnswer(quizObj.data()?.foodIntake))
    const forDispatch = getRecommendations(
      quizObj?.data()?.lastKnownTrimester,
      quizObj?.data()?.hasSickness,
      quizObj?.data()?.foodIntakeEnum,
      quizObj?.data()?.currentDietEnum,
      quizObj?.data()?.healthyDiet
    );
    dispatch(actionSetRec({...forDispatch, recommended: showOption}));
    return forDispatch;
  };
  const loadOrderData = async (uid: string) => {
    const subscriptionId = await getDoc(doc(db, "users", uid));
    const id: any = subscriptionId.data();
    if (id) {
      if (id.currentProductSubscriptionId) {
        const subscriptionData = await getDoc(
          doc(
            db,
            "users",
            `${uid}`,
            "productSubscriptions",
            id.currentProductSubscriptionId
          )
        );
        dispatch(actionGetOrderDataAC(subscriptionData.data()));
        if (id.currentPaymentMethodId) {
          dispatch(actionGetDefaultPayMethodAC(id.currentPaymentMethodId));
        } else {
          return null;
        }
      }
    }
  };

  const getSubscriptionHistory = async (uid: string) => {
    const forDispatch: any[] = [];
    const querySnap = await getDocs(
      collection(db, "users", uid, "productSubscriptions")
    );
    querySnap.forEach((el) => forDispatch.push(el.data()));
    dispatch(actionUserSubscriptionHistory(forDispatch));
  };

  return {
    getUserAddress,
    loadAccount,
    setAccountDocument,
    setRecommendationsAgain,
    resetPassword,
    sendAddress,
    updateField,
    removeAccount,
    loadOrderData,
    getSubscriptionHistory,
  };
}

export default useAccount;
