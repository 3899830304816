import React from "react";
import "./CheckOutModal.scss";
import SubscriptionScreen from "../../../pages/SubscriptionScreen/SubscriptionScreen";
import closeIcon from "../../../assets/static/icon/close.svg";

interface Props {
  closeModal: () => void;
}
function CheckoutModal({ closeModal }: Props) {
  return (
    <div className={"checkout_modal"}>
      <SubscriptionScreen
        isModal
        closeModalSubScreen={() => closeModal()}
        modalTitle={"Change subscription plan"}
      />
    </div>
  );
}

export default CheckoutModal;
