import { Quiz } from "../../types/quizTypes";
import { DefaultQuizzesState } from "../../constants/defaultData";
import { actionInterface } from "../../types/common";
import { QUIZ_ACTION_TYPES } from "../action-types";

export const quizReducer = (
  state: Quiz[] = DefaultQuizzesState,
  action: actionInterface
) => {
  switch (action.type) {
    case QUIZ_ACTION_TYPES.SET_QUIZZES: {
      return action.data;
    }
    case QUIZ_ACTION_TYPES.USER_LOGOUT: {
      return DefaultQuizzesState;
    }
    case QUIZ_ACTION_TYPES.SET_ARTICLES: {
      return { ...state, articles: action.data };
    }
    case QUIZ_ACTION_TYPES.SET_TAGGING: {
      return { ...state, tagging: action.data };
    }
    default:
      return state;
  }
};
