import React from "react";
import Button from "../../_common/Button/Button";
import CheckBoxItems from "../../CheckBox/CheckBoxItems";

function LastSection({clickBtnHandler}: { clickBtnHandler: () => void }) {
    return (
        <>
            <div className="last_section">
                <div className={"header_content_main_wrapper"}>
                    <div className="last_section_content">
                        <h1>Try Bianu today</h1>
                        <div className={"last_section_check_wrapper"}>
                            <p>Supplements personalised to you by prenatal experts</p>
                            <p>Nutrition & wellbeing programme via companion app</p>
                            <p>Access to one-to-one coaching</p>
                            <p>Risk free, 30-day money back guarantee</p>
                        </div>
                        {/*<div className="checkbox_wrapper">*/}
                        {/*<CheckBoxItems/>*/}
                        {/*</div>*/}
                        {/*<span className={"description_second_section_one"}>*/}
                        {/*  *If you don't love Bianu you can easily cancel in the first*/}
                        {/*  30-days and have your subscription refunded. The Pregnancy Plan is*/}
                        {/*  billed at £34.99 x 3-10 months (due date dependant) and doesn't*/}
                        {/*  renew.*/}
                        {/*</span>*/}
                        <Button
                            content={"Start pregnancy quiz"}
                            className={"primary_button_orange"}
                            clickHandler={clickBtnHandler}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default LastSection;
