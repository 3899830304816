import detailItem1 from "../assets/static/recommendation_img/detail-item1.svg";
import productItem1 from "../assets/static/recommendation_img/product-item1.svg";
import comprisesImg from "../assets/static/recommendation_img/comprises.svg";
import detailItem2 from "../assets/static/recommendation_img/detail-item2.svg";
import productItem2 from "../assets/static/recommendation_img/product-item2.svg";
import detailItem3 from "../assets/static/recommendation_img/detail-item3.svg";
import productItem3 from "../assets/static/recommendation_img/product-item3.svg";
import detailItem4 from "../assets/static/recommendation_img/detail-item4.svg";
import productItem4 from "../assets/static/recommendation_img/product-item4.svg";
import recItem1 from "../assets/static/recommendation_img/rec-item1.svg";
import recItem2 from "../assets/static/recommendation_img/rec-item2.svg";
import recItem3 from "../assets/static/recommendation_img/rec-item3.svg";
import iconPlusRed from "../assets/static/icon/plus_icon_red.svg";

import visaMethod from "../assets/static/icon/Method=Visa.svg";
import masterMethod from "../assets/static/icon/Method=Mastercard.svg";
import { Subscription } from "../types/subscription";
import { Supplement } from "../types/supplements";
import {BillingData, TermsData } from "../types/checkout";
import { supplementIngredients } from "../assets/static/supplement_ingredients/supplementIngredients";

export const PersonalDetailsSetting = {
  name: false,
  email: false,
  phone: false,
  address: false,
  address2: false,
};


export const initialStateRecommendation: Supplement = {
  id: "",
  createdAt: {
    seconds: 0,
    nanoseconds: 0,
  },
  updatedAt: {
    seconds: 0,
    nanoseconds: 0,
  },
  suggestedUse: "",
  name: "",
  description: "",
  ingredients: [{ name: "", units: "", valueUnits: "", value: 0 }],
  ingredientNote: "",
  ingredientInfo: "",
  benefitSupplementsAre: [""],
  benefitHighlights: [""],
  path: "",
  imagePath: "",
};
export const DefaultQuizzesState: any[] = [
  {
    createdAt: {
      nanoseconds: 0,
      seconds: 0,
    },
    id: "",
    path: "",
    steps: [
      {
        id: "",
        isCheckpoint: false,
        singleSelectionPlacement: "",
        stepType: "",
        textItems: [{ text: "" }],
      },
    ],
    title: "",
    type: "",
    updatedAt: {
      nanoseconds: 0,
      seconds: 0,
    },
  },
];
export const defaultSubscriptionState: Subscription[] = [
  {
    createdAt: {
      seconds: 1631180519,
      nanoseconds: 911292000,
    },
    intervalCount: 30,
    type: "one_time",
    currencySymbol: "£",
    currency: "gbp",
    interval: "day",
    id: "prc0001",
    stripeProductId: "prod_KGIMc0hcRzkLLC",
    path: "bianu_1.00/en_uk/prices/prc0001",
    amount: 3499,
    title: "One-time purchase",
    active: true,
    updatedAt: {
      seconds: 1631180519,
      nanoseconds: 911293000,
    },
    stripePriceId: "price_1JbllTDEcgDmZVsQyDmdAiXg",
    description: "Pregnancy Plan - billed at £104.97 (3 month plan)",
  },
  {
    stripeProductId: "prod_KGIMc0hcRzkLLC",
    currencySymbol: "£",
    id: "prc0002",
    path: "bianu_1.00/en_uk/prices/prc0002",
    title: "Monthly subscription",
    createdAt: {
      seconds: 1631180519,
      nanoseconds: 911303000,
    },
    interval: "day",
    type: "recurring",
    intervalCount: 30,
    updatedAt: {
      seconds: 1631180519,
      nanoseconds: 911314000,
    },
    active: true,
    description: "Monthly Plan",
    currency: "gbp",
    stripePriceId: "price_1JbllTDEcgDmZVsQyDmdAiXg",
    amount: 4499,
  },
];

export enum stepTypes {
  INPUT = "INPUT",
  DATE = "DATE",
  SINGLE = "SINGLE",
  MULTIPLE = "MULTIPLE",
  CONFIRM = "CONFIRM",
  LOGIN_PASSWORD = "LOGIN_PASSWORD",
}

export const supplementsData = [
  {
    id: "pregnancy_essentials_t1",
    details_img: detailItem1,
    product_card_img: productItem1,
    title: "Pregnancy Essentials - T1",
    text_list: [
      "Supports blood system",
      "Promotes energy metabolism",
      "Beneficial for vegans and vegetarians",
    ],
    content:
      "Our pregnancy Essentials T1 supplement is designed for mums-to-be who don’t have any dietary restrictions and are managing to eat a healthy balanced diet.  A healthy balanced diet will provide you with most of the nutrients you and your baby need, with a few exceptions - and that’s where our carefully curated Pregnancy Essentials T1 comes in. Alongside a healthy balanced diet (which we’ll be helping you with through our companion app!) our Pregnancy Essentials T1 will help meet yours and your baby’s nutritional needs.",
    advantages: [
      "Free from XXX",
      "Gluten free ",
      "Easy to consume",
      "Flavourless",
    ],
    comprises:
      "Our Pregnancy Essentials T1 contains the two nutrients all women need in supplement form during pregnancy - folic acid and vitamin D - at the recommended levels for your first trimester. It’s difficult to get enough of these nutrients from food, which is why it’s important to take them in supplement form. ",
    comprises_img: comprisesImg,
  },
  {
    id: "dairy_free_t1",
    details_img: detailItem2,
    product_card_img: productItem2,
    title: "The Dairy Free T1",
    text_list: [
      "Supports blood system",
      "Promotes energy metabolism",
      "Beneficial for vegans and vegetarians",
    ],
    content:
      "Our pregnancy Essentials T1 supplement is designed for mums-to-be who don’t have any dietary restrictions and are managing to eat a healthy balanced diet.  A healthy balanced diet will provide you with most of the nutrients you and your baby need, with a few exceptions - and that’s where our carefully curated Pregnancy Essentials T1 comes in. Alongside a healthy balanced diet (which we’ll be helping you with through our companion app!) our Pregnancy Essentials T1 will help meet yours and your baby’s nutritional needs.",
    advantages: [
      "Free from XXX",
      "Gluten free ",
      "Easy to consume",
      "Flavourless",
    ],
    comprises:
      "Our Pregnancy Essentials T1 contains the two nutrients all women need in supplement form during pregnancy - folic acid and vitamin D - at the recommended levels for your first trimester. It’s difficult to get enough of these nutrients from food, which is why it’s important to take them in supplement form. ",
    comprises_img: comprisesImg,
  },
  {
    id: "flexitarian_t1",
    details_img: detailItem3,
    product_card_img: productItem3,
    title: "The Flexitarian T1",
    text_list: [
      "Supports blood system",
      "Promotes energy metabolism",
      "Beneficial for vegans and vegetarians",
    ],
    content:
      "Our pregnancy Essentials T1 supplement is designed for mums-to-be who don’t have any dietary restrictions and are managing to eat a healthy balanced diet.  A healthy balanced diet will provide you with most of the nutrients you and your baby need, with a few exceptions - and that’s where our carefully curated Pregnancy Essentials T1 comes in. Alongside a healthy balanced diet (which we’ll be helping you with through our companion app!) our Pregnancy Essentials T1 will help meet yours and your baby’s nutritional needs.",
    advantages: [
      "Free from XXX",
      "Gluten free ",
      "Easy to consume",
      "Flavourless",
    ],
    comprises:
      "Our Pregnancy Essentials T1 contains the two nutrients all women need in supplement form during pregnancy - folic acid and vitamin D - at the recommended levels for your first trimester. It’s difficult to get enough of these nutrients from food, which is why it’s important to take them in supplement form. ",
    comprises_img: comprisesImg,
  },
  {
    id: "plant_based_t1",
    details_img: detailItem4,
    product_card_img: productItem4,
    title: "The Plant Based T1",
    text_list: [
      "Supports blood system",
      "Promotes energy metabolism",
      "Beneficial for vegans and vegetarians",
    ],
    content:
      "Our pregnancy Essentials T1 supplement is designed for mums-to-be who don’t have any dietary restrictions and are managing to eat a healthy balanced diet.  A healthy balanced diet will provide you with most of the nutrients you and your baby need, with a few exceptions - and that’s where our carefully curated Pregnancy Essentials T1 comes in. Alongside a healthy balanced diet (which we’ll be helping you with through our companion app!) our Pregnancy Essentials T1 will help meet yours and your baby’s nutritional needs.",
    advantages: [
      "Free from XXX",
      "Gluten free ",
      "Easy to consume",
      "Flavourless",
    ],
    comprises:
      "Our Pregnancy Essentials T1 contains the two nutrients all women need in supplement form during pregnancy - folic acid and vitamin D - at the recommended levels for your first trimester. It’s difficult to get enough of these nutrients from food, which is why it’s important to take them in supplement form. ",
    comprises_img: comprisesImg,
  },
  // {   id: 5,
  //     details_img: detailItem5,
  //     product_card_img: productItem5,
  //     title: "Pregnancy Complete T1",
  //     text_list: [
  //         "Supports blood system",
  //         "Promotes energy metabolism",
  //         "Beneficial for vegans and vegetarians"
  //     ],
  //     content: 'Our pregnancy Essentials T1 supplement is designed for mums-to-be who don’t have any dietary restrictions and are managing to eat a healthy balanced diet.  A healthy balanced diet will provide you with most of the nutrients you and your baby need, with a few exceptions - and that’s where our carefully curated Pregnancy Essentials T1 comes in. Alongside a healthy balanced diet (which we’ll be helping you with through our companion app!) our Pregnancy Essentials T1 will help meet yours and your baby’s nutritional needs.',
  //     advantages: ['Free from XXX', 'Gluten free ', 'Easy to consume', 'Flavourless'],
  //     comprises: 'Our Pregnancy Essentials T1 contains the two nutrients all women need in supplement form during pregnancy - folic acid and vitamin D - at the recommended levels for your first trimester. It’s difficult to get enough of these nutrients from food, which is why it’s important to take them in supplement form. ',
  //     comprises_img: comprisesImg,
  //
  // },
];

export const cardIcon = [visaMethod, masterMethod];
export const country = [
  "US",
  "AT",
  "AR",
  "AU",
  "BE",
  "BG",
  "BO",
  "CA",
  "CH",
  "CL",
  "CO",
  "CR",
  "CY",
  "CZ",
  "DE",
  "DK",
  "DO",
  "EE",
  "EG",
  "ES",
  "FI",
  "FR",
  "GB",
  "GR",
  "HK",
  "HR",
  "HU",
  "ID",
  "IE",
  "IL",
  "IS",
  "IT",
  "LI",
  "LT",
  "LU",
  "LV",
  "MT",
  "MX",
  "NL",
  "NO",
  "NZ",
  "PE",
  "PL",
  "PT",
  "PY",
  "RO",
  "SE",
  "SG",
  "SI",
  "SK",
  "TH",
  "TT",
  "UY",
];
export const checkoutInputData = [
  {
    class_name: "card-infoAddress-inputOne",
    placeholder: "Address line 1",
    name: "line1",
    shippingName: "shipping_line1",
  },
  {
    class_name: "card-infoAddress-inputTwo",
    placeholder: "Address line 2",
    name: "line2",
    shippingName: "shipping_line2",
  },
  {
    class_name: "card-infoCountry-input",
    placeholder: "City",
    name: "city",
    shippingName: "shipping_city",
  },
  // {
  //   class_name: "card-infoCountry-input",
  //   placeholder: "Country",
  //   name: "country",
  // },
  {
    class_name: "card-infoCountry-input",
    placeholder: "Postcode",
    name: "postal_code",
    shippingName: "shipping_postal_code",
  },
];

export const suggestionProducts = [
  "dairy_free_t1",
  "flexitarian_t1",
  "plant_based_t1",
  "pregnancy_essentials_t1",
  "pregnancy_complete_t1",
];
export const suggestionProductsT2 = [
  "dairy_free_t2",
  "flexitarian_t2",
  "plant_based_t2",
  "pregnancy_essentials_t2",
  "pregnancy_complete_t2",
];

export const fallbackValues = {
  userName: "for the answers",
  elements: {
    program: {
      id: "Pregnancy_Essentials_Trimester_1",
      img: recItem1,
      title: "Pregnancy Essentials Trimester 1",
      text: "Pregnancy Essentials T1 contains the two vitamins all women need in supplement form during pregnancy - folic acid and vitamin D - at the recommended levels for your first trimester.We’ve also included high quality DHA, a type of omega-3 which contributes to the normal development of your baby’s brain and eyes at a daily intake of 200 milligrams.",
    },
    appInfo: {
      img: recItem2,
      img_icon_plus: iconPlusRed,
      title: "Holistic wellbeing and nutrition tailored for your pregnancy",
      text: "Welcome to Trimester 1. Get the confidence and reassurance you need with our holistic wellness and nutrition guidance that’s tailored to your pregnancy.",
    },
    oooCoach: {
      img: recItem3,
      title: "Prenatal experts on tap to prepare you for your birth",
      text: "Get unlimited access to prenatal expert coaches who will provide you with one-to-one chat support for all your physical pregnancy needs, as and when you need it.",
    },
  },
};

export const programData = [
  {
    img: recItem2,
    title: "Holistic wellness and nutrition tailored for your pregnancy",
    text: "Welcome to Trimester 1. Get the confidence and reassurance you need with our holistic wellness and nutrition guidance that’s tailored to your pregnancy.",
  },
  {
    img: recItem3,
    title: "Prenatal experts on tap to prepare you for your birth",
    text: "Get unlimited access to prenatal expert coaches who will provide you with one-to-one chat support for all your physical pregnancy needs, as and when you need it.",
  },
];

export const defaultStateShowDetails = {
  showDetail: false,
};

export const tags = {
  symptoms: {
    constipation: "constipation",
    heartburn: "heartburn",
    feeling_full_quickly_advice: "feeling_full_quickly_advice",
    pregnancy_fatigue: "pregnancy_fatigue",
    nausea: "nausea",
  },
  foodAdvice: {
    healthy_food_advice: "healthy_food_advice",
    weight_gain_advice: "weight_gain_advice",
    foods_to_avoid_advice: "foods_to_avoid_advice",
    alcohol_advice: "alcohol_advice",
    caffeine_advice: "caffeine_advice",
    healthy_snacking_advice: "healthy_snacking_advice",
  },
  prenatalIssues: {
    ankle_pain: "ankle_pain",
    back_pain: "back_pain",
    flat_feet: "flat_feet",
    dizziness: "dizziness",
    balance: "balance",
    sleep: "sleep",
    bleeding_gums: "bleeding_gums",
    anxiety: "anxiety",
    calcium: "calcium",
    craving: "craving",
    hydration: "hydration",
    headache: "headache",
    energy: "energy",
    fibre: "fibre",
  },
};

export enum currentDietEnum {
  omnivore = "omnivore",
  flexitarian = "flexitarian",
  plantBased = "plant_based",
  dairyFree = "dairy_free",
  unknown = "unknown",
}

export enum foodIntakeEnum {
  normal = "normal",
  reduced = "reduced",
  compromised = "compromised",
}

export const initialShippingData = {
  address: {
    city: "",
    line1: "",
    line2: "",
    postal_code: "",
    country: "GB",
  },
  name: "",
};
export const initialBillingData: BillingData = {
  address: {
    city: "",
    country: "GB",
    line1: "",
    line2: "",
    postal_code: "",
  },
  name: "",
};
export const initialFormikData = {
  city: "",
  country: "GB",
  line1: "",
  line2: "",
  postal_code: "",
  name: "",
  shipping_city: "",
  shipping_line1: "",
  shipping_line2: "",
  shipping_postal_code: "",
  firstName: '',
  lastName: '',
  email: '',
  password: ''

};
export const initialFormikData4ExistedUser = {
  city: "",
  country: "GB",
  line1: "",
  line2: "",
  postal_code: "",
  name: "",
  shipping_city: "",
  shipping_line1: "",
  shipping_line2: "",
  shipping_postal_code: "",

};
export const termData:TermsData ={show: false, accepted: false, currentContent: 'terms'}

export const defaultNavState = {
  prevStepId: "",
  currentStepId: "stp0001",
  nextStepId: "stp0002",
  altNextStepId: "",
};

interface imagesInterface {
  [key: string]: any;
}

export const supplementIngredientImages: imagesInterface = {
  dairy_free_t1: supplementIngredients.supplementImage6,
  flexitarian_t1: supplementIngredients.supplementImage2,
  plant_based_t1: supplementIngredients.supplementImage4,
  pregnancy_essentials_t1: supplementIngredients.supplementImage0,
  pregnancy_complete_t1: supplementIngredients.supplementImage5a,
  dairy_free_t2: supplementIngredients.supplementImage7,
  flexitarian_t2: supplementIngredients.supplementImage3,
  plant_based_t2: supplementIngredients.supplementImage5,
  pregnancy_essentials_t2: supplementIngredients.supplementImage1,
  pregnancy_complete_t2: supplementIngredients.supplementImage5b,
};
