import React, { useEffect, useState } from "react";
import "./Home.scss";
import HeaderMain from "../../components/_common/HeaderMain/HeaderMain";
import FooterMain from "../../components/_common/FooterMain/FooterMain";
import SectionOne from "../../components/Home/sectionOne/sectionOne";
import SectionSecond from "../../components/Home/sectionSecond/sectionSecond";
import SectionThree from "../../components/Home/sectionThree/sectionThree";
import SectionFour from "../../components/Home/sectionFour/sectionFour";
import SectionFive from "../../components/Home/sectionFive/sectionFive";
import SectionSix from "../../components/Home/sectionSix/sectionSix";
import SectionHead from "../../components/Home/sectionHead/sectionHead";
import {getAuth, onAuthStateChanged, signInAnonymously} from "firebase/auth";
import { auth } from "../../configs/firebase";
import useAccount from "../../hooks/useAccount";
import { useRecommendations } from "../../hooks/useRecommendations";
import Loader from "../../components/_common/Loader/Loader";
import logo from "../../assets/static/svg/bianu_logo.svg";

import { Routes } from "../../configs/routes/routes";
import { useHistory, useLocation, useParams } from "react-router-dom";
import SectionStories from "../../components/Home/sectionStories/sectionStories";
import LastSection from "../../components/Home/lastSection/lastSection";
import SliderSection from "../../components/Home/sliderSection/SliderSection";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import LandingFooter from "../../components/LandingFooter/LandingFooter";
import ReactPixel from "react-facebook-pixel";
import Banner from "../../components/Banner/Banner";

function Home() {


  const { setRecommendationsAgain } = useAccount();
  const { loadSupplements } = useRecommendations();
  const history = useHistory();
  const windowLocation = window.location.href.includes("try.");
  const [menuVisible, setMenuVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [authd, setAuthd] = useState(false);
  const menuVisibleHandler = () => {
    setMenuVisible(!menuVisible);
  };
  const { accountData } = useSelector((state: RootState) => state.account);
  const { loadAccount } = useAccount();
  useEffect(() => {
    const listener = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthd(true);
        setRecommendationsAgain(user.uid);
        loadAccount(user.uid);
        loadSupplements().then(() => setIsLoading(false));
      } else {
        setAuthd(false);
        setIsLoading(false);
      }
    });
    return () => listener();
  }, []);
  const startPersonalBtn = () => {
    if (accountData && accountData.hasCompletedQuizPart1) {
      history.push(Routes.RECOMMENDATION);
    } else {
      //@ts-ignore
      window.cqet("send", "CustomEvent", {
        eventName: "Start quiz button clicked on home page",
      });
      ReactPixel.trackCustom("Start quiz button clicked on home page")
      history.push(Routes.QUIZ);
    }
  };
  return (
   <>
     <Banner/>
     <div className="home">
       {isLoading ? (
         <div
           style={{
             width: "100%",
             height: "100vh",
             display: "flex",
             justifyContent: "center",
             alignItems: "center",
           }}
         >
           <Loader />
         </div>
       ) : (
         <div className={"home_wrapper"}>
           {!windowLocation && (
             <HeaderMain menuBtnHandler={menuVisibleHandler} />
           )}
           {windowLocation && (
             <div className={"landingLogo"}>
               <img src={logo} alt="logo" />
             </div>
           )}
           <SectionHead
             menuVisible={menuVisible}
             menuBtnHandler={menuVisibleHandler}
             clickBtnHandler={startPersonalBtn}
           />
           <SectionOne />
           <SectionSecond clickBtnHandler={startPersonalBtn} />
           <SectionStories />
           <SliderSection />
           <SectionThree clickBtnHandler={startPersonalBtn} />
           <SectionFour />
           <SectionFive clickBtnHandler={startPersonalBtn} />
           <SectionSix clickBtnHandler={startPersonalBtn} />
           <LastSection clickBtnHandler={startPersonalBtn} />
           {windowLocation && <LandingFooter />}
           {!windowLocation && <FooterMain linksShown={true} />}
         </div>
       )}
     </div>
   </>
  );
}

export default Home;
