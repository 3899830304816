import {currentDietEnum, foodIntakeEnum, stepTypes} from "./defaultData";
import {SVG} from "./svg";
import {Step} from "../types/quizTypes";

export const webQuiz: { steps: Step[] } = {
  steps: [
    {
      id: "stp0001",
      type: stepTypes.INPUT,
      header: "Let’s start with your name",
      inputs: [
        {
          id: 0,
          inputPlaceHolder: "Enter first name",
          name: "firstName",
        },
      ],
      btnText: "Continue",
      nextStepId: "stp0002",
      prevStepId: "",
      altNextStep: "",
      isReset: false,
      isCheckpoint: true,
      stepOptions: {
        altNextStep: "",
        bottomText: "Already got an account?",
        linkText: "Log in here",
        linkHref: "/login",
      },
      displayStepNumber: 1,
    },
    {
      id: "stp0002",
      type: stepTypes.DATE,
      header: "When’s your date of birth?",
      inputPlaceHolders: ["dd", "mm", "yyyy"],
      btnText: "Continue",
      nextStepId: "stp0003",
      altNextStep: "",
      prevStepId: "stp0001",
      isCheckpoint: true,
      isReset: false,
      isBirthday: true,
      stepOptions: {
        altNextStep: "",
        bottomText: "",
        linkText: "",
      },
      subType: "dob",
      displayStepNumber: 2,
    },
    {
      id: "stp0003",
      type: stepTypes.DATE,
      header: "When’s your due date?",
      inputPlaceHolders: ["dd", "mm", "yyyy"],
      inputLabel: "",
      btnText: "Continue",
      nextStepId: "stp0008",
      altNextStep: "stp0006",
      prevStepId: "stp0002",
      isCheckpoint: true,
      isBirthday: false,
      stepOptions: {
        altNextStep: "stp0004",
        bottomText: "Don’t know your due date? ",
        linkText: "Use our calculator",
      },
      cycleLength: false,
      isReset: false,
      subType: "due",
      displayStepNumber: 3,
    },
    {
      id: "stp0006",
      type: stepTypes.DATE,
      header: "Calculate due date",
      inputPlaceHolders: ["dd", "mm", "yyyy"],
      inputLabel: "The first day of your last period",
      btnText: "CALCULATE",
      nextStepId: "stp0007",
      altNextStep: "stp0003",
      prevStepId: "stp0003",
      isCheckpoint: true,
      isBirthday: false,
      stepOptions: {
        altNextStep: "stp0003",
        bottomText: "Remembered the date? ",
        linkText: "Go back to quiz",
      },
      cycleLength: true,
      isReset: false,
      subType: "lastPer",
      displayStepNumber: 3,
    },
    {
      id: "stp0007",
      altNextStep: "",
      prevStepId: "stp0006",
      type: stepTypes.CONFIRM,
      header: "Your due date is ",
      img: SVG.FORM_IMAGE,
      btnText: "CONTINUE",
      nextStepId: "stp0008",
      isCheckpoint: false,
      isReset: false,
      displayStepNumber: 3,
    },
    {
      id: "stp0008",
      altNextStep: "",
      prevStepId: "stp0003",
      type: stepTypes.SINGLE,
      options: [
        {text: "Yes", action: "showWarning"},
        {text: "No", action: ""},
      ],
      modal: {
        supplements: {
          header: "If you’re already taking supplements",
          subHeader:
            "What you need to know if you’re already taking supplements",
          text: [
            "Our supplements are designed to help you get all the nutrients you need during pregnancy when consumed alongside a healthy balanced diet. For this reason, we recommend stopping other vitamin and mineral supplements to avoid the risk of consuming in excess of the safe level.",
            "If you're taking a supplement that contains a nutrient not supplied in your Bianu supplement (for example you're taking an iron supplement and your recommended Bianu supplement doesn't contain iron) you may choose to continue. If you're unsure, always seek the advice of your doctor or healthcare professional",
          ],
          button: {
            title: "I understand",
            type: "close",
          },
        },
      },
      header: "Are you currently taking any pregnancy or general supplements?",
      nextStepId: "stp0009",
      bottomText: "",
      isCheckpoint: true,
      isReset: false,
      displayStepNumber: 4,
    },
    {
      id: "stp0009",
      prevStepId: "stp0008",
      type: stepTypes.SINGLE,
      options: [
        {
          text: "I eat meat, fish, and dairy",
          action: "",
          enum: currentDietEnum.omnivore,
        },
        {
          text: "I don’t eat much meat, fish, or dairy, or I’m a vegetarian",
          action: "",
          enum: currentDietEnum.flexitarian,
        },
        {
          text: "I follow a vegan or completely plant-based diet",
          action: "",
          enum: currentDietEnum.plantBased,
        },
        {
          text: "I am dairy free but I do eat meat and fish",
          action: "",
          enum: currentDietEnum.dairyFree,
        },
      ],
      header: "Which of these best describes what you eat?",
      nextStepId: "stp0010",
      altNextStep: "stp0010",
      bottomText: "",
      bottomHtml: false,
      isCheckpoint: true,
      isReset: false,
      displayStepNumber: 5,
    },
    {
      id: "stp0010",
      altNextStep: "stp00",
      prevStepId: "stp0009",
      options: [
        {text: "Yes", action: "end"},
        {text: "No", action: "end"},
      ],
      type: stepTypes.SINGLE,
      header:
        "Is pregnancy nausea and/or sickness affecting what you eat?",
      nextStepId: "",
      bottomText: "",
      bottomHtml: false,
      isCheckpoint: true,
      isReset: false,
      displayStepNumber: 6,
    },
    {
      id: "stp0011",
      altNextStep: "stp0010",
      prevStepId: "stp0009",
      type: stepTypes.SINGLE,
      options: [
        {text: "Yes", action: "next"},
        {text: "No", action: "altNext"},
      ],
      header:
        "Are you finding that nausea or sickness are affecting your appetite and diet?",
      nextStepId: "stp0012",
      bottomText: "",
      isCheckpoint: true,
      isReset: false,
      displayStepNumber: 6,
    },
    {
      id: "stp0012",
      prevStepId: "stp0011",
      type: stepTypes.SINGLE,
      options: [
        {
          text: "Eating same as or more than normal but the type of food has changed",
          action: "next",
          enum: foodIntakeEnum.normal,
        },
        {
          text: "Eating less than normal and my diet is less healthy",
          action: "next",
          enum: foodIntakeEnum.compromised,
        },
        {
          text: "Finding it hard to keep anything down or hard to eat anything",
          action: "next",
          enum: foodIntakeEnum.reduced,
        },
      ],
      header: "Which of these best describes how you eat?",
      nextStepId: "stp0010",
      altNextStep: "stp0013",
      bottomText: "",
      bottomHtml: false,
      isCheckpoint: true,
      isReset: false,
      displayStepNumber: 7,
    },
    // {
    //   id: "stp0013",
    //   altNextStep: "",
    //   prevStepId: "stp0012",
    //   type: stepTypes.SINGLE,
    //   options: [
    //     {
    //       text: "Yes",
    //       action: "sendYes",
    //     },
    //     {text: "No", action: "end"},
    //   ],
    //   header: "Do you have a healthy diet?",
    //   nextStepId: "stp0014",
    //   bottomText: {
    //     header: "By that we mean:",
    //     listItems: [
    //       "Aiming for 5 portions of fruit & veg a day",
    //       "High-fibre starchy foods eg wholemeal bread or potato with the skin on",
    //       "Some protein each day like meat, fish, beans, lentils or eggs",
    //       "Some dairy foods or calcium-fortified dairy alternatives each day",
    //     ],
    //   },
    //   bottomHtml: true,
    //   isCheckpoint: true,
    //   isReset: true,
    //   displayStepNumber: 8,
    // },
    // {
    //   id: "stp0012",
    //   altNextStep: "",
    //   prevStepId: "stp0011",
    //   type: stepTypes.INPUT,
    //   header: "What’s your last name?",
    //   inputPlaceHolder: "ex. Smith",
    //   btnText: "CONTINUE",
    //   nextStepId: "stp0013",
    //   isReset: false,
    //   isCheckpoint: true,
    // },
    // {
    //   id: "stp0013",
    //   altNextStep: "",
    //   prevStepId: "stp0012",
    //   type: stepTypes.LOGIN_PASSWORD,
    //   header: "Create account",
    //   subHeader: "We’ll use this to save your recommendation.",
    //   loginPlaceholder: "jane.emmerson@gmail.com",
    //   pwdPlaceholder: "Password",
    //   btnText: "CONTINUE",
    //   nextStepId: "stp0014",
    //   isCheckpoint: true,
    //   isReset: false,
    // },
    // {
    //   id: "stp0014",
    //   altNextStep: "",
    //   prevStepId: "stp0013",
    //   type: stepTypes.SINGLE,
    //   options: [
    //     { text: "Yes", action: "" },
    //     { text: "I’ll do this later", action: "reset" },
    //   ],
    //   header:
    //     "Can we ask you a few more questions to further personalise your experience?",
    //   nextStepId: "stp0015",
    //   bottomText: "",
    //   isCheckpoint: false,
    //   isReset: true,
    // },
    // {
    //   id: "stp0015",
    //   altNextStep: "",
    //   prevStepId: "stp0014",
    //   type: stepTypes.SINGLE,
    //   options: [
    //     { text: "A single baby", action: "" },
    //     { text: "Twins or multiples", action: "" },
    //   ],
    //   header: "Are you expecting…",
    //   nextStepId: "stp0016",
    //   bottomText: "",
    //   bottomHtml: false,
    //   isCheckpoint: true,
    //   isReset: false,
    // },
    // {
    //   id: "stp0016",
    //   altNextStep: "",
    //   prevStepId: "stp0015",
    //   type: stepTypes.MULTIPLE,
    //   listOptions: [
    //     {
    //       displayText: "Feeling or being sick",
    //       tag: tags.symptoms.nausea,
    //     },
    //     {
    //       displayText: "Constipation",
    //       tag: tags.symptoms.constipation,
    //     },
    //     {
    //       displayText: "Heartburn",
    //       tag: tags.symptoms.heartburn,
    //     },
    //     {
    //       displayText: "Tiredness or fatigue",
    //       tag: tags.symptoms.pregnancy_fatigue,
    //     },
    //     {
    //       displayText: "Feeling full quickly",
    //       tag: tags.symptoms.feeling_full_quickly_advice,
    //     },
    //   ],
    //   header:
    //     "Are you currently experiencing any of the following pregnancy symptoms?",
    //   nextStepId: "stp0017",
    //   btnText: "CONTINUE",
    //   bottomText: "",
    //   isCheckpoint: true,
    //   isReset: false,
    // },
    // {
    //   id: "stp0017",
    //   altNextStep: "",
    //   prevStepId: "stp0016",
    //   type: stepTypes.SINGLE,
    //   options: [
    //     { text: "Yes", action: "" },
    //     { text: "No", action: "" },
    //   ],
    //   header: "Are you drinking any caffeinated drinks?",
    //   nextStepId: "stp0018",
    //   bottomText: "",
    //   bottomHtml: false,
    //   isCheckpoint: true,
    //   isReset: false,
    // },
    // {
    //   id: "stp0018",
    //   altNextStep: "",
    //   prevStepId: "stp0017",
    //   type: stepTypes.SINGLE,
    //   options: [
    //     { text: "Less than 8", action: "" },
    //     { text: "8 or more", action: "" },
    //   ],
    //   header: "How many cups of fluid are you drinking each day?",
    //   nextStepId: "stp0019",
    //   bottomText: "1 сup is 250ml (about the size of a mug)",
    //   isCheckpoint: true,
    //   bottomHtml: false,
    //   isReset: false,
    // },
    // {
    //   id: "stp0019",
    //   altNextStep: "",
    //   prevStepId: "stp0018",
    //   type: stepTypes.MULTIPLE,
    //   listOptions: [
    //     {
    //       displayText: "Healthy eating",
    //       tag: tags.foodAdvice.healthy_food_advice,
    //     },
    //     {
    //       displayText: "Weight gain",
    //       tag: tags.foodAdvice.weight_gain_advice,
    //     },
    //     {
    //       displayText: "Foods to avoid",
    //       tag: tags.foodAdvice.foods_to_avoid_advice,
    //     },
    //     {
    //       displayText: "Alcohol",
    //       tag: tags.foodAdvice.alcohol_advice,
    //     },
    //     {
    //       displayText: "Caffeine",
    //       tag: tags.foodAdvice.caffeine_advice,
    //     },
    //     {
    //       displayText: "Healthy snacking",
    //       tag: tags.foodAdvice.healthy_snacking_advice,
    //     },
    //   ],
    //   header:
    //     "Are there any areas of pregnancy nutrition you’d like to learn more about?",
    //   nextStepId: "stp0020",
    //   btnText: "CONTINUE",
    //   bottomText: "",
    //   isCheckpoint: true,
    //   isReset: false,
    // },
    // {
    //   id: "stp0020",
    //   altNextStep: "",
    //   prevStepId: "stp0019",
    //   type: stepTypes.MULTIPLE,
    //   listOptions: [
    //     {
    //       displayText: "Ankle pain",
    //       tag: tags.prenatalIssues.ankle_pain,
    //     },
    //     {
    //       displayText: "Back pain",
    //       tag: tags.prenatalIssues.back_pain,
    //     },
    //     {
    //       displayText: "Flat feet",
    //       tag: tags.prenatalIssues.flat_feet,
    //     },
    //     {
    //       displayText: "Dizziness",
    //       tag: tags.prenatalIssues.dizziness,
    //     },
    //     {
    //       displayText: "Balance",
    //       tag: tags.prenatalIssues.balance,
    //     },
    //     {
    //       displayText: "Better sleep",
    //       tag: tags.prenatalIssues.sleep,
    //     },
    //     {
    //       displayText: "Bleeding gums",
    //       tag: tags.prenatalIssues.bleeding_gums,
    //     },
    //     {
    //       displayText: "Anxiety",
    //       tag: tags.prenatalIssues.anxiety,
    //     },
    //     {
    //       displayText: "Calcium intake",
    //       tag: tags.prenatalIssues.calcium,
    //     },
    //     {
    //       displayText: "Cravings",
    //       tag: tags.prenatalIssues.craving,
    //     },
    //     {
    //       displayText: "Staying hydrate",
    //       tag: tags.prenatalIssues.hydration,
    //     },
    //     {
    //       displayText: "Headaches",
    //       tag: tags.prenatalIssues.headache,
    //     },
    //     {
    //       displayText: "Energy levels",
    //       tag: tags.prenatalIssues.energy,
    //     },
    //     {
    //       displayText: "Fibre intake",
    //       tag: tags.prenatalIssues.fibre,
    //     },
    //   ],
    //   header: "Would you like help with any of these?",
    //   nextStepId: "",
    //   btnText: "CONTINUE",
    //   bottomText: "",
    //   isCheckpoint: false,
    //   isReset: true,
    // },
  ],
};
