import { useDispatch } from "react-redux";
import { getRecommendations, getSrc } from "../utils/UtilsCommon";
import {
  actionSetProduct,
  actionSetRec,
  actionSetSupplements,
} from "../store/actions/rec-actions";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { db } from "../configs/firebase";

export const useRecommendations = () => {
  const dispatch = useDispatch();
  const setRecommendations = async (trimester: number, uid: string) => {
    const quizObj = await getDoc(
      doc(db, "users", uid, "userData", "supplementQuiz")
    );
    const forDispatch = getRecommendations(
      trimester,
      quizObj?.data()?.hasSickness,
      quizObj?.data()?.foodIntakeEnum,
      quizObj?.data()?.currentDietEnum,
      quizObj?.data()?.healthyDiet
    );
    // LocalStorage.set("productId", forDispatch.favorite);
    dispatch(actionSetRec(forDispatch));
  };
  const loadProduct = async (productId: string) => {
    try {
      const product = await getDoc(
        doc(db, "bianu_1.00", "en_uk", "products", productId)
      );
      dispatch(actionSetProduct(product.data()));
    } catch (e) {
      console.log(e);
    }
  };
  const loadSupplements = async () => {
    try {
      const query = await getDocs(
        collection(db, "bianu_1.00", "en_uk", "products")
      );
      const products = query.docs.map((doc) => doc.data());
      await Promise.all(
        products.map(async (prod) => {
          prod.imagePath = await getSrc(prod.imagePath);
        })
      );
      dispatch(actionSetSupplements(products));
    } catch (e) {
      console.log(e);
    }
  };
  const setRecommendedProduct = async (uid: string, product: string) => {
    try {
      const userRef = doc(db, "users", uid, "userData", "user");
      await updateDoc(userRef, { recommendedProduct: product });
    } catch (e) {
      console.log(e);
    }
  };
  return {
    setRecommendations,
    setRecommendedProduct,
    loadProduct,
    loadSupplements,
  };
};
