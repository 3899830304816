import React, {useEffect, useState} from "react";
import "./Confirmation.scss";
import avatarConf from "../../assets/static/svg/confirm_icon.png";
import appStoreBtn from "../../assets/static/icon/appstoreButton.svg";
import googlePlayBtn from "../../assets/static/icon/googleplayButton.svg";
import programIcon1 from "../../assets/static/recommendation_img/rec-item1.svg";
import programIcon2 from "../../assets/static/recommendation_img/rec-item2.svg";
import programIcon3 from "../../assets/static/recommendation_img/rec-item3.svg";
import doneIcon from "../../assets/static/svg/Done.svg";
import Header from "../_common/Header/Header";
import {onAuthStateChanged} from "firebase/auth";
import {auth, firebaseConfig} from "../../configs/firebase";
import useAccount from "../../hooks/useAccount";
import {useRecommendations} from "../../hooks/useRecommendations";
import {useSelector} from "react-redux";
import Loader from "../_common/Loader/Loader";
import {checkTrimester, renderConfirmationText} from "../../utils/UtilsCommon";
import {UtilsPregnancy} from "../../utils/UtilsPregnancy";
import FooterMain from "../_common/FooterMain/FooterMain";
import {getDownloadURL, getStorage, ref} from "firebase/storage";

function Confirmation() {
  const storage = getStorage();

  const {loadSupplements} = useRecommendations();
  const {loadAccount, setRecommendationsAgain, loadOrderData} = useAccount();
  const [isLoading, setIsLoading] = useState(true);
  const {supplements} = useSelector((state: any) => state.rec);
  const {accountData, orderData} = useSelector((state: any) => state.account);
  const [prodImage, setProdImage] = useState<string>('')

  useEffect(() => {
    const listener = onAuthStateChanged(auth, (user) => {
      if (user) {
        loadAccount(user.uid);
        setRecommendationsAgain(user.uid);
        loadOrderData(user.uid);
      }
    });
    return () => listener();
  }, []);
  useEffect(() => {
    loadSupplements().then(() => setIsLoading(false));
  }, []);
  const prodFinder = supplements.find(
    (item: any) => item.id === orderData.productId
  );
  const getSrc = async () => {
    if(prodFinder){
      return await getDownloadURL(
        ref(
          storage,
          `gs://${firebaseConfig.storageBucket}/${prodFinder.webImagePath}`
        )
      )
    }
  }
  useEffect(() => {
    getSrc().then((url) => url && setProdImage(url))
  }, [prodFinder])

  const week = UtilsPregnancy.getCurrentWeek(accountData.dueDate);


  return (
    <div className={"confirmation"}>
      <Header/>
      {isLoading ? (
        <Loader/>
      ) : (
        <>
          <div className={"confirmation_header"}>
            <img src={doneIcon} alt="done icon"/>
            <h2>Subscription confirmed!</h2>
            <p>Please check your email</p>
          </div>
          <div className={"confirmation-container"}>
            <div>
              <div className={"confirmation-sub"}>
                <h2 className={"confirmation-sub_title"}>
                  {renderConfirmationText()}
                </h2>
                <p className={"confirmation-sub_text"}>
                  Prepare for birth programme
                </p>
                <div className={"confirmation-items"}>
                  <div className={"confirmation-item"}>
                    <img
                      className={"confirmation-item_img"}
                      src={programIcon1 && prodImage}
                      alt=""
                    />
                    <p className={"confirmation-item_text"}>
                      {"Pregnancy Essentials Trimester 1" && prodFinder.name}
                    </p>
                  </div>
                  <div className={"confirmation-item"}>
                    <img
                      className={"confirmation-item_img"}
                      src={programIcon2}
                      alt=""
                    />
                    <p className={"confirmation-item_text"}>
                      Holistic wellness and nutrition tailored for your
                      pregnancy
                    </p>
                  </div>

                  <div className={"confirmation-item"}>
                    <img
                      className={"confirmation-item_img"}
                      src={programIcon3}
                      alt=""
                    />
                    <p className={"confirmation-item_text"}>
                      Prenatal experts on tap to prepare you for your birth
                    </p>
                  </div>
                </div>
              </div>
              <div className={"confirmation-advantages"}>
                <div className={"confirmation-advantages_container"}>
                  <h2 className={"confirmation-advantages_title"}>
                    What’s waiting for you in the Bianu app
                  </h2>
                  <div className={"confirmation-advantages_item"}>
                    <div className={"confirmation-advantages_itemTitle"}>
                      Nutrition Programme: Prepare for Birth, week { week ? week : ''}
                    </div>
                    <div className={"confirmation-advantages_itemText"}>
                      Nutrition in pregnancy isn't just about a supplement -
                      from eating well to keeping on top of the advice around
                      the foods you need to limit or avoid, there is a lot to
                      think about. Our Prepare for Birth programme has you
                      covered.
                    </div>
                  </div>
                  <div className={"confirmation-advantages_item"}>
                    <div className={"confirmation-advantages_itemTitle"}>
                      What to focus on next
                    </div>
                    <div className={"confirmation-advantages_itemText"}>
                      Welcome to {checkTrimester(orderData.productId)}. We know
                      there's a lot on your mind in these exciting early weeks
                      of pregnancy. We hope to make things easier by sharing
                      nutrition information when you need it. Over the coming
                      weeks, you'll see advice and tips from our nutrition
                      experts appearing in the app based on the topics you're
                      interested in, and your stage of pregnancy.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={"confirmation-app"}>
              <p className={"confirmation-app_title"}>
                Download the Bianu app now to get started with your personal
                plan
              </p>
              <p className={"confirmation-app_text"}>
                Included in your subscription
              </p>
              <div className={"confirmation-app_content"}>
                <div className={"confirmation-app_content_popUp"}>
                  <img src={avatarConf} alt="avatar icon"/>
                  <p>
                    Hi {accountData.firstName}, what can I help you with today?
                    ☺️
                  </p>
                </div>
              </div>
              <div className={"confirmation-app_buttons"}>
                <a
                  href={"https://apps.apple.com/gb/app/bianu/id1587023269"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className={"confirmation-app_button"}
                    src={appStoreBtn}
                    alt=""
                  />
                </a>
                <a
                  href={"https://play.google.com/store/apps/details?id=com.bianu&hl=en_GB"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className={"confirmation-app_button"}
                    src={googlePlayBtn}
                    alt="google play "
                  />
                </a>
              </div>
            </div>
            <div className={"confirmation-advantagesMobile"}>
              <div className={"confirmation-advantages_container"}>
                <h2 className={"confirmation-advantages_title"}>
                  What’s waiting for you in the Bianu app
                </h2>
                <div className={"confirmation-advantages_item"}>
                  <div className={"confirmation-advantages_itemTitle"}>
                    Nutrition Programme: Prepare for Birth, week {week ? week : ''}
                  </div>
                  <div className={"confirmation-advantages_itemText"}>
                    Nutrition in pregnancy isn't just about a supplement - from
                    eating well to keeping on top of the advice around the foods
                    you need to limit or avoid, there is a lot to think about.
                    Our Prepare for Birth programme has you covered.
                  </div>
                </div>
                <div className={"confirmation-advantages_item"}>
                  <div className={"confirmation-advantages_itemTitle"}>
                    What to focus on next
                  </div>
                  <div className={"confirmation-advantages_itemText"}>
                    Welcome to {checkTrimester(orderData.productId)}. We know
                    there's a lot on your mind in these exciting early weeks of
                    pregnancy. We hope to make things easier by sharing
                    nutrition information when you need it. Over the coming
                    weeks, you'll see advice and tips from our nutrition experts
                    appearing in the app based on the topics you're interested
                    in, and your stage of pregnancy.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <FooterMain linksShown={true}/>
    </div>
  );
}

export default Confirmation;
