import React, { useCallback } from "react";
import "./PaymentSummary.scss";
import { LocalStorage } from "../../services/storage";
const numbers = [
  "One",
  "Two",
  "Three",
  "Four",
  "Five",
  "Six",
  "Seven",
  "Eight",
  "Nine",
  "Ten",
];

interface Props {
  [key: string]: any;
  plan: string;
  promoCode: string;
  shipping: string;
  total: number | string;
  saved: number | string;
}

interface element {
  title: string;
  isTotal: boolean;
  dataSource: string;
}

const elements: element[] = [
  {
    title: "Subscription Plan",
    isTotal: false,
    dataSource: "plan",
  },
  {
    title: "PROMO CODE",
    isTotal: false,
    dataSource: "promoCode",
  },
  {
    title: "Shipping",
    isTotal: false,
    dataSource: "shipping",
  },
  {
    title: "Total due",
    isTotal: true,
    dataSource: "total",
  },
  {
    title: "Total saved",
    isTotal: true,
    dataSource: "saved",
  },
];

function PaymentSummary(props: Props) {
  const localStorageMonthCount = LocalStorage.get("monthCount");
  const localStorageSelectedPlan = LocalStorage.get("selectedPlan");
  // const { plan, promoCode, saved, shipping, total } = props;
  const mapElements = useCallback(
    (list) => {
      return list.map((el: element) => {
        return el.dataSource === "plan" ? (
          <div className="row">
            <h4>{el.title}</h4>
            <h4>
              {localStorageSelectedPlan === "monthly"
                ? "Monthly Plan"
                : "Pregnancy Plan"/*`${numbers.find(
                    (num, index) => index === Number(localStorageMonthCount) - 1
                  )} month subscription`*/}
            </h4>
          </div>
        ) : (
          <div className="row">
            {el.isTotal ? (
              <>
                <h4>{el.title}</h4>
                <h4>{props[el.dataSource]}</h4>
              </>
            ) : (
              <>
                <span>{el.title}</span>
                <span>{props[el.dataSource]}</span>
              </>
            )}
          </div>
        );
      });
    },
    [props, localStorageMonthCount, localStorageSelectedPlan]
  );
  return (
    <div className="payment-summary-container">
      <h1 className={'payment-summary-container_title'}>Payment summary</h1>
      {mapElements(elements)}
    </div>
  );
}

export default PaymentSummary;
