import { Field } from "formik";
import React from "react";
import { checkoutInputData } from "../../constants/defaultData";

interface Props {
  paymentBillingDataHandler: any;
  checked: boolean;
  BillingAddressCountry: any;
  setBillingAddressCountry: any;
  showList: any;
  setShowList: any;
}

function BillingInputs({  checked }: Props) {
  const renderBillingInputs = () =>
    checkoutInputData.map((item: any, index: number) => {
      if(item.name === 'line2'){
        return (
            <Field
                key={index}
                type={"text"}
                name={item.name}
                className={item.class_name}
                placeholder={item.placeholder}
                autoFocus={false}
            />
        )
      }else {
        return (
            <Field
                key={index}
                type={"text"}
                required={!checked}
                name={item.name}
                className={item.class_name}
                placeholder={item.placeholder}
                autoFocus={false}
            />
        )
      }
    });
  return (
    <div
      style={checked ? { display: "none" } : { display: "grid" }}
      className={"checkout-cardInfo_address"}
    >
      {renderBillingInputs()}
    </div>
  );
}

export default BillingInputs;
