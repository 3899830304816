import { getFunctions, httpsCallable } from "firebase/functions";
import { useDispatch } from "react-redux";
import {
  actionGetDefaultPayMethodAC,
  actionGetKeySTRAC,
  actionGetPayMethodAC,
} from "../store/actions/account-actions";

export interface subObj {
  priceId: string;
  productId: string;
  rootPath: string;
  paymentMethodId: string;
  couponId?: string;
}
export interface updSubObg {
  subscriptionId: string;
  paymentMethodId: string;
  productId: string;
  cancelAtPeriodEnd: boolean;
}

function useStripeHook() {
  const dispatch = useDispatch();
  const functions = getFunctions();

  const createStripeCustomerAddress = httpsCallable(
    functions,
    "callableStripeCustomerAddress"
  );
  const createStripeCustomer = httpsCallable(
    functions,
    "callableCreateStripeCustomer"
  );
  const createSetupIntents = httpsCallable(
    functions,
    "callableStripeSetupIntents"
  );
  const createDefaultPaymentMethod = httpsCallable(
    functions,
    "callableStripeCustomerDefaultPaymentMethod"
  );
  const updateSubscription = httpsCallable(
    functions,
    "callableUpdateStripeSubscription"
  );
  const createSubscription = httpsCallable(
    functions,
    "callableCreateStripeSubscription"
  );
  const getPaymentMethods = httpsCallable(
    functions,
    "callablePaymentMethodList"
  );
  const deleteOnePaymentMethod = httpsCallable(
    functions,
    "callableDeletePaymentMethod"
  );
  const firstCreateStripeCustomer = async () => {
    await createStripeCustomer();
  };
  const createCustomerAddress = async (data: any) => {
    await createStripeCustomerAddress(data);
  };
  const startSetupIntents = async () => {
    const res = await createSetupIntents();
    dispatch(actionGetKeySTRAC(res.data));
  };
  const defaultPaymentMethod = async (id: string) => {
    const res = await createDefaultPaymentMethod({ paymentMethodId: id });
    dispatch(actionGetDefaultPayMethodAC(res.data as string));
    return res;
  };
  const createSubscriptionHandler = async (data: subObj) => {
    return await createSubscription(data);
  };
  const updateSubscriptionHandler = async (data: updSubObg) => {
    return await updateSubscription(data);
  };
  const getUserCards = async () => {
    const cards = await getPaymentMethods({ type: "card" });
    dispatch(actionGetPayMethodAC(cards.data));
  };
  const deletePaymentMethod = async (pmId: string) => {
    await deleteOnePaymentMethod({ paymentMethodId: pmId });
  };

  return {
    firstCreateStripeCustomer,
    startSetupIntents,
    defaultPaymentMethod,
    createSubscriptionHandler,
    updateSubscriptionHandler,
    getUserCards,
    deletePaymentMethod,
    createCustomerAddress,
  };
}
export default useStripeHook;
