import React from "react";
import { Routes } from "../../configs/routes/routes";

const TermsText = () => {
  return (
    <div className={"terms_content_text"}>
      <p className={"terms_content_paragraph"}>
        <h2>
          PLEASE READ THESE TERMS CAREFULLY. BY ACCESSING THE WEBSITE,
          COMPLETING THE QUESTIONNAIRE AND PURCHASING A SUPPLEMENT, AND
          DOWNLOADING AND ACCESSING THE APP YOU AGREE TO THESE TERMS. IF YOU DO
          NOT AGREE TO THESE TERMS PLEASE DO NOT ACCESS THE WEBSITE, COMPLETE
          THE QUESTIONNAIRE AND PURCHASE A SUPPLEMENT, OR DOWNLOAD OR ACCESS THE
          APP .
        </h2>
      </p>
      <p>
        <h2 className={"terms_title"}>INTRODUCTION</h2>
      </p>
      <ol className={"terms_list"}>
        <li>
          <h2 className={"terms_list_header"}>These Terms</h2>
          <p className={"terms_content_paragraph"}>
            These Terms set out the basis on which L3M Technologies (t/a Salus
            Optima) whose registered office is L3M Technologies (t/a Salus
            Optima), C/O Pkf Littlejohn, 15 Westferry Circus, Canary Wharf,
            London, United Kingdom, E14 4HD (hereby referred to as 'Salus
            Optima') with company number 11157173 (“<b>we</b>”, “<b>us</b>”, “
            <b>our</b>”), permits you to use the:
            <ul className={"terms_list_inner"}>
              <li className={"terms_content_paragraph"}>
                Bianu ecosystem (including our Biainsider programme) which is
                made up of, but not necessarily limited to, the digital
                application (the “<b>App</b>”), the website (the ‘<b>Web</b>
                ’), and the physical supplement product and associated
                questionnaire to personalise it (the ‘<b>Supplement</b>’)
              </li>
              <li className={"terms_content_paragraph"}>
                the related online documentation (the “<b>Documentation</b>
                ”); and
              </li>
              <li className={"terms_content_paragraph"}>
                the content and services we provide to you through the{" "}
                <b>App</b> and the <b>Web</b> (the “<b>Services</b>”).
              </li>
            </ul>
          </p>
        </li>
        <li>
          <h2 className={"terms_list_header"}>Disclaimer</h2>
          <p className={"terms_content_paragraph_margin"}>
            THE <b>APP</b>, THE <b>WEB</b>, THE <b>SUPPLEMENT</b> AND THE{" "}
            <b>SERVICES</b> ARE PROVIDED FOR INFORMATION PURPOSES ONLY AND ON AN
            “AS IS” AND “AS AVAILABLE” BASIS. WE RECOMMEND YOU OBTAIN
            PROFESSIONAL OR SPECIALIST ADVICE BEFORE TAKING, OR REFRAINING FROM
            TAKING, ANY ACTION ON THE BASIS OF INFORMATION OBTAINED FROM THE{" "}
            <b>APP</b>, THE <b>WEB</b>, THE <b>SUPPLEMENT</b> OR THE{" "}
            <b>SERVICES</b>. WHILST WE COMPILE THE INFORMATION MADE AVAILABLE TO
            YOU VIA THE <b>APP</b>, THE <b>WEB</b> AND THE <b>SERVICES</b> IN
            GOOD FAITH, WE MAKE NO REPRESENTATIONS, WARRANTIES OR GUARANTEES,
            WHETHER EXPRESS OR IMPLIED, THAT SUCH INFORMATION IS ACCURATE,
            COMPLETE OR UP TO DATE. SUCH INFORMATION IS NOT INTENDED TO REPLACE
            ADVICE FROM YOUR GP OR ANY HEALTHCARE PROFESSIONAL.{" "}
          </p>
          <p className={"terms_content_paragraph_margin"}>
            You must ensure that all information you submit to us via the{" "}
            <b>App</b>, the <b>Web</b> and the <b>Supplements</b> or otherwise
            in connection with the <b>Services</b> is accurate, current and
            complete as this could affect the information which is provided to
            you. We are entitled to rely on any information you submit to us via
            the <b>App</b>, the <b>Web</b> and the <b>Supplements</b> or
            otherwise in connection with the <b>Services</b>.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            Please also note, where you use any of the recipes on our <b>App</b>{" "}
            or follow any of the training programmes, you are responsible (at
            your own cost) for sourcing the ingredients and equipment required
            for such recipes and training; these are not provided as part of
            your Subscription or otherwise as part of our Service. Please also
            ensure you check the nutritional and allergen information given in
            our recipes – the recipes have not been designed specifically for
            you, nor do they account for any allergies or food intolerances you
            may have.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            <b>Supplements</b> are part of your subscription and you will
            receive a set number of boxes as per the subscription that you
            select. Please ensure you check the nutritional and allergen
            information given about our supplements – they have not been
            designed specifically for you, nor do they account for any allergies
            or food intolerances that you may have.
          </p>
        </li>
        <li>
          <h2 className={"terms_list_header"}>YOUR DATA & PRIVACY</h2>
          <p className={"terms_content_paragraph_margin"}>
            Under data protection legislation, we are required to provide you
            with certain information about who we are, how we process your
            personal data and for what purposes and your rights in relation to
            your personal data and how to exercise them. This information is
            provided in our <a href={Routes.PRIVACY_POLICY}>Privacy Policy</a>{" "}
            and it is important that you read that information. Please be aware
            that internet transmissions are never completely private or secure
            and that any message or information you send using the App or any
            Service may be read or intercepted by others, even if there is a
            special notice that a particular transmission is encrypted.
          </p>
        </li>
        <li>
          <h2 className={"terms_list_header"}>CONTACT</h2>
          <p className={"terms_content_paragraph_margin"}>
            <b>Contacting us.</b> If you think the <b>App</b>, the <b>Web</b>,
            the <b>Supplements</b> or the <b>Services</b> are faulty or
            mis-described or wish to contact us for any other reason please
            email our customer service team at support@bianu.co.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            <b>How we will communicate with you.</b> If we have to contact you
            we will do so by email or by phone using the contact details you
            have provided to us.
          </p>
        </li>
        <li>
          <h2 className={"terms_list_header"}>PERMITTED USE</h2>
          <p className={"terms_content_paragraph_margin"}>
            In return for your agreeing to comply with these Terms you may:
            <ul className={"terms_list_inner"}>
              <li className={"terms_content_paragraph"}>
                download a copy of the <b>App</b> and view, use and display the{" "}
                <b>App</b> and the <b>Services</b> on your device for your
                personal purposes only;
              </li>
              <li className={"terms_content_paragraph"}>
                use any <b>Documentation</b> to support your permitted use of
                the <b>App</b>, the <b>Web</b> and the <b>Services</b>; and{" "}
              </li>
              <li className={"terms_content_paragraph"}>
                receive and use any free updates of the <b>App</b> and the{" "}
                <b>Web</b> incorporating “patches” and corrections of errors as
                we may provide to you.
              </li>
              <li className={"terms_content_paragraph"}>
                Access the <b>Web</b>, and view, use and display the <b>Web</b>{" "}
                on your device for your personal purposes only
              </li>
            </ul>
          </p>
          <p className={"terms_content_paragraph_margin"}>
            The ways in which you can use the <b>App</b> and Documentation may
            also be controlled by the rules and policies of the appstore from
            where you downloaded the <b>App</b>. Such rules and policies will
            apply instead of these Terms where there is a conflict between the
            two.
          </p>
        </li>
        <h2 className={"terms_title"}>ACCESS</h2>
        <li>
          <h2 className={"terms_list_header"}>GENERAL ACCESS</h2>
          <p className={"terms_content_paragraph_inner"}>A) To the App</p>
          <p className={"terms_content_paragraph_margin"}>
            You are permitted to access and use the majority of our{" "}
            <b>Services</b>, available via our <b>App</b>, if you purchase a
            subscription. You must be at least 18 years of age to use the{" "}
            <b>App</b> and access our <b>Services</b>. If you choose to access
            the <b>Services</b> from outside the United Kingdom, you are
            responsible for your compliance with local laws for that country if
            and to the extent that local laws are applicable.
          </p>
          <p className={"terms_content_paragraph_inner"}>B) To the Web</p>
          <p className={"terms_content_paragraph_margin"}>
            You are permitted to access and use our <b>Services</b>, available
            via the <b>Web</b>, free of charge in perpetuity. You must be at
            least 18 years of age to use the <b>Web</b> and access our{" "}
            <b>Services</b>. If you choose to access the Services from outside
            the United Kingdom, you are responsible for your compliance with
            local laws for that country if and to the extent that local laws are
            applicable.
          </p>
        </li>
        <li>
          <h2 className={"terms_list_header"}>OFFERS</h2>
          <p className={"terms_content_paragraph_margin"}>
            We may make certain <b>Services</b> available as part of an offer,
            free trial or other discounted packages. Details of these{" "}
            <b>Services</b> will be available on our <b>App</b> from time to
            time, which you may choose to take advantage of. In order to receive
            such offers you must ensure that you select the relevant offer when
            you place your order request, at which time we will provide you with
            specific information on the offer selected.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            Please note that our offers may expire, after which time they may
            not be available. We will endeavour to update the <b>App</b> where
            offers expire; otherwise you will be notified that the offer is no
            longer available on proceeding through the checkout process. All
            offers are subject to availability. We reserve the right to withdraw
            offers at any time, without notice to you, and for any reason.
          </p>
        </li>
        <h2 className={"terms_title"}>SUBSCRIPTIONS</h2>
        <li>
          <h2 className={"terms_list_header"}>YOUR ACCOUNT</h2>
          <p className={"terms_content_paragraph_margin"}>
            In order to access the App and purchase a <b>Supplement</b> (hereby
            known as a <b>“Subscription”</b>) you will need to register for a
            Bianu account. You will be required to enter an email address and
            password for your Bianu account. You are responsible for
            safeguarding your account email address and password and must treat
            such information as confidential and not disclose it to any third
            party. We will not be liable for any loss or damage arising from
            your failure to comply with the above.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            If you know or suspect that anyone other than you knows your email
            address and password, or you believe there has been unauthorised
            access to your Bianu account, you should promptly notify us at
            support@bianu.co. Should you forget your password for your account,
            please select the “I forgot my password” option on the <b>App</b>.
            You will be asked to submit your name and email address and you will
            be able to reset your password
          </p>
          <p className={"terms_content_paragraph_margin"}>
            We are giving you the personal right to access the <b>App</b>. You
            may not transfer your Bianu account to someone else, whether for
            money, for anything else or for free.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            We will contact you by email, SMS or phone as selected by you when
            creating your Bianu account, using the contact details you have
            provided to us. You are responsible for ensuring that your contact
            details are kept up to date.
          </p>
        </li>
        <li>
          <h2 className={"terms_list_header"}>YOUR SUBSCRIPTION</h2>
          <p className={"terms_content_paragraph_margin"}>
            Full details of the <b>Subscription</b> you have selected will be
            available for you to view in the <b>App</b> in the ‘Settings’
            section. <b>Subscriptions</b> may be offered on a 30-day one off,
            recurring monthly or one-off whole pregnancy plan basis, so please
            ensure that you select the correct option. Full details of the{" "}
            <b>Subscription</b> you have selected will be emailed to you in your
            confirmation when the purchase is completed.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            Subject to your cancellation rights detailed below, you provide us
            with a continuous payment authority to charge your payment method
            for the specified amount on approval of your <b>Subscription</b> and
            at monthly intervals (as applicable) following such approval date.
            Each payment will be taken on or around the same day of each month
            or quarter (as detailed in your <b>Subscription</b> confirmation
            unless your <b>Subscription</b> is cancelled by you in accordance
            with these Terms).
          </p>
        </li>
        <li>
          <h2 className={"terms_list_header"}>CANCELLING YOUR SUBSCRIPTION</h2>
          <p className={"terms_content_paragraph_margin"}>
            <b>14-day cooling-off period.</b> For most services bought online
            you have a legal right under the Consumer Contracts Regulations 2013
            to change your mind within 14 days from the day after the contract
            becomes legally binding and receive a refund.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            <b>Cancelling your subscription during the cooling-off period.</b>{" "}
            The contract between us becomes legally binding as soon as payment
            is taken, and we email you our confirmation of your subscription.
            You have 14 days from this date to cancel and you do not have to
            give us a reason for cancelling. If you cancel during this time, we
            will end your subscription and your access to the services and
            provide you with a full refund of any amounts paid.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            30-day money back guarantee: 30 Day Money Back Guarantee includes
            the statutory 14 day cooling off period.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            To qualify customers must have received the supplements, downloaded
            the <b>App</b>, personalised their Bianu wellbeing program via the
            in-app quiz and had a text chat with a coach to ensure they’ve
            experienced the product fully.{" "}
          </p>
          <p className={"terms_content_paragraph_margin"}>
            Only one 30 day money back guarantee will be offered per email
            address.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            <b>We</b> will send a full refund, less shipping, within one week of
            receiving the value refund request
          </p>
          <p className={"terms_content_paragraph_margin"}>
            Please include a brief explanation for the return and your original
            order information.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            You have the right to cancel your <b>Subscription</b> at any time.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            iOS: If you choose to cancel your <b>Subscription</b>, you can do so
            through the <b>App</b>, in the ‘Profile’ section or by contacting us
            on support@bianu.co Android: If you choose to cancel your
            <b>Subscription</b>, you can do so through the <b>App</b>, in the
            ‘Profile’ section or by contacting us on support@bianu.co
          </p>
          <p className={"terms_content_paragraph_margin"}>
            <b>Subscription</b> fees are non-refundable after the 30-day period
            covered by the 30-day money back guarantee so the cancellation will
            take effect from the start of the next billing cycle. You won’t
            receive any further <b>Supplements</b> once your cancellation takes
            effect.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            We must receive your request to cancel your <b>Subscription</b> more
            than 24 hours before your next payment date. If we receive the
            request less than 24 hours before your next payment date your{" "}
            <b>Subscription</b> will only be cancelled at the end of the
            following month or quarter (as applicable) and we will still take
            payment for your next instalment of your <b>Subscription</b>.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            If you cancel your <b>Subscription</b>, you will still have access
            to the <b>App</b> and all of the features within the <b>App</b>{" "}
            until the end of your current billing cycle at which point your
            access will stop.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            We reserve the right to cancel your Subscription, Bianu account,
            right to access the <b>App</b> and right to receive{" "}
            <b>Supplements</b> or any part thereof at any time by contacting you
            if you have breached these Terms in a serious way. If what you have
            done can be put right, we will give you a reasonable opportunity to
            do so.
          </p>
        </li>
        <li>
          <h2 className={"terms_list_header"}>COACHING SERVICES</h2>
          <p className={"terms_content_paragraph_margin"}>
            If you choose to use our live chat-messaging as part of the Coaching
            Service with one of our personal trainer coaches, please ensure that
            the information you give is true, accurate and complete at all
            times. Any errors in such information may impact the accuracy and
            applicability of the advice you are given and may detrimentally
            affect your results from using the Coaching Service. Details of all
            consultations and questionnaires are retained in accordance with all
            applicable laws. We cannot provide specific medical advice via the
            coaching service, please consult a medical professional with any
            medical queries.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            Where we do provide any interactive service, we will provide clear
            information to you about the kind of service offered, if it is
            moderated and what form of moderation is used (including whether it
            is human or technical).
          </p>
        </li>
        <li>
          <h2 className={"terms_list_header"}>THE SUPPLEMENT</h2>
          <p className={"terms_content_paragraph_margin"}>
            Our <b>Supplements</b> are not designed to replace a healthy and
            balanced diet and an active lifestyle. Please consult a doctor
            before taking any <b>Supplements</b>. While our <b>Supplements</b>{" "}
            are made on a production line that is gluten and lactose-free, we
            cannot guarantee that any of our <b>Supplements</b> are gluten-free
            and lactose-free. If you have any food allergies or are allergic to
            any of the ingredients then please consult a doctor before taking
            our <b>Supplements</b>. Do not take any of our <b>Supplements</b> if
            you are already taking a supplement containing one or more of the
            ingredients in our <b>Supplements</b>.
          </p>
        </li>
        <h2 className={"terms_title"}>PERMITTED USE</h2>
        <li>
          <h2 className={"terms_list_header"}>USE RESTRICTIONS</h2>
          <p className={"terms_content_paragraph_margin"}>
            You agree that you will:
            <ul className={"terms_list_inner"}>
              <li className={"terms_content_paragraph"}>
                not rent, lease, sub-license, loan, provide, or otherwise make
                available, the <b>App</b>, the <b>Web</b>, the{" "}
                <b>Supplements</b> or the <b>Services</b> in any form, in whole
                or in part to any person without prior written consent from us;
              </li>
              <li className={"terms_content_paragraph"}>
                not copy the the <b>App</b>, the <b>Web</b>, the{" "}
                <b>Supplements</b> or the <b>Services</b>, except as part of the
                normal use or where it is necessary for the purpose of back-up
                or operational security;
              </li>
              <li className={"terms_content_paragraph"}>
                not translate, merge, adapt, vary, alter or modify, the whole or
                any part of the the <b>App</b>, the <b>Web</b>, the{" "}
                <b>Supplements</b>
                or the <b>Services</b> nor permit the the <b>App</b>, the{" "}
                <b>Web</b>, the <b>Supplements</b> or the <b>Services</b> or any
                part of them to be combined with, or become incorporated in, any
                other programs, except as necessary to use the <b>App</b>,{" "}
                <b>Web</b> and the <b>Services</b> on your device as permitted
                in these <b>Terms</b>;
              </li>
              <li className={"terms_content_paragraph"}>
                not disassemble, de-compile, reverse engineer or create
                derivative works based on the whole or any part of the the{" "}
                <b>App</b>, the <b>Web</b>, the <b>Supplements</b> or the{" "}
                <b>Services</b> nor attempt to do any such things, except to the
                extent that (by virtue of sections 50B and 296A of the
                Copyright, Designs and Patents Act 1988) such actions cannot be
                prohibited because they are necessary to decompile the the{" "}
                <b>App</b>, the <b>Web</b>, the <b>Supplements</b> or the{" "}
                <b>Services</b> to obtain the information necessary to create an
                independent program that can be operated with the <b>App</b>,
                the <b>Web</b>, the <b>Supplements</b> or the <b>Services</b>;
                and
              </li>
              <li className={"terms_content_paragraph"}>
                comply with all applicable technology control or export laws and
                regulations that apply to the technology used or supported by
                the the <b>App</b>, the <b>Web</b>, the <b>Supplements</b> or
                the <b>Services</b>.
              </li>
            </ul>
          </p>
        </li>
        <li>
          <h2 className={"terms_list_header"}>ACCEPTABLE USE</h2>
          <p className={"terms_content_paragraph_margin"}>
            You must:
            <ul className={"terms_list_inner"}>
              <li className={"terms_content_paragraph"}>
                not use the the <b>App</b>, the <b>Web</b>, the{" "}
                <b>Supplements</b> or the <b>Services</b> in any unlawful
                manner, for any unlawful purpose, or in any manner inconsistent
                with these Terms, or act fraudulently or maliciously, for
                example, by hacking into or inserting malicious code, such as
                viruses, or harmful data, into the <b>App</b>, the <b>Web</b>,
                any <b>Service</b> or any operating system;{" "}
              </li>
              <li className={"terms_content_paragraph"}>
                not infringe our intellectual property rights or those of any
                third party in relation to your use of the <b>App</b>, the{" "}
                <b>Web</b>, the <b>Supplements</b> or the <b>Services</b>,
                including by the submission of any material (to the extent that
                such use is not licensed by these Terms);{" "}
              </li>
              <li className={"terms_content_paragraph"}>
                not use the <b>App</b>, the <b>Web</b>, the <b>Supplements</b>{" "}
                or the <b>Services</b> in a way that could damage, disable,
                overburden, impair or compromise our systems or security or
                interfere with other users; or
              </li>
              <li className={"terms_content_paragraph"}>
                not collect or harvest any information or data from any Service
                or our systems or attempt to decipher any transmissions to or
                from the servers running any Service.
              </li>
            </ul>
          </p>
        </li>
        <li>
          <h2 className={"terms_list_header"}>CONTENT</h2>
          <p className={"terms_content_paragraph_margin"}>
            These content standards apply to any and all material, content and
            data which you contribute to our <b>App</b> or <b>Web</b>, and to
            any interactive services associated with it. Each contribution must:
            be accurate (where it states facts); be genuinely held (where it
            states opinions); and comply with the law applicable in England and
            Wales and in any country from which it is posted.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            A contribution must not: be defamatory of any person; be obscene,
            offensive, hateful or inflammatory; bully, insult, intimidate or
            humiliate; promote sexually explicit material; promote violence;
            promote discrimination based on race, sex, religion, nationality,
            disability, sexual orientation or age; infringe any copyright,
            database right or trade mark of any other person; promote any
            illegal activity; be threatening; abuse or invade another’s privacy,
            or cause annoyance, inconvenience or needless anxiety; be likely to
            harass, upset, embarrass, alarm or annoy any other person;
            impersonate any person, or misrepresent your identity or affiliation
            with any person; give the impression that the contribution emanates
            from us, if this is not the case; or contain any advertising or
            promote any services or web links to other sites.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            You warrant that all contributions will comply with the standards
            set out above, and you will be liable to us and indemnify us for any
            breach of that warranty. This means you will be responsible for any
            loss or damage we suffer as a result of your breach of warranty.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            Any content you upload to our <b>App</b> or <b>Web</b> (and any
            content you allow us to access via your Apple Health app) will be
            considered non-confidential and non-proprietary. You retain all of
            your ownership rights in your content, but you are required to grant
            us a licence to use, store, copy and aggregate that content and to
            distribute and make it available to third parties. Where any content
            you upload includes personal data, we will always treat such content
            in accordance with the terms of our{" "}
            <a href={Routes.PRIVACY_POLICY}>Privacy Policy</a>.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            We also have the right to disclose your identity to any third party
            who is claiming that any content posted or uploaded by you to our{" "}
            <b>App</b> or <b>Web</b> constitutes a violation of their
            intellectual property rights, or of their right to privacy.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            We have the right to remove any posting you make on our <b>App</b>{" "}
            or <b>Web</b> if, in our opinion, your post does not comply with the
            content standards set out above.
          </p>
        </li>
        <li>
          <h2 className={"terms_list_header"}>INTELLECTUAL PROPERTY RIGHTS</h2>
          <p className={"terms_content_paragraph_margin"}>
            All intellectual property rights in the information, data, text,
            documents, graphics, logos, designs, images, pictures, photographs,
            videos, interactive features, advertisements or other content,
            services or materials (or any part of them) accessible on our{" "}
            <b>Services</b>, or otherwise protecting the <b>App</b>, the{" "}
            <b>Web</b>, the <b>Supplements</b> or the <b>Services</b> and all
            others throughout the world, belong to us (or our licensors). Such
            rights are licensed (not sold) to you. You have no intellectual
            property rights in, or to, the the <b>App</b>, the <b>Web</b>, the{" "}
            <b>Supplements</b> or the <b>Services</b> other than the right to
            use them in accordance with these Terms.
          </p>
        </li>
        <h2 className={"terms_title"}>LIABILITY</h2>
        <li>
          <h2 className={"terms_list_header"}>OUR LIABILITY</h2>
          <p className={"terms_content_paragraph_margin"}>
            <b>
              WE DO NOT WARRANT THAT INFORMATION WE PROVIDE WILL MEET YOUR
              HEALTH OR MEDICAL REQUIREMENTS. YOU MUST CHECK OUR RECOMMENDATIONS
              OR ANY INFORMATION PROVIDED THROUGH THE SERVICES WITH YOUR OWN GP
              OR OTHER APPROPRIATE HEALTHCARE PROFESSIONAL.
            </b>
          </p>
          <p className={"terms_content_paragraph_margin"}>
            Limitations on our liability. To the fullest extent permitted by
            applicable law, we disclaim all warranties, conditions and other
            terms of any kind, express or implied, in connection with our{" "}
            <b>Services</b> and your use of our <b>Services</b> (including,
            without limitation, as to satisfactory quality, fitness for a
            particular purpose, non-infringement, compatibility, security and
            accuracy). We do not warrant that the functions contained in any
            materials on our <b>Services</b> will be uninterrupted or
            error-free, that defects will be corrected or that our{" "}
            <b>Services</b> or the servers that makes them available are free of
            viruses or bugs.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            We are responsible to you for foreseeable loss and damage caused by
            us. If we fail to comply with these Terms, we are responsible for
            loss or damage you suffer that is a foreseeable result of our
            breaking these Terms or our failing to use reasonable care and
            skill, but we are not responsible for any loss or damage that is not
            foreseeable. Loss or damage is foreseeable if either it is obvious
            that it will happen or if, at the time you accepted these Terms,
            both we and you knew it might happen.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            We do not exclude or limit in any way our liability to you where it
            would be unlawful to do so. This includes liability for death or
            personal injury caused by our negligence or the negligence of our
            employees, agents or subcontractors or for fraud or fraudulent
            misrepresentation.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            When we are liable for damage to your property: If defective digital
            content that we have supplied damages a device or digital content
            belonging to you, we will either repair the damage or pay you
            compensation. However, we will not be liable for damage that you
            could have avoided by following our advice to apply an update
            offered to you free of charge or for damage that was caused by you
            failing to correctly follow installation instructions or to have in
            place the minimum system requirements advised by us. We are not
            liable for business losses. The <b>App</b>, the <b>Web</b>, the{" "}
            <b>Supplements</b> and the <b>Services</b> are for domestic and
            private use. If you use the <b>App</b>, the <b>Web</b>, the{" "}
            <b>Supplements</b> and the <b>Services</b> for any commercial,
            business or resale purpose we will have no liability to you for any
            loss of profit, loss of business, business interruption, or loss of
            business opportunity.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            Please back-up content and data used with the <b>App</b> and the{" "}
            <b>Web</b>. We recommend that you back up any content and data used
            in connection with the <b>App</b> and the <b>Web</b>, to protect
            yourself in case of problems with the <b>App</b>, the <b>Web</b> or
            the <b>Service</b>.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            Check that the <b>App</b>, the <b>Web</b> and the <b>Services</b>{" "}
            are suitable for you. The <b>App</b>, the <b>Web</b> and the{" "}
            <b>Services</b> have not been developed to meet your individual
            requirements. Please check that the facilities and functions of the{" "}
            <b>App</b>, the <b>Web</b> and the <b>Services</b>
            (as described on the Appstore site and in the Documentation) meet
            your requirements.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            We are not responsible for events outside our control. If our
            provision of the <b>Services</b> or support for the <b>App</b> or
            the <b>Web</b> or the <b>Services</b> is delayed by an event outside
            our control, then we will contact you as soon as possible to let you
            know and we will take steps to minimise the effect of the delay.
            Provided we do this we will not be liable for delays caused by the
            event but if there is a risk of substantial delay you may contact us
            to end your contract with us and receive a refund for any Services
            you have paid for but not received.
          </p>
        </li>
        <li>
          <h2 className={"terms_list_header"}>TERMINATION OF YOUR RIGHTS</h2>
          <p className={"terms_content_paragraph_margin"}>
            We may end your rights to use the <b>App</b>, the <b>Web</b> and{" "}
            <b>Services</b> at any time by contacting you if you have breached
            these Terms in a serious way. If what you have done can be put right
            we will give you a reasonable opportunity to do so.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            If we end your rights to use the <b>App</b>, the <b>Web</b> and{" "}
            <b>Services</b>:
            <ul className={"terms_list_inner"}>
              <li className={"terms_content_paragraph"}>
                you must stop all activities authorised by these Terms,
                including your use of the <b>App</b>, the <b>Web</b> and{" "}
                <b>Services</b>;
              </li>
              <li className={"terms_content_paragraph"}>
                you must delete or remove the <b>App</b> from all devices in
                your possession and immediately destroy all copies of the{" "}
                <b>App</b> which you have and confirm to us that you have done
                this; and
              </li>
              <li className={"terms_content_paragraph"}>
                we may remotely access your devices and remove the <b>App</b>{" "}
                from them and cease providing you with access to the{" "}
                <b>Services</b>.
              </li>
            </ul>
          </p>
        </li>
        <h2 className={"terms_title"}>GENERAL</h2>
        <li>
          <h2 className={"terms_list_header"}>
            YOU MAY NOT TRANSFER THE APP TO SOMEONE ELSE
          </h2>
          <p className={"terms_content_paragraph_margin"}>
            We are giving you personally the right to use the <b>App</b> and the{" "}
            <b>Service</b> as set out above. You may not transfer the <b>App</b>{" "}
            or the <b>Service</b> to someone else, whether for money, for
            anything else or for free. If you sell any device on which the{" "}
            <b>App</b> is installed, you must remove the <b>App</b> from it.
          </p>
        </li>
        <li>
          <h2 className={"terms_list_header"}>CHANGES TO THESE TERMS</h2>
          <p className={"terms_content_paragraph_margin"}>
            We may need to change these Terms to reflect changes in law or best
            practice or to deal with additional features which we introduce. We
            will give you at least 30 days’ notice of any change by sending you
            an email with details of the change or notifying you of a change
            when you next start the <b>App</b> or access the <b>Web</b>.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            If you do not accept the notified changes you may continue to use
            the <b>App</b>, the <b>Web</b> and the <b>Service</b> in accordance
            with the existing Terms, but certain new features may not be
            available to you.
          </p>
        </li>
        <li>
          <h2 className={"terms_list_header"}>
            UPDATES TO THE APP AND CHANGES TO THE SERVICE
          </h2>
          <p className={"terms_content_paragraph_margin"}>
            From time to time we may automatically update the <b>App</b> and
            change the <b>Services</b> to improve performance, enhance
            functionality, reflect changes to the operating system or address
            security issues. Alternatively, we may ask you to update the{" "}
            <b>App</b> for these reasons.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            If you choose not to install such updates or if you opt out of
            automatic updates you may not be able to continue using the{" "}
            <b>App</b> and the <b>Services</b>.
          </p>
        </li>
        <li>
          <h2 className={"terms_list_header"}>
            IF SOMEONE ELSE OWNS THE PHONE OR DEVICE YOU ARE USING
          </h2>
          <p className={"terms_content_paragraph_margin"}>
            If you download the <b>App</b> onto any phone or other device not
            owned by you, you must have the owner’s permission to do so. You
            will be responsible for complying with these Terms, whether or not
            you own the phone or other device.
          </p>
        </li>
        <li>
          <h2 className={"terms_list_header"}>
            WE ARE NOT RESPONSIBLE FOR OTHER WEBSITES YOU LINK TO
          </h2>
          <p className={"terms_content_paragraph_margin"}>
            The <b>App</b>, the <b>Web</b> or any <b>Service</b> may contain
            links to other independent websites which are not provided by us.
            Such independent sites are not under our control, and we are not
            responsible for and have not checked and approved their content or
            their privacy policies (if any).
          </p>
          <p className={"terms_content_paragraph_margin"}>
            You will need to make your own independent judgement about whether
            to use any such independent sites, including whether to buy any
            products or services offered by them.
          </p>
        </li>
        <li>
          <h2 className={"terms_list_header"}>TRANSFER OF THIS AGREEMENT</h2>
          <p className={"terms_content_paragraph_margin"}>
            We may transfer our rights and obligations under these Terms to
            another organisation.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            We will always tell you in writing if this happens and we will
            ensure that the transfer will not affect your rights under the
            contract.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            You may only transfer your rights or your obligations under these
            Terms to another person if we agree in writing.
          </p>
        </li>
        <li>
          <h2 className={"terms_list_header"}>NO RIGHTS FOR THIRD PARTIES</h2>
          <p className={"terms_content_paragraph_margin"}>
            These Terms do not give rise to any rights under the Contracts
            (Rights of Third Parties) Act 1999 to enforce any of these Terms.
          </p>
        </li>
        <li>
          <h2 className={"terms_list_header"}>GENERAL</h2>
          <p className={"terms_content_paragraph_margin"}>
            Each of the paragraphs of these Terms operates separately. If any
            court or relevant authority decides that any of them are unlawful,
            the remaining paragraphs will remain in full force and effect.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            Even if we delay in enforcing this contract, we can still enforce it
            later. If we do not insist immediately that you do anything you are
            required to do under these Terms, or if we delay in taking steps
            against you in respect of your breach of these Terms, that will not
            mean that you do not have to do those things and it will not prevent
            us taking steps against you at a later date.
          </p>
          <p className={"terms_content_paragraph_margin"}>
            These Terms are governed by English law and you can bring legal
            proceedings in respect of the products in the English courts. If you
            live in Scotland, you can bring legal proceedings in respect of the
            products in either the Scottish or the English courts. If you live
            in Northern Ireland, you can bring legal proceedings in respect of
            the products in either the Northern Irish or the English courts.
          </p>
          <p className={"terms_content_paragraph"}>
            Last updated: 10th December 2021.{" "}
          </p>
        </li>
      </ol>
    </div>
  );
};

export default TermsText;
