import React from "react";
import "./Terms.scss";
import mainLogo from "../../assets/static/svg/newBianuLogo.svg";
import { useHistory } from "react-router-dom";
import chevron from "../../assets/static/icon/Vector.svg";
import { Routes } from "../../configs/routes/routes";
import TermsText from "./TermsText";
interface Props{
  disableHeader: boolean;
}
function Terms({disableHeader}:Props) {
  const history = useHistory();
  return (
    <div className={"terms_and_conditions"}>
      {disableHeader ? null :
        <div className={"terms_and_conditions_header"}>
          <div className={"terms_main_logo"}>
            <img src={mainLogo} alt="main logo" />
          </div>
          <div className={"header-rec-backBtn"} onClick={() => history.goBack()}>
            <img src={chevron} alt="header-rec-close_icon" />
            <p>Back</p>
          </div>
        </div>
      }
      <div className={"terms_content"}>
        <h2 className={"terms_content_title"}>Terms of Service</h2>
        <TermsText />
      </div>
      <div className={"terms_footer"}>
        <img src={mainLogo} alt="main logo" />
        <p>© 2021 Salus Optima Technologies Limited</p>
      </div>
    </div>
  );
}

export default Terms;
