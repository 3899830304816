import React from "react";
import imgOne from "../../../assets/static/homePageImages/updateIMG/Frame 627.png";
import imgSecond from "../../../assets/static/homePageImages/updateIMG/Frame 626.png";
import imgThree from "../../../assets/static/homePageImages/updateIMG/Frame 622.png";
interface Props {
  clickBtnHandler: any;
}
function SectionSecond({ clickBtnHandler }: Props) {
  return (
    <section className="section_three_wrapper">
      <h2>
        Nurture yourself with personalised supplements, holistic wellbeing and
        prenatal experts
      </h2>
      <div className="section_three_wrapper_content">
        <div className="section_three_wrapper_item">
          <img src={imgThree} />
          <p>Supplements tailored to your dietary and pregnancy needs</p>
        </div>
        <div className="section_three_wrapper_item">
          <img src={imgSecond} />
          <p>Personalised holistic wellbeing on tap via the Bianu app</p>
        </div>
        <div className="section_three_wrapper_item">
          <img src={imgOne} />
          <p>Prenatal experts provide guidance that's unique to you</p>
        </div>
      </div>
    </section>
  );
}

export default SectionSecond;
