import * as Yup from "yup";

export const quizValidation: { [key: string]: any } = {
  stp0001: Yup.object().shape({
    firstName: Yup.string()
      .matches(/^[a-z ,.'-]+$/i)
      .required("Please enter a first name"),
    // lastName: Yup.string()
    //   .matches(/^[a-z ,.'-]+$/i)
    //   .required("Please enter a last name"),
  }),
  // stp0002: Yup.object().shape({
  //   email: Yup.string().email().required("Please enter an email"),
  //   password: Yup.string().min(8).required("Please enter a password"),
  // }),
  stp0004: Yup.object().shape({
    dd: Yup.number().required("Provide a date"),
    mm: Yup.number().required("Provide a month"),
    yyyy: Yup.number().required("Provide a year"),
  }),
};



