import React, { useState } from "react";
import Modal from "../../../_common/Modal/Modal";
import Button from "../../../_common/Button/Button";
import { subscriptionDate } from "../../../../utils/UtilsCommon";
import useStripeHook from "../../../../hooks/useStripeHook";
import Loader from "../../../_common/Loader/Loader";
import ReactPixel from "react-facebook-pixel";

interface Props {
  clickHandlerButton?: () => void;
  clickHandlerClose?: () => void;
  orderData: any;
  currentSubscription: any;
}
function ModalEndSubscription({
  clickHandlerButton,
  clickHandlerClose,
  orderData,
  currentSubscription,
}: Props) {
  const { title, currencySymbol, amount } = currentSubscription;
  const { updateSubscriptionHandler } = useStripeHook();
  const [isLoading, setLoading] = useState(false);
  const endSubscription = async () => {
    setLoading(true);
    await updateSubscriptionHandler({
      subscriptionId: orderData.stripeSubscriptionId,
      paymentMethodId: orderData.defaultPaymentMethod,
      productId: orderData.productId,
      cancelAtPeriodEnd: true,
    });
    //@ts-ignore
    window.cqet("send", "CustomEvent", {
      eventName: "Subscription cancelled",
      userId: orderData.userId,
    });
    ReactPixel.trackCustom("Subscription cancelled", {userId: orderData.userId})

    setLoading(false);
    if (clickHandlerButton) {
      clickHandlerButton();
    }
  };
  return (
    <Modal title={"End subscription"} clickHandlerClose={clickHandlerClose}>
      <div className={"order_subscription_sub_title"}>
        <h4 className={"end_subscription_title"}>
          Are you sure you want to end subscription?
        </h4>
        <p>Your subscription:</p>
      </div>
      <div className={"current_subscription"}>
        <div className={"choose_subscription_left"}>
          <span>
            Next payment -{" "}
            {subscriptionDate(orderData.currentPeriodEnd.seconds)}
          </span>
          <p>{title}</p>
        </div>
        <div className={"choose_subscription_right"}>
          <div>Subscribed</div>
          <p>
            {currencySymbol}
            {amount / 100}
          </p>
        </div>
      </div>
      {isLoading ? (
        <div className={"loader_modal-Wrapper"}>
          <Loader />
        </div>
      ) : (
        <div className={"modal_buttons_wrapper"}>
          <Button
            content={"End subscription"}
            clickHandler={endSubscription}
            className={"primary_button_green"}
          />
          <Button
            content={"Go it"}
            clickHandler={clickHandlerClose}
            className={"secondary_button_gray"}
          />
        </div>
      )}
    </Modal>
  );
}

export default ModalEndSubscription;
