import { Account, CurrentSubscription, PayMethod } from "../types/account";

export interface accountState {
  accountData: Account;
  lastAnswerQuiz:string;
  keySTR: string;
  paymentMethod: any;
  defaultPaymentMethod?: string;
  orderData?: any;
}
export type accountAction =
  | fetchAccountAction
  | fetchQuizAction
  | fetchOrderDataAction
  | fetchDefaultPayMethodAction
  | fetchAddressAction
  | fetchKeySTRAction
  | fetchPayMethodAction
  | fetchLogoutUserAction
  | fetchUserSubscriptions
  | fetchAnonymousUid
  | fetchLastAnswer

export enum accountActionTypes {
  GET_DEFAULT_PAY_METHOD = "GET_DEFAULT_PAY_METHOD",
  GET_ORDER_DATA = "GET_ORDER_DATA",
  GET_DATA_QUIZ = "GET_DATA_QUIZ",
  GET_DATA = "GET_DATA",
  GET_DATA_ADDRESS = "GET_DATA_ADDRESS",
  GET_STR_KEY = "GET_STR_KEY",
  GET_PAY_METHOD = "GET_PAY_METHOD",
  GET_SUBSCRIPTION_HISTORY = "GET_SUBSCRIPTION_HISTORY",
  USER_LOGOUT = "USER_LOGOUT",
  GET_ANONYMOUS_UID = 'GET_ANONYMOUS_UID',
  GET_LAST_ANSWER= 'GET_LAST_ANSWER'
}
interface fetchLogoutUserAction {
  type: accountActionTypes.USER_LOGOUT;
}
interface fetchAccountAction {
  type: accountActionTypes.GET_DATA;
  data: Account;
}
interface fetchQuizAction {
  type: accountActionTypes.GET_DATA_QUIZ;
  data: any;
}
interface fetchAddressAction {
  type: accountActionTypes.GET_DATA_ADDRESS;
  data: any;
}
interface fetchKeySTRAction {
  type: accountActionTypes.GET_STR_KEY;
  data: any;
}
interface fetchOrderDataAction {
  type: accountActionTypes.GET_ORDER_DATA;
  data: CurrentSubscription;
}

interface fetchPayMethodAction {
  type: accountActionTypes.GET_PAY_METHOD;
  data: PayMethod;
}

interface fetchDefaultPayMethodAction {
  type: accountActionTypes.GET_DEFAULT_PAY_METHOD;
  data: string;
}

interface fetchUserSubscriptions {
  type: accountActionTypes.GET_SUBSCRIPTION_HISTORY;
  data: any;
}
interface fetchAnonymousUid {
  type: accountActionTypes.GET_ANONYMOUS_UID;
  data: any;
}
interface fetchLastAnswer {
  type: accountActionTypes.GET_LAST_ANSWER;
  data: string;
}
